import React, { useEffect } from 'react';
import {
    clearLastSegment,
    fetchSegment,
    previewDrawnCollection,
    clearAllSegments,
    toggleDrawing,
} from '../../store/drawActions';
import { connect } from 'react-redux';
import { getSegments4Fetch, getLastCollection } from '../../store/drawSelector';
import {geoFileUploaded, initiateActiveCollectionPreview, generateFeaturesNames} from '../../store/adminActions';

function EditDrawCollection(props) {
    const {
        unresolvedSegments,
        fetchSegment,
        clearLastSegment,
        fetchStack,
        collection,
        geoFileUploaded,
        clearAll,
    } = props;
    const onClearLastSegment = () => {
        clearLastSegment();
    };
    const onClearAllSegments = () => {
        clearAll();
    };
    const uploadCollection = () => {
        if (!collection) {
            return;
        }
        geoFileUploaded({
            collection,
            slugKey: 'drawn-collection',
            fileName: 'drawn-collection',
            isDrawn: true,
        });
        // clearAll();
    };
    // Fetch elevation
    useEffect(() => {
        if (unresolvedSegments.length) {
            fetchSegment(unresolvedSegments[unresolvedSegments.length - 1]);
        }
    }, [unresolvedSegments, fetchSegment]);
    return (
        <>
            <span>Stack [{fetchStack.length}]: </span>
            <button onClick={onClearLastSegment}>Clear last segment</button>
            <button onClick={onClearAllSegments}>Clear all</button>
            <button onClick={uploadCollection}>Save drawn collection</button>
        </>
    );
}

const mapStateToProps = (state) => {
    return {
        unresolvedSegments: getSegments4Fetch(state),
        collection: getLastCollection(state),
        fetchStack: state.drawState.fetchStack,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        geoFileUploaded: (payload) => {
            dispatch(clearAllSegments());
            dispatch(geoFileUploaded(payload));
            dispatch(generateFeaturesNames());
            dispatch(initiateActiveCollectionPreview(true));
        },
        clearAll: () => dispatch(clearAllSegments()),
        toggleDrawing: () => dispatch(toggleDrawing()),
        fetchSegment: (segment) => dispatch(fetchSegment(segment)),
        clearLastSegment: () => {
            dispatch(clearLastSegment());
            dispatch(previewDrawnCollection());
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(EditDrawCollection);
