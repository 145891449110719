import React from 'react';
import { useIntl } from 'react-intl';
import TextField from '@material-ui/core/TextField';
// import ExportAndDownload from './ExportAndDownload';
// import { slug } from '../../helpers/commonHelper';

const TrailFacts = (props) => {
    const intl = useIntl();
    const {
        distance,
        elevGain,
        elevLoss,
        requiredTechnique,
        requiredFitness,
    } = props.properties;
    return (
        <div className="flex row">
            <div className="flex column size-third">
                <TextField
                    label={intl.formatMessage({ id: 'common.odometer.trail' })}
                    defaultValue={`${Math.round(distance)}km`}
                    variant="outlined"
                    InputProps={{ readOnly: true }}
                />
            </div>
            <div className="margin-horizontal--half" />
            <div className="flex column size-third">
                <TextField
                    label={intl.formatMessage({ id: 'common.elevation.gain' })}
                    defaultValue={`${Math.round(elevGain)} m`}
                    variant="outlined"
                    InputProps={{ readOnly: true }}
                />
            </div>
            <div className="margin-horizontal--half" />
            <div className="flex column size-third">
                <TextField
                    label={intl.formatMessage({ id: 'common.elevation.loss' })}
                    defaultValue={`${Math.round(elevLoss)} m`}
                    variant="outlined"
                    InputProps={{ readOnly: true }}
                />
            </div>
            <div className="margin-horizontal--half" />
            <div className="flex column size-third">
                <TextField
                    label={intl.formatMessage({ id: 'common.skill' })}
                    defaultValue={`${requiredTechnique} / 5`}
                    variant="outlined"
                    InputProps={{ readOnly: true }}
                />
            </div>
            <div className="margin-horizontal--half" />
            <div className="flex column size-third">
                <TextField
                    label={intl.formatMessage({ id: 'common.fitness' })}
                    defaultValue={`${requiredFitness} / 5`}
                    variant="outlined"
                    InputProps={{ readOnly: true }}
                />
            </div>
        </div>
    );
};

// const TrailFacts = (props) => {
//     const intl = useIntl();
//     const {
//         distance,
//         elevGain,
//         elevMin,
//         elevMax,
//         requiredFitness,
//         requiredTechnique,
//     } = props.properties;
//     return (
//         <div className="flex row">
//             <div className="flex column size-third">
//                 <TextField
//                     label={intl.formatMessage({ id: 'common.odometer.trail' })}
//                     defaultValue={`${Math.round(distance)}km`}
//                     variant="outlined"
//                     InputProps={{ readOnly: true }}
//                 />
//                 <div className="margin-vertical--half" />
//                 <TextField
//                     label={intl.formatMessage({ id: 'common.elevation.gain' })}
//                     defaultValue={`${Math.round(elevGain)} m`}
//                     variant="outlined"
//                     InputProps={{ readOnly: true }}
//                 />
//             </div>
//             <div className="margin-horizontal--half" />
//             <div className="flex column size-third">
//                 <TextField
//                     label={intl.formatMessage({ id: 'common.elevation.min' })}
//                     defaultValue={`${Math.round(elevMin)} mnv`}
//                     variant="outlined"
//                     InputProps={{ readOnly: true }}
//                 />
//                 <div className="margin-vertical--half" />
//                 <TextField
//                     label={intl.formatMessage({ id: 'common.elevation.max' })}
//                     defaultValue={`${Math.round(elevMax)} mnv`}
//                     variant="outlined"
//                     InputProps={{ readOnly: true }}
//                 />
//             </div>
//             <div className="margin-horizontal--half" />
//             <div className="flex column size-third">
//                 <TextField
//                     label={intl.formatMessage({ id: 'common.fitness' })}
//                     defaultValue={`${requiredFitness} / 5`}
//                     variant="outlined"
//                     InputProps={{ readOnly: true }}
//                 />
//                 <div className="margin-vertical--half" />
//                 <TextField
//                     label={intl.formatMessage({ id: 'common.skill' })}
//                     defaultValue={`${requiredTechnique} / 5`}
//                     variant="outlined"
//                     InputProps={{ readOnly: true }}
//                 />
//             </div>
//         </div>
//     );
// };

export default TrailFacts;
