import React from 'react';
import { getPoints } from '../../helpers/collectionHelper';
import SingleTrailWaypoint from './SingleTrailWaypoint';

const TrailWaypoints = (props) => {
    const { collection } = props;
    const points = getPoints(collection)
        // .filter((p) => p.properties.odometer > 0)
        .sort((a, b) => a.properties.odometer - b.properties.odometer);
    return points.map((point) => {
        const { properties } = point;
        const { id } = properties;
        return <SingleTrailWaypoint feature={point} key={id} />;
    });
};

export default TrailWaypoints;
