import {
    MapConstants,
    defaultGeoFeatureProperties,
} from '../constants/constants';
import { getCollectionBounds } from './mapToolsHelper';
import { getLines, getPoints } from './collectionHelper';

let map, Draw;

export const initMapActions = (mapInstance, drawInstance) => {
    map = mapInstance;
    Draw = drawInstance;
};

export const executeMapAction = (mapAction, cb) => {
    const {
        zoom,
        center,
        bounds,
        bearing,
        collection,
        draw,
        exportCollectionFromDraw,
        markerCollection,
        drawRoute,
        focus,
        features2preview,
        preview,
        focusFeature,
        layerSet,
        visible,
    } = mapAction;
    if (center) {
        const cameraOptions = {
            center,
            zoom: zoom || 15,
        };
        if (bearing !== undefined) {
            cameraOptions.bearing = bearing;
        }
        map.flyTo(cameraOptions);
    }
    if (bounds) {
        map.fitBounds(bounds, {
            padding: MapConstants.bboxPadding,
            speed: 0.6,
        });
        if (isTerrainMap(map)) {
            map.once('zoomend', () => {
                const lines = getLines(collection);
                if (lines && lines.length) {
                    const firstLine = lines[0];
                    const { initialBearing } = firstLine.properties;
                    map.easeTo({
                        bearing: initialBearing,
                        pitch: 70,
                    });
                }
            });
        }
    }
    if (collection && collection.features) {
        if (focus) {
            // setCollection(MapConstants.id.source.geojsonFocus, collection);
            const lines = getLines(collection);
            const trailUUIDs = lines.map((line) => line.properties.trailUUID);
            map.setFilter(
                `${MapConstants.id.layer.geojsonFocus.line}-outline`,
                ['all', ['==', '$type', 'LineString'], ['has', 'isHome'], ['in', 'trailUUID', ...trailUUIDs]],
            );
            // console.log(lines);
            const points = getPoints(collection);
            console.log(points);
            return;
        } else if (focusFeature) {
            if (collection.features.length) {
                setCollection(
                    MapConstants.id.source.geojsonFocus,
                    collection,
                );
            } else {
                clearCollection(MapConstants.id.source.geojsonFocus);
            }
        } else if (drawRoute) {
            if (collection.features.length) {
                clearCollection(MapConstants.id.source.geojsonFocus);
                clearCollection(MapConstants.id.source.geojsonPreview);
                setCollection(
                    MapConstants.id.source.geojsonRoutePreview,
                    collection,
                );
            } else {
                clearCollection(MapConstants.id.source.geojsonRoutePreview);
            }
        } else if (draw) {
            if (collection.features.length) {
                clearCollection(MapConstants.id.source.geojsonFocus);
                clearCollection(MapConstants.id.source.geojsonPreview);
                clearCollection(MapConstants.id.source.geojsonRoutePreview);
                Draw.set(collection);
            }
        } else {
            if (collection.features.length) {
                clearCollection(MapConstants.id.source.geojsonFocus);
                clearCollection(MapConstants.id.source.geojsonRoutePreview);
                setCollection(
                    MapConstants.id.source.geojsonPreview,
                    collection,
                );
                if (Draw) {
                    Draw.set(defaultGeoFeatureProperties.Collection);
                }
            }
        }
    }
    if (features2preview) {
        clearCollection(MapConstants.id.source.geojsonFocus);
        setCollection(MapConstants.id.source.geojsonPreview, features2preview);
        map.fitBounds(getCollectionBounds(features2preview), {
            padding: MapConstants.bboxPadding,
        });
    }
    if (
        markerCollection &&
        markerCollection.features &&
        markerCollection.features.length
    ) {
        map.getSource(MapConstants.id.source.currentPositionMarker).setData(
            markerCollection,
        );
    }
    if (exportCollectionFromDraw) {
        const editedCollection = Draw.getAll();
        cb(editedCollection);
    }
    // Preview trail in 3d
    if (preview) {
        const { playing, index, collection } = preview;
        console.log(playing);
        console.log(index);
        console.log(collection);
    }
    if (layerSet) {
        map
            .getStyle().layers
            .filter((l) => l.metadata && l.metadata.layerSet === layerSet)
            .forEach((layer) => {
                const { id } = layer;
                map.setLayoutProperty(id, 'visibility', visible ? 'visible' : 'none');
            })
    }
};

const setCollection = (source, collection) => {
    if (map.getSource(source)) {
        map.getSource(source).setData(collection);
    } else {
        console.log(`Source ${source} not loaded`);
    }
};

const clearCollection = (source) => {
    if (map.getSource(source)) {
        map.getSource(source).setData(defaultGeoFeatureProperties.Collection);
    } else {
        console.log(`Source ${source} not loaded`);
    }
};

const isTerrainMap = (map) => {
    return !!map.getSource('mapbox-dem');
};
