import React, { useState, useEffect } from 'react';
import classNames from 'classnames';
import {
    setCollectionActiveById,
    initiateActiveCollectionPreview,
    initiateActiveCollectionEdit,
    saveEditedCollection,
} from '../../store/adminActions';
import { getAllRevisions } from '../../store/adminSelector';
import { connect } from 'react-redux';

function PreviewActiveCollectionOnMap(props) {
    const { allRevisions } = props;
    const [activeRevisionId, setActiveRevisionId] = useState();
    const [editModeActive, setEditModeActive] = useState(false);
    useEffect(() => {
        if (allRevisions && allRevisions.length) {
            setActiveRevisionId(allRevisions[allRevisions.length - 1].id);
        }
    }, [allRevisions]);
    const onPreviewInitiated = () => {
        props.previewCollectionById(activeRevisionId);
    };
    const onSaveEditInitiated = () => {
        props.saveEditedCollection();
        setEditModeActive(false);
    };
    const onEditInitiated = () => {
        props.editCollectionById(activeRevisionId);
        setEditModeActive(true);
    };
    const onSelectRevision = (e) => {
        setActiveRevisionId(e.target.value);
    };
    const renderStatistics = () => {
        if (!activeRevisionId) {
            return null;
        }
        const {
            totalPoints,
            totalLines,
            totalCoordinatesByLine,
        } = allRevisions.find((r) => r.id === activeRevisionId).statistics;
        return (
            <label>
                Total {JSON.stringify(totalCoordinatesByLine)} coordinates in{' '}
                {totalLines} lines + {totalPoints} points.
            </label>
        );
    };
    return (
        <>
            {/* eslint-disable-next-line jsx-a11y/no-onchange */}
            <select onChange={onSelectRevision} value={activeRevisionId}>
                {allRevisions.map((revision, idx) => {
                    const { id, time, action, slugKey } = revision;
                    const timeString =
                        typeof time === 'string'
                            ? time
                            : time.toLocaleTimeString();
                    return (
                        <option
                            key={idx}
                            value={id}
                        >{`${timeString} - ${action} - ${slugKey}`}</option>
                    );
                })}
            </select>
            <button
                onClick={onPreviewInitiated}
                className={classNames({ inactive: !allRevisions.length })}
            >
                Preview
            </button>
            {editModeActive ? (
                <button
                    onClick={onSaveEditInitiated}
                    className={classNames({ inactive: !allRevisions.length })}
                >
                    Save
                </button>
            ) : (
                <button
                    onClick={onEditInitiated}
                    className={classNames({ inactive: !allRevisions.length })}
                >
                    Edit
                </button>
            )}
            {renderStatistics()}
        </>
    );
}

const mapStateToProps = (state) => {
    return {
        allRevisions: getAllRevisions(state),
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        previewCollectionById: (id) => {
            dispatch(setCollectionActiveById(id));
            dispatch(initiateActiveCollectionPreview(true));
        },
        editCollectionById: (id) => {
            dispatch(setCollectionActiveById(id));
            dispatch(initiateActiveCollectionEdit());
        },
        saveEditedCollection: () => {
            dispatch(saveEditedCollection());
        },
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(PreviewActiveCollectionOnMap);
