import React from 'react';
import { FormattedMessage } from 'react-intl';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Typography from '@material-ui/core/Typography';
import { getLines } from '../../helpers/collectionHelper';
import TrailFacts from './TrailFacts';
import TrailPlaceObjects from './TrailPlaceObjects';

const useStyles = makeStyles({
    root: {
        // maxWidth: 345,
        width: '100%',
    },
    media: {
        height: '25vh',
    },
});

const TrailGeneralProperties = (props) => {
    const { collection } = props;
    const lines = getLines(collection);
    const classes = useStyles();
    return lines.map((line) => {
        const { properties } = line;
        const {
            trailUUID,
            trailName,
            trailDesc,
            imageUrl,
            imageCredits,
        } = properties;
        return (
            <Card key={trailUUID} className={classes.root}>
                <CardMedia
                    className={classes.media}
                    image={imageUrl}
                    title={`Foto: ${imageCredits}`}
                />
                <CardContent>
                    <Typography gutterBottom variant="h5" component="h2">
                        {trailName}
                    </Typography>
                    <Typography
                        variant="body2"
                        color="textSecondary"
                        component="p"
                    >
                        {trailDesc}
                    </Typography>
                    <div className="margin-vertical" />
                    <TrailFacts properties={properties} />
                    <div className="margin-vertical" />
                    <Typography
                        variant="body2"
                        color="textSecondary"
                        component="p"
                    >
                        <FormattedMessage id="common.placeObjects" />
                    </Typography>
                    <div className="margin-vertical" />
                    <TrailPlaceObjects properties={properties} />
                </CardContent>
            </Card>
        );
    });
};

export default TrailGeneralProperties;
