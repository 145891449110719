import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Paper from '@material-ui/core/Paper';
import Popover from '@material-ui/core/Popover';
import MenuList from '@material-ui/core/MenuList';
import MenuItem from '@material-ui/core/MenuItem';
import Divider from '@material-ui/core/Divider';
import InputBase from '@material-ui/core/InputBase';
import SortByAlphaIcon from '@material-ui/icons/SortByAlpha';
import AppsIcon from '@material-ui/icons/Apps';
import FormatListBulletedIcon from '@material-ui/icons/FormatListBulleted';
import SearchIcon from '@material-ui/icons/Search';
import ClearIcon from '@material-ui/icons/Clear';
import { uiConstants } from '../../constants/constants';
import Tooltip from '@material-ui/core/Tooltip';

const useStyles = makeStyles((theme) => ({
    grow: {
        flexGrow: 1,
    },
    menuButton: {
        marginRight: theme.spacing(2),
    },
    title: {
        display: 'none',
        [theme.breakpoints.up('sm')]: {
            display: 'block',
        },
    },
    search: {
        position: 'relative',
    },
    searchIcon: {
        padding: theme.spacing(0, 2),
        height: '100%',
        position: 'absolute',
        pointerEvents: 'none',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    inputRoot: {
        // color: 'inherit',
    },
    inputInput: {
        padding: theme.spacing(1, 1, 1, 0),
        // vertical padding + font size from searchIcon
        paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
        transition: theme.transitions.create('width'),
        width: '100%',
        [theme.breakpoints.up('md')]: {
            width: '20ch',
        },
    },
    sectionDesktop: {
        display: 'none',
        [theme.breakpoints.up('md')]: {
            display: 'flex',
        },
    },
    sectionMobile: {
        display: 'flex',
        [theme.breakpoints.up('md')]: {
            display: 'none',
        },
    },
    wrapper: {
        display: 'flex',
        justifyContent: 'space-between',
    },
    appBar: {
        top: 0,
    },
}));

function AllTrailsListControls(props) {
    const { onSortAction, onLayoutSwitch, onSearchAction, onResetTempSelection, hasTempSelection, listLayout } = props;
    const [selectedIndex, setSelectedIndex] = React.useState(null);
    const anchorRef = React.useRef(null);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const classes = useStyles();
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    return (
        <>
            <AppBar
                position="sticky"
                color="inherit"
                className={classes.appBar}
                elevation={1}
            >
                <Toolbar className={classes.wrapper}>
                    <div className={classes.search}>
                        <div className={classes.searchIcon}>
                            <SearchIcon />
                        </div>
                        <InputBase
                            placeholder="Search…"
                            classes={{
                                root: classes.inputRoot,
                                input: classes.inputInput,
                            }}
                            inputProps={{ 'aria-label': 'search' }}
                            onChange={onSearchAction}
                        />
                    </div>
                    <div>
                        {hasTempSelection && <Tooltip title="Poništi privremenu selekciju staza">
                            <IconButton
                                onClick={onResetTempSelection}
                            >
                                <ClearIcon />
                            </IconButton>
                        </Tooltip>}
                        {listLayout === uiConstants.trailListLayout.grid && <Tooltip title="Prikaži staze kao tabelarnu listu">
                            <IconButton
                                onClick={() =>
                                    onLayoutSwitch(uiConstants.trailListLayout.list)
                                }
                            >
                                <FormatListBulletedIcon />
                            </IconButton>
                        </Tooltip>}
                        {listLayout === uiConstants.trailListLayout.list && <Tooltip title="Prikaži staze kao matricu sličica">
                            <IconButton
                                onClick={() =>
                                    onLayoutSwitch(uiConstants.trailListLayout.grid)
                                }
                            >
                                <AppsIcon />
                            </IconButton>
                        </Tooltip>}
                        {!hasTempSelection && <Tooltip title="Opcije sortiranja">
                            <IconButton onClick={handleClick} ref={anchorRef}>
                                <SortByAlphaIcon />
                            </IconButton>
                        </Tooltip>}
                    </div>
                </Toolbar>
            </AppBar>
            <Popover anchorEl={anchorRef.current} open={open}>
                <Paper>
                    <ClickAwayListener onClickAway={handleClose}>
                        <MenuList id="sort-menu" autoFocusItem={open}>
                            {uiConstants.sortRules.map((option, oIdx) => {
                                const { label, separator } = option;
                                if (separator) {
                                    return <Divider key={`divider${oIdx}`} />;
                                }
                                return (
                                    <MenuItem
                                        key={label}
                                        selected={oIdx === selectedIndex}
                                        onClick={() => {
                                            setSelectedIndex(oIdx);
                                            onSortAction(option);
                                            handleClose();
                                        }}
                                    >
                                        {label}
                                    </MenuItem>
                                );
                            })}
                            <Divider />
                            <MenuItem
                                key="Bez sortiranja"
                                selected={selectedIndex === null}
                                onClick={() => {
                                    setSelectedIndex(null);
                                    handleClose();
                                }}
                            >
                                Bez sortiranja
                            </MenuItem>
                        </MenuList>
                    </ClickAwayListener>
                </Paper>
            </Popover>
        </>
    );
}

export default AllTrailsListControls;
