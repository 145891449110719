import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { getAllTrailsList } from '../store/adminActions';
import EditSingleTrail from '../components/admin/EditSingleTrail';

const EditAllTrailsDataLoader = (props) => {
    const { collection, initCollection } = props;
    useEffect(() => {
        if (!collection.length) {
            initCollection();
        }
    }, [collection, initCollection]);
    if (!collection.length) {
        return <span>Loading</span>;
    }
    return (
        <div className="table__wrapper">
            <table>
                <thead>
                    <tr>
                        <th>Image</th>
                        <th>ID</th>
                        <th>Name</th>
                        <th>Dist / Elev</th>
                        <th>Places</th>
                        <th>Update</th>
                        <th>Status</th>
                        <th>Controls</th>
                    </tr>
                </thead>
                <tbody>
                    {collection.map((point) => (
                        <EditSingleTrail key={point.trailUUID} trail={point} />
                    ))}
                </tbody>
            </table>
        </div>
    );
};

const mapStateToProps = (state) => {
    return {
        collection: state.adminState.trailsList,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        initCollection: () => dispatch(getAllTrailsList()),
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(EditAllTrailsDataLoader);
