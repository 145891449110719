import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import {
    simplifyCollectionLines,
    fetchElevation4Coordinates,
    initiateActiveCollectionPreview,
    initiateCoordinatesElevationFetch,
    elevateCollection,
    enrichCollection,
    stickyCollectionPoints2lines,
    fetchFeatureThumbnail,
    toggleActionFlag,
    invertCollection,
} from '../../store/adminActions';
import { connect } from 'react-redux';
import {
    getAllUnresolvedCoordinates,
    getUnresolvedCandidatesCoordinates,
    isElevationFetchInProgress,
    getNextFeatureForThumbnailGeneration,
    getTokenSetup,
} from '../../store/adminSelector';

function EditCollection(props) {
    const {
        parsed,
        simplified,
        elevated,
        simplify,
        fetchElevation,
        elevate,
        unresolvedCoordinates,
        candidatesCoordinates,
        fetchInProgress,
        enrich,
        invert,
        generateWaypoints,
        featureCandidate4ThumbnailGeneration,
        generateFeatureThumbnail,
        tokenSetup,
        generateWpThumbnails,
        toggleActionFlag,
    } = props;
    const onSimplify = () => {
        simplify();
    };
    const onStickyPoints = () => {
        generateWaypoints();
    };
    const onElevate = () => {
        elevate();
    };
    const onEnrich = () => {
        enrich();
    };
    const onInvert = () => {
        invert();
    };
    const [valueGenerateWpThumbnails, setGenerateWpThumbnails] = useState(
        generateWpThumbnails,
    );
    // Fetch elevation
    useEffect(() => {
        if (!fetchInProgress && candidatesCoordinates.length) {
            fetchElevation(candidatesCoordinates);
        }
    }, [fetchInProgress, candidatesCoordinates, fetchElevation]);
    // Generate waypoints thumbnails
    useEffect(() => {
        if (featureCandidate4ThumbnailGeneration && generateWpThumbnails) {
            generateFeatureThumbnail(
                featureCandidate4ThumbnailGeneration,
                tokenSetup,
            );
        }
    }, [
        featureCandidate4ThumbnailGeneration,
        generateFeatureThumbnail,
        generateWpThumbnails,
        tokenSetup,
    ]);
    return (
        <>
            <button
                onClick={() => {
                    window.localStorage.removeItem('resolvedCoordinates');
                }}
                className={classNames({
                    inactive: !window.localStorage.getItem(
                        'resolvedCoordinates',
                    ),
                })}
            >
                Clear cache
            </button>
            <button
                onClick={onSimplify}
                className={classNames({ inactive: !parsed })}
            >
                Simplify
            </button>
            <button
                onClick={onStickyPoints}
                className={classNames({ inactive: !simplified })}
            >
                Waypoints
            </button>
            <input
                name="generateWpThumbnails"
                type="checkbox"
                value={valueGenerateWpThumbnails}
                checked={valueGenerateWpThumbnails}
                onChange={() => {
                    setGenerateWpThumbnails(!valueGenerateWpThumbnails);
                    toggleActionFlag('generateWpThumbnails');
                }}
            />
            <button
                onClick={onElevate}
                className={classNames({
                    inactive:
                        !simplified ||
                        fetchInProgress ||
                        unresolvedCoordinates.length > 0,
                })}
            >
                {fetchInProgress
                    ? `Elevating... (${unresolvedCoordinates.length} left)`
                    : 'Elevate'}
            </button>
            <button
                onClick={onEnrich}
                className={classNames({ inactive: !elevated })}
            >
                Enrich
            </button>
            <button
                onClick={onInvert}
                className={classNames({ inactive: !parsed })}
            >
                Invert/Flip
            </button>
        </>
    );
}

const mapStateToProps = (state) => {
    return {
        ...state.adminState.actionFlags,
        unresolvedCoordinates: getAllUnresolvedCoordinates(state),
        candidatesCoordinates: getUnresolvedCandidatesCoordinates(state),
        fetchInProgress: isElevationFetchInProgress(state),
        featureCandidate4ThumbnailGeneration: getNextFeatureForThumbnailGeneration(
            state,
        ),
        tokenSetup: getTokenSetup(state),
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        simplify: () => {
            dispatch(simplifyCollectionLines());
            dispatch(initiateActiveCollectionPreview(true));
        },
        fetchElevation: (coordinates) => {
            dispatch(initiateCoordinatesElevationFetch());
            dispatch(fetchElevation4Coordinates(coordinates));
            dispatch(initiateActiveCollectionPreview(true));
        },
        elevate: () => {
            dispatch(elevateCollection());
            dispatch(initiateActiveCollectionPreview(true));
        },
        enrich: () => {
            dispatch(enrichCollection());
            dispatch(initiateActiveCollectionPreview(true));
        },
        invert: () => {
            dispatch(invertCollection());
            dispatch(simplifyCollectionLines());
            dispatch(stickyCollectionPoints2lines());
            dispatch(initiateActiveCollectionPreview(true));
        },
        generateWaypoints: () => {
            dispatch(stickyCollectionPoints2lines());
            dispatch(initiateActiveCollectionPreview(true));
        },
        generateFeatureThumbnail: (point, tokenSetup) => {
            dispatch(fetchFeatureThumbnail(point, tokenSetup));
        },
        toggleActionFlag: (flagName) => dispatch(toggleActionFlag(flagName)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(EditCollection);
