import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import GridList from '@material-ui/core/GridList';
import GridListTile from '@material-ui/core/GridListTile';

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flex: '1 1 50%',
        flexWrap: 'wrap',
        justifyContent: 'flex-start',
        overflow: 'hidden',
        backgroundColor: theme.palette.background.paper,
    },
    gridList: {
        width: window.innerWidth < 720 ? window.innerWidth - 140 : '100%',
    },
}));

export default function SurfaceTypeExamples(props) {
    const classes = useStyles();
    const { id } = props;
    const [tileIds, setTileIds] = useState([1, 2, 3]);
    const reorder = (id) => {
        const newList = tileIds.filter((element) => element !== id);
        setTileIds([id, ...newList]);
    };
    return (
        <div className={classes.root}>
            <GridList cellHeight={200} spacing={1} className={classes.gridList}>
                {tileIds.map((tileId, idx) => (
                    <GridListTile
                        key={`${id}_${tileId}`}
                        cols={!idx ? 2 : 1}
                        rows={!idx ? 2 : 1}
                        onClick={() => {
                            reorder(tileId);
                        }}
                    >
                        <img
                            src={`/pics/surface/${id}_${tileId}.jpg`}
                            alt={`${id}_${tileId}`}
                        />
                    </GridListTile>
                ))}
            </GridList>
        </div>
    );
}
