/* global turf */
import { getUUID, slug } from '../helpers/commonHelper';
import { getRichLine } from '../helpers/elevationChartHelper';
import { getDefaultImageCredits } from './collectionHelper';
import {
    defaultGeoFeatureProperties,
    processingParameters,
    surfaceTypes,
} from '../constants/constants';

export const parseLineFeatureMetadata = (feature) => {
    const { properties } = feature;
    const { published, trailUUID } = properties;
    if (trailUUID || published) {
        return {
            ...properties,
        };
    } else {
        const { name, desc, cmt, surfaceCollection } = properties;
        const newId = getUUID();
        const newName = name || '';
        const newDesc = desc || cmt || name || '';
        let newImageCredits = getDefaultImageCredits(feature);
        return {
            ...defaultGeoFeatureProperties.LineString,
            id: newId,
            trailUUID: newId,
            trailSlug: slug(newName),
            trailName: newName,
            trailDesc: newDesc,
            trailNameEn: newName,
            trailDescEn: newDesc,
            imageCredits: newImageCredits,
            center: turf.centerOfMass(feature).geometry.coordinates,
            surfaceCollection:
                surfaceCollection && surfaceCollection.length > 1
                    ? surfaceCollection
                    : defaultGeoFeatureProperties.LineString.surfaceCollection,
        };
    }
};

export const enrichLine = (line) => {
    let {
        elevMax,
        elevMin,
        elevGain,
        elevLoss,
        surfaceCollection,
        numberOfOscilations: numberOfOscillations,
    } = line.properties;
    elevGain = 0;
    elevLoss = 0;
    line.geometry.coordinates.forEach((coordinate, idx) => {
        const previousElevation =
            idx > 0 ? line.geometry.coordinates[idx - 1][2] : 0;
        const elevation = coordinate[2];
        if (elevation > elevMax) {
            elevMax = parseInt(elevation, 10);
        }
        if (elevation < elevMin) {
            elevMin = parseInt(elevation, 10);
        }
        if (idx > 0) {
            if (elevation > previousElevation) {
                elevGain += Math.abs(elevation - previousElevation);
            } else {
                elevLoss += Math.abs(elevation - previousElevation);
            }
        }
    });
    // Elevation reduction depending on number of oscillations
    const elevationReduction =
        1 -
        processingParameters.elevationReduction * (numberOfOscillations || 0);
    // console.log(`Elevation reduction ${elevGain} -> ${elevGain * elevationReduction}`);
    elevGain = parseInt(elevGain * elevationReduction, 10);
    elevLoss = parseInt(elevLoss * elevationReduction, 10);
    // Distance
    const distance = parseFloat(
        turf.length(line, { units: 'kilometers' }).toFixed(2),
    );
    const bounds = turf.bbox(line);
    const center = turf.centerOfMass(line).geometry.coordinates;
    const requiredTechnique = calculateTechniqueLevelID(
        surfaceCollection,
        elevGain,
    );
    const requiredFitness = calculateFitnessLevelID(
        distance,
        elevGain,
        requiredTechnique,
    );
    const trailTypeID = calculateTrailTypeID(
        requiredTechnique,
        requiredFitness,
    );
    return {
        ...line,
        properties: {
            ...line.properties,
            elevMax,
            elevMin,
            elevGain,
            elevLoss,
            distance,
            bounds,
            center,
            requiredTechnique,
            requiredFitness,
            trailTypeID,
            richLine: getRichLine(line),
        },
    };
};

const calculateTrailTypeID = (t, f) => {
    if (t === 1 && f === 4) {
        return 4;
    }
    if (t >= 3 && f >= 2) {
        return 3;
    }
    if (t >= 2 && f >= 1) {
        return 2;
    }
    if (t >= 1 && f >= 0) {
        return 1;
    }
    return 0;
};

const calculateFitnessLevelID = (distance, elevation, requiredTechnique) => {
    const score = parseInt(
        elevation + distance * 10 + (requiredTechnique / 5) * 1000,
        10,
    );
    if (score < 800) {
        return 1;
    } else if (score < 1500) {
        return 2;
    } else if (score < 3000) {
        return 3;
    } else if (score < 4500) {
        return 4;
    }
    return 5;
};

const calculateSurfaceDistribution = (surfaceCollection, totaldistance) => {
    const temp = surfaceCollection.map((s, idx) => {
        if (idx < surfaceCollection.length - 1) {
            return {
                type: s.value,
                start: s.key,
                end: surfaceCollection[idx + 1].key,
                distance: surfaceCollection[idx + 1].key - s.key,
                percent:
                    ((surfaceCollection[idx + 1].key - s.key) / totaldistance) *
                    100,
            };
        }
        return {
            type: s.value,
            start: s.key,
            end: totaldistance,
            distance: totaldistance - s.key,
            percent: ((totaldistance - s.key) / totaldistance) * 100,
        };
    });
    let surface = {};
    surfaceTypes.forEach((type) => {
        const t = type.id;
        surface[t] = {
            distance: 0,
            percent: 0,
        };
        const segment = temp.filter((s) => {
            return s.type === t;
        });
        segment.forEach((s) => {
            surface[t].distance += s.distance;
            surface[t].percent += s.percent;
        });
    });
    return surface;
};

const calculateTechniqueLevelID = (surfaceCollection, totaldistance) => {
    let surface = calculateSurfaceDistribution(
        surfaceCollection,
        totaldistance,
    );
    // A - M - S - P - N
    // 5 - NEPROHODNO
    if (
        surface.N.percent > 2 ||
        surface.P.percent > 5 ||
        surface.S.percent > 10 ||
        surface.M.percent > 50 ||
        surface.N.distance > 0.5 ||
        surface.P.distance > 2 ||
        surface.S.distance > 5 ||
        surface.M.distance > 40
    ) {
        return 5;
    }
    // 4 - STAZA
    if (
        surface.N.percent > 0 ||
        surface.P.percent > 2 ||
        surface.S.percent > 5 ||
        surface.M.percent > 40 ||
        surface.N.distance > 0 ||
        surface.P.distance > 1 ||
        surface.S.distance > 5 ||
        surface.M.distance > 30
    ) {
        return 4;
    }
    // 3
    if (
        surface.P.percent > 0 ||
        surface.S.percent > 1 ||
        surface.M.percent > 25 ||
        surface.P.distance > 0 ||
        surface.S.distance > 2 ||
        surface.M.distance > 20
    ) {
        return 3;
    }
    // 2
    if (
        surface.S.percent > 0 ||
        surface.M.percent > 20 ||
        surface.S.distance > 0 ||
        surface.M.distance > 10
    ) {
        return 2;
    }
    return 1;
};

export const cleanUpSurfaceCollection = (surfaceCollection) => {
    let activeKey;
    const newSurfaceCollection = [];
    surfaceCollection.forEach((transition) => {
        const { key } = transition;
        const isChanged = key !== activeKey;
        if (isChanged) {
            activeKey = JSON.stringify(JSON.parse(key));
            newSurfaceCollection.push(transition);
        }
    });
    return newSurfaceCollection;
};
