import React from 'react';
import { UrlContstants } from '../../constants/constants';

function ImageUploader(props) {
    const { properties, value, onUpdate, isPhotoCollection } = props;
    const { id, parent, name } = properties;
    const isPoint = parent && parent.id;
    const onFileSelectedEvent = (e) => {
        if (e.target.files.length > 0) {
            const file = e.target.files[0];
            const formData = new FormData();
            const newFileName = `${
                isPoint ? 'point' : 'line'
            }_user_upload_${id}.${file.name}`;
            formData.append('SelectedFile', file, newFileName);
            const folder = isPoint ? `trails/${parent.id}/` : `trails/${id}/`;
            formData.append('folder', folder);
            fetch(UrlContstants.imageUpload, {
                method: 'POST',
                body: formData,
            })
                .then((response) => {
                    return response.json();
                })
                .then((result) => {
                    console.log('Success:', result);
                    if (result.status === 'success') {
                        const imagePath = `${UrlContstants.imageRepository}${folder}${result.fileName}`;
                        onUpdate(imagePath);
                    }
                })
                .catch((error) => {
                    console.error('Error:', error);
                });
        }
    };
    return (
        <div className="feature__image-uploader">
            <input
                type="file"
                onChange={onFileSelectedEvent}
                style={{ width: 200 }}
            />
            {value && value.length && !isPhotoCollection ? (
                <>
                    <a href={value} target="_blank" rel="noopener noreferrer">
                        <img src={value} alt={name} height={30} />
                    </a>
                    <button
                        onClick={() => {
                            onUpdate('');
                        }}
                    >
                        <span className="material-icons">
                            remove_circle_outline
                        </span>
                    </button>
                </>
            ) : null}
        </div>
    );
}

export default ImageUploader;
