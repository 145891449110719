import { getCollectionForExport, getLines } from '../helpers/collectionHelper';

export const getModal = (state) => {
    return state.presentationState.modal;
};

export const getHomeCollection = (state) => {
    const collection = state.presentationState.collection.home;
    if (!collection || !collection.features) {
        return false;
    }
    return collection;
};

export const getTrailGroupCollection = (state) => {
    const uuid = state.presentationState.collection.activeGuid;
    if (!uuid) {
        return false;
    }
    const collection = state.presentationState.collection.group[uuid];
    if (!collection || !collection.features) {
        return false;
    }
    return collection;
};

export const getTrailCollectionByGuid = (state, uuid) => {
    if (!uuid) {
        return false;
    }
    const collection = state.presentationState.collection.trail[uuid];
    if (!collection || !collection.features) {
        return false;
    }
    return collection;
};

export const getTrailCollection = (state) => {
    const uuid = state.presentationState.collection.activeGuid;
    return getTrailCollectionByGuid(state, uuid);
};

export const getTrailCollectionForExport = (state, rich) => {
    const uuid = state.presentationState.collection.activeGuid;
    const collection = getTrailCollectionByGuid(state, uuid);
    if (rich) {
        return collection;
    }
    return getCollectionForExport(collection);
};

export const getNextMapAction = (state) => {
    const stack = [...state.presentationState.mapActionStack];
    return stack[stack.length - 1];
};

export const getTrailFacts = (state) => {
    const uuid = state.presentationState.collection.activeGuid;
    const collection = getTrailCollectionByGuid(state, uuid);
    const line = getLines(collection)[0];
    if (!line) {
        return false;
    }
    return line.properties;
};

export const getSocialNetworkSharingSetup = (state) => {
    const collection = getTrailCollection(state);
    const lines = getLines(collection);
    if (lines.length) {
        const { trailName, trailDesc, imageUrl } = lines[0].properties;
        return {
            hashtag: '#stazenet',
            twhashtags: ['stazenet'],
            activeSharingTitle: `Staze.net - ${trailName}`,
            activeSharingDescription: trailDesc,
            via: 'mtbba',
            url: window.location.href,
            imageUrl: imageUrl,
            embed: `<iframe frameborder='0' scrolling='no' marginheight='0' marginwidth='0' src='${window.location.href}' width='800' height='600' allowfullscreen='true' webkitallowfullscreen='true' mozallowfullscreen='true'></iframe>`,
        };
    }
    return {
        title: 'Staze.net - outdoor staze Balkana',
        hashtag: '#stazenet',
        twhashtags: ['stazenet'],
        activeSharingTitle: 'Staze.net - outdoor staze Balkana',
        activeSharingDescription: 'Staze.net - outdoor staze Balkana',
        via: 'mtbba',
        url: window.location.href,
        imageUrl: 'http://www.mtb.ba/wp-content/uploads/2012/09/E0975.jpg',
        embed: `<iframe frameborder='0' scrolling='no' marginheight='0' marginwidth='0' src='${window.location.href}' width='800' height='600' allowfullscreen='true' webkitallowfullscreen='true' mozallowfullscreen='true'></iframe>`,
    };
};

export const getTempTrailSelectionUUIDs = (state) => {
    return state.presentationState.tempTrailSelection.uuids;
};
