import { AppEvent } from '../constants/events';
import { AppAction } from '../constants/actions';
import { getCollectionBounds, getFocusAction } from '../helpers/mapToolsHelper';
import {
    getSimplifiedCollection,
    getElevatedCollection,
    getSmoothCollection,
    stickPoints2Lines,
    generateWPointGeoJSON,
} from '../helpers/geoSpatialHelper';
import { getUUID } from '../helpers/commonHelper';
import {
    getAllCoordinates,
    getStatistics,
    parseCollection,
    reParseCollection,
    getEnrichedCollection,
    getLines,
    combineCollections,
    getInvertedCollection,
    getCollectionLinePreviewFeatures,
    getHomeCollection,
    generateFeaturesNames,
    preparePayload4Publish,
    collectionCleanUp4BatchUpdate,
} from '../helpers/collectionHelper';
import { getTrailSegments4Map } from '../helpers/elevationChartHelper';

const initialState = {
    dataStack: [],
    combineStack: [],
    mapActionStack: [],
    batchUpdateStack: {
        fetchCandidates: [],
        fetched: [],
        updateCandidates: [],
        updated: [],
        uploadCandidate: null,
    },
    combiner: {
        before: [],
        after: [],
    },
    pois: null,
    current: {
        odometer: 0,
    },
    coordinates: {
        unresolved: [],
        resolved:
            JSON.parse(window.localStorage.getItem('resolvedCoordinates')) ||
            [],
    },
    actionFlags: {
        elevationFetchInProgress: false,
        parsed: false,
        simplified: false,
        stickyPoints: false,
        elevated: false,
        published: false,
        generateWpThumbnails: false,
    },
    trailsList: [],
};

export default function adminState(state = initialState, action) {
    switch (action.type) {
        case AppEvent.admin.NEAREST_TRAILS_LOADED: {
            const newState = { ...state };
            const { data, part } = action.payload;
            newState.combiner[part] = [...data];
            return newState;
        }
        case AppEvent.admin.POPULATE_CANDIDATES_FOR_BATCH_FETCH: {
            const newState = { ...state };
            const { candidates } = action;
            newState.batchUpdateStack.fetchCandidates = [...candidates];
            return newState;
        }
        case AppEvent.admin.POPULATE_CANDIDATES_FOR_BATCH_UPDATE: {
            const newState = { ...state };
            const { candidates } = action;
            newState.batchUpdateStack.updateCandidates = [...candidates];
            return newState;
        }
        case AppEvent.admin.GEO_FILE_FETCHED_4_UPDATE: {
            const newState = { ...state };
            const { uuid } = action.payload;
            newState.batchUpdateStack.fetchCandidates = newState.batchUpdateStack.fetchCandidates.filter(
                (candidate) => candidate.trailUUID !== uuid,
            );
            newState.batchUpdateStack.fetched.push(action.payload);
            return newState;
        }
        case AppEvent.admin.UPDATE_SINGLE_COLLECTION_IN_BATCH_PROCESS: {
            const newState = { ...state };
            const { candidate } = action;
            const { uuid, collection, status } = candidate;
            const collectionCleanedUp = collectionCleanUp4BatchUpdate(collection, newState);
            const payload = preparePayload4Publish(collectionCleanedUp);
            newState.batchUpdateStack.uploadCandidate = {
                p1: {
                    lines: payload.lines,
                    waypoints: payload.waypoints,
                    generalFacts: payload.generalFacts,
                },
                p2: {
                    collection: payload.collection,
                    uuid: payload.uuid,
                },
                status,
                uuid,
            };
            return newState;
        }
        case AppEvent.admin.COLLECTION_BATCH_UPDATE_COMPLETED: {
            const newState = { ...state };
            const { uuid } = action;
            newState.batchUpdateStack.updated.push(uuid);
            newState.batchUpdateStack.updateCandidates = newState.batchUpdateStack.updateCandidates.filter(
                (e) => e.uuid !== uuid,
            );
            newState.batchUpdateStack.uploadCandidate = null;
            return newState;
        }
        case AppEvent.admin.TOGGLE_ACTION_FLAG: {
            const newState = { ...state };
            const { flagName } = action;
            newState.actionFlags[flagName] = !newState.actionFlags[flagName];
            return newState;
        }
        case AppEvent.admin.HOME_GEOJSON_LOADED: {
            const newState = { ...state };
            newState.trailsList = [...action.data];
            const collection = getHomeCollection(newState.trailsList);
            newState.mapActionStack.push({
                collection,
            });
            return newState;
        }
        case AppEvent.admin.LOAD_LOCALE_STORAGE_INTO_STATE: {
            const newState = JSON.parse(
                window.localStorage.getItem('completeState'),
            );
            return newState;
        }
        case AppEvent.admin.SAVE_STATE_INTO_LOCALE_STORAGE: {
            const newState = { ...state };
            newState.pois = null;
            newState.mapActionStack = [];
            newState.coordinates.unresolved = [];
            newState.coordinates.resolved = [];
            newState.dataStack = [
                newState.dataStack[newState.dataStack.length - 1],
            ];
            window.localStorage.setItem(
                'completeState',
                JSON.stringify(newState),
            );
            return newState;
        }
        case AppEvent.admin.PICK_CURRENT_ODOMETER: {
            const newState = { ...state };
            newState.current.odometer = action.odometer;
            return newState;
        }
        case AppEvent.map.FOCUS_MAP_FEATURE: {
            const newState = { ...state };
            newState.mapActionStack.push(getFocusAction([action.feature], true));
            return newState;
        }
        case AppEvent.map.PREVIEW_ACTIVE_COLLECTION: {
            const newState = { ...state };
            const activeCollection =
                newState.dataStack[newState.dataStack.length - 1].collection;
            const newAction = {
                collection: getTrailSegments4Map(activeCollection),
            };
            if (action.payload.fitBounds) {
                newAction.bounds = getCollectionBounds(activeCollection);
            }
            newState.mapActionStack.push(newAction);
            return newState;
        }
        case AppEvent.map.EDIT_ACTIVE_COLLECTION: {
            const newState = { ...state };
            const newAction = {
                draw: true,
            };
            if (newState.dataStack.length > 0) {
                newAction.collection =
                    newState.dataStack[
                        newState.dataStack.length - 1
                    ].collection;
            } else {
                newAction.collection = {
                    type: 'FeatureCollection',
                    features: [],
                };
            }
            if (
                action.payload.fitBounds &&
                newAction.collection.features.length
            ) {
                newAction.bounds = getCollectionBounds(newAction.collection);
            }
            newState.mapActionStack.push(newAction);
            return newState;
        }
        case AppEvent.map.SAVE_EDITED_COLLECTION: {
            const newState = { ...state };
            newState.mapActionStack.push({
                exportCollectionFromDraw: true,
            });
            return newState;
        }
        case AppEvent.admin.REMOVE_FEATURE_FROM_COLLECTION: {
            const newState = { ...state };
            const { feature } = action;
            const currentActiveRevision =
                newState.dataStack[newState.dataStack.length - 1];
            const newCollection = {
                ...currentActiveRevision.collection,
                features: currentActiveRevision.collection.features.filter(
                    (f) => f.properties.id !== feature.properties.id,
                ),
            };
            newState.dataStack.push({
                ...currentActiveRevision,
                collection: newCollection,
                statistics: getStatistics(newCollection),
                action: AppAction.FEATURE_REMOVED,
                time: new Date(),
                id: getUUID(),
            });
            return newState;
        }
        case AppEvent.map.UPDATE_ACTIVE_COLLECTION: {
            const newState = { ...state };
            newState.actionFlags.parsed = true;
            const { collection } = action.payload;
            const currentActiveRevision =
                newState.dataStack[newState.dataStack.length - 1];
            let newCollection = reParseCollection(collection, newState);
            newCollection = stickPoints2Lines(newCollection);
            newCollection = generateWPointGeoJSON(newCollection);
            newState.dataStack.push({
                ...currentActiveRevision,
                collection: newCollection,
                statistics: getStatistics(newCollection),
                action: action.payload.action,
                time: new Date(),
                id: getUUID(),
            });
            return newState;
        }
        case AppEvent.admin.UPDATE_SINGLE_FEATURE_PROPERTY: {
            const newState = { ...state };
            const currentActiveRevision =
                newState.dataStack[newState.dataStack.length - 1];
            currentActiveRevision.collection.features.find(
                (f) => f.properties.id === action.id,
            ).properties[action.key] = action.value;
            return newState;
        }
        case AppEvent.admin.SIMPLIFY_LINES: {
            const newState = { ...state };
            newState.actionFlags.simplified = true;
            const currentActiveRevision =
                newState.dataStack[newState.dataStack.length - 1];
            const collection = getSimplifiedCollection(
                currentActiveRevision.collection,
            );
            const newCoordinates = getAllCoordinates(collection);
            newCoordinates.forEach((newPoint) => {
                if (
                    !newState.coordinates.resolved.some(
                        (oldPoint) =>
                            oldPoint.lng === newPoint.lng &&
                            oldPoint.lat === newPoint.lat,
                    )
                ) {
                    newState.coordinates.unresolved.push(newPoint);
                }
            });
            newState.dataStack.push({
                ...currentActiveRevision,
                collection,
                statistics: getStatistics(collection),
                action: AppAction.SIMPLIFY,
                time: new Date(),
                id: getUUID(),
            });
            return newState;
        }
        case AppEvent.admin.STICKY_POINTS_2_LINES: {
            const newState = { ...state };
            newState.actionFlags.stickyPoints = true;
            const currentActiveRevision =
                newState.dataStack[newState.dataStack.length - 1];
            let collection = stickPoints2Lines(
                currentActiveRevision.collection,
            );
            collection = generateWPointGeoJSON(collection);
            newState.dataStack.push({
                ...currentActiveRevision,
                collection,
                statistics: getStatistics(collection),
                action: AppAction.STICKYPOINTS,
                time: new Date(),
                id: getUUID(),
            });
            return newState;
        }
        case AppEvent.admin.ELEVATE_COLLECTION: {
            const newState = { ...state };
            newState.actionFlags.elevated = true;
            const currentActiveRevision =
                newState.dataStack[newState.dataStack.length - 1];
            const collection = getElevatedCollection(
                currentActiveRevision.collection,
                newState.coordinates.resolved,
            );
            newState.dataStack.push({
                ...currentActiveRevision,
                collection,
                statistics: getStatistics(collection),
                action: AppAction.ELEVATE,
                time: new Date(),
                id: getUUID(),
            });
            return newState;
        }
        case AppEvent.admin.SMOOTH_LINES: {
            const newState = { ...state };
            const currentActiveRevision =
                newState.dataStack[newState.dataStack.length - 1];
            const collection = getSmoothCollection(
                currentActiveRevision.collection,
            );
            newState.dataStack.push({
                ...currentActiveRevision,
                collection,
                statistics: getStatistics(collection),
                action: AppAction.SMOOTHED,
                time: new Date(),
                id: getUUID(),
            });
            return newState;
        }
        case AppEvent.admin.ENRICH_COLLECTION: {
            const newState = { ...state };
            const currentActiveRevision =
                newState.dataStack[newState.dataStack.length - 1];
            const collection = getEnrichedCollection(
                currentActiveRevision.collection,
            );
            newState.dataStack.push({
                ...currentActiveRevision,
                collection,
                statistics: getStatistics(collection),
                action: AppAction.ENRICHED,
                time: new Date(),
                id: getUUID(),
            });
            return newState;
        }
        case AppEvent.admin.INVERT_COLLECTION: {
            const newState = { ...state };
            const currentActiveRevision =
                newState.dataStack[newState.dataStack.length - 1];
            const inverted = getInvertedCollection(
                currentActiveRevision.collection,
            );
            const parsed = reParseCollection(inverted, newState);
            newState.actionFlags.parsed = true;
            const simplified = getSimplifiedCollection(parsed);
            newState.actionFlags.simplified = true;
            let stickied = stickPoints2Lines(simplified);
            const elevated = getElevatedCollection(
                stickied,
                newState.coordinates.resolved,
            );
            newState.actionFlags.elevated = true;
            const enriched = getEnrichedCollection(elevated);
            newState.dataStack.push({
                ...currentActiveRevision,
                collection: enriched,
                statistics: getStatistics(enriched),
                action: AppAction.INVERTED,
                time: new Date(),
                id: getUUID(),
            });
            return newState;
        }
        case AppEvent.admin.COORDINATES_FETCHING_STARTED: {
            return {
                ...state,
                actionFlags: {
                    ...state.actionFlags,
                    elevationFetchInProgress: true,
                },
            };
        }
        case AppEvent.admin.COORDINATES_RESOLVED: {
            const newState = { ...state };
            newState.actionFlags.elevationFetchInProgress = false;
            action.data.forEach((newPoint) => {
                if (newPoint.elevation !== undefined || newPoint.elevation !== null) {
                    if (
                        !newState.coordinates.resolved.some(
                            (oldPoint) =>
                                oldPoint.lng === newPoint.lng &&
                                oldPoint.lat === newPoint.lat,
                        )
                    ) {
                        newState.coordinates.resolved.push(newPoint);
                    }
                    const unresolvedIndex = newState.coordinates.unresolved.findIndex(
                        (oldPoint) =>
                            oldPoint.lng === newPoint.lng &&
                            oldPoint.lat === newPoint.lat,
                    );
                    if (unresolvedIndex > -1) {
                        newState.coordinates.unresolved.splice(
                            unresolvedIndex,
                            1,
                        );
                    }
                }
            });
            window.localStorage.setItem(
                'resolvedCoordinates',
                JSON.stringify(newState.coordinates.resolved),
            );
            return newState;
        }
        case AppEvent.admin.COORDINATES_LOADED_FROM_STORAGE: {
            const newState = { ...state };
            newState.coordinates.resolved = [...action.coordinates];
            return newState;
        }
        case AppEvent.map.SET_COLLECTION_ACTIVE_BY_ID: {
            const newState = { ...state };
            if (!newState.dataStack || !newState.dataStack.length) {
                return newState;
            }
            const currentActiveCollectionId =
                newState.dataStack[newState.dataStack.length - 1].id;
            if (currentActiveCollectionId === action.payload) {
                return newState;
            } else {
                const newActiveRevision = newState.dataStack.find(
                    (r) => r.id === action.payload,
                );
                const newStack = newState.dataStack.filter(
                    (r) => r.id !== action.payload,
                );
                newStack.push({
                    ...newActiveRevision,
                    time: new Date(),
                });
                newState.dataStack = [...newStack];
                return newState;
            }
        }
        case AppEvent.admin.GEO_FILE_UPLOADED: {
            const newState = { ...state };
            newState.actionFlags = {
                elevationFetchInProgress: false,
                parsed: true,
                simplified: false,
                stickyPoints: false,
                elevated: false,
                published: false,
            };
            const { collection, slugKey, fileName, isDrawn } = action.payload;
            const newCollection = parseCollection(collection, newState, isDrawn);
            newState.dataStack.push({
                id: getUUID(),
                slugKey,
                fileName,
                collection: newCollection,
                statistics: getStatistics(newCollection),
                time: new Date(),
                action: AppAction.GEO_FILE_UPLOAD,
            });
            return newState;
        }
        case AppEvent.admin.GENERATE_FEATURE_NAMES:
            const newState = { ...state };
            const currentActiveRevision =
                newState.dataStack[newState.dataStack.length - 1];
            const collection = generateFeaturesNames(
                currentActiveRevision.collection,
            );
            newState.dataStack.push({
                ...currentActiveRevision,
                collection,
                statistics: getStatistics(collection),
                action: AppAction.NAME_GENERATED,
                time: new Date(),
                id: getUUID(),
            });
            return newState;
        case AppEvent.admin.PURGE_COLLECTIONS: {
            const newState = { ...state };
            const { uuid } = action;
            newState.trailsList = newState.trailsList.filter(
                (t) => t.trailUUID !== uuid,
            );
            return newState;
        }
        case AppEvent.admin.COLLECTION_2_COMBINE: {
            const newState = { ...state };
            const { collection, unshift } = action;
            if (unshift) {
                newState.combineStack.unshift(collection);
            } else {
                newState.combineStack.push(collection);
            }
            newState.mapActionStack.push({
                features2preview: getCollectionLinePreviewFeatures(
                    newState.combineStack,
                ),
                bounds: getCollectionBounds(collection),
            });
            return newState;
        }
        case AppEvent.admin.REMOVE_COLLECTION_FROM_COMBINE: {
            const newState = { ...state };
            const { uuid } = action;
            newState.combineStack = newState.combineStack.filter(
                (collection) => {
                    const collectionHasUuid = getLines(collection).some(
                        (line) => line.properties.trailUUID === uuid,
                    );
                    return !collectionHasUuid;
                },
            );
            return newState;
        }
        case AppEvent.admin.COMBINE_COLLECTIONS: {
            const newState = { ...state };
            const combinedCollection = combineCollections(
                newState.combineStack,
            );
            const newCollection = parseCollection(combinedCollection, newState);
            newState.actionFlags.parsed = true;
            newState.dataStack.push({
                id: getUUID(),
                slugKey: 'combined',
                fileName: 'combined',
                collection: newCollection,
                statistics: getStatistics(newCollection),
                time: new Date(),
                action: AppAction.COMBINED,
            });
            return newState;
        }
        case AppEvent.admin.COLLECTION_PUBLISHED: {
            const newState = { ...state };
            newState.actionFlags.published = true;
            return newState;
        }
        case AppEvent.admin.GOT_TOKEN: {
            const newState = {
                ...state,
                tokenSetup: action.payload,
            };
            return newState;
        }
        case AppEvent.admin.GOT_POIS: {
            const newState = {
                ...state,
                pois: {
                    ...state.pois,
                    [action.payload.keyname]: action.payload.data,
                },
            };
            return newState;
        }
        case AppEvent.admin.GOT_INITIAL_SETUP: {
            const newState = {
                ...state,
                initialSetup: action.payload,
            };
            return newState;
        }
        case AppEvent.map.SET_LAYER_SET_VISIBILITY: {
            const newState = {
                ...state,
            };
            const { layerSet, visible } = action.payload;
            newState.mapActionStack.push({
                layerSet,
                visible,
            });
            return newState;
        }
        default:
            return state;
    }
}
