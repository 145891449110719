import { AppEvent } from '../constants/events';
import { UrlContstants, processingParameters } from '../constants/constants';

export const addCoordinate = (coordinate, manual) => {
    if (manual) {
        return {
            type: AppEvent.draw.ADD_COORDINATE_2_STACK,
            coordinate,
            onRoad: false,
        };
    }
    return function (dispatch) {
        return fetch(
            `${UrlContstants.maptoolkit.nearestPoint}?point=${coordinate[1]},${coordinate[0]}&api_key=${UrlContstants.maptoolkit.apiKey}`,
        )
            .then((response) => {
                return response.json();
            })
            .then((body) => {
                const { distance } = body;
                console.log(`Nearest Point Distance ${distance}m`);
                if (distance < processingParameters.nearestPointTreshold) {
                    dispatch({
                        type: AppEvent.draw.ADD_COORDINATE_2_STACK,
                        coordinate: body.coordinates,
                        onRoad: true,
                    });
                } else {
                    dispatch({
                        type: AppEvent.draw.ADD_COORDINATE_2_STACK,
                        coordinate,
                        onRoad: false,
                    });
                }
            })
            .catch((error) => {
                console.error('Error:', error);
            });
    };
};

export const fetchSegment = (segment) => {
    return function (dispatch) {
        const { start, end, onRoadStart, onRoadEnd } = segment;
        console.log('segment');
        console.log(segment);
        if (onRoadStart !== false && onRoadEnd !== false) {
            const points = [`${start[1]},${start[0]}`, `${end[1]},${end[0]}`];
            return fetch(
                `${UrlContstants.maptoolkit.route}&point=${points[0]}&point=${points[1]}&api_key=${UrlContstants.maptoolkit.apiKey}`,
            )
                .then((response) => {
                    return response.json();
                })
                .then((body) => {
                    const { paths } = body;
                    if (paths && paths.length) {
                        paths.forEach((path) => {
                            dispatch({
                                type: AppEvent.draw.ADD_PATH_2_STACK,
                                path,
                            });
                            dispatch({
                                type: AppEvent.draw.PARSE_DRAWN_PATH,
                                path,
                            });
                        });
                        dispatch({
                            type: AppEvent.draw.PREVIEW_DRAWN_COLLECTION,
                        });
                    }
                })
                .catch((error) => {
                    console.error('Error:', error);
                });
        } else {
            dispatch({
                type: AppEvent.draw.ADD_PATH_2_STACK,
                path: ' ',
            });
            dispatch({
                type: AppEvent.draw.APPEND_COORDINATE_2_TRAIL,
                coordinate: end,
            });
            dispatch({
                type: AppEvent.draw.PREVIEW_DRAWN_COLLECTION,
            });
        }
    };
};

export const clearLastSegment = () => {
    return {
        type: AppEvent.draw.CLEAR_LAST_SEGMENT,
    };
};

export const clearAllSegments = () => {
    return {
        type: AppEvent.draw.CLEAR_ALL_SEGMENTS,
    };
};

export const previewDrawnCollection = () => {
    return {
        type: AppEvent.draw.PREVIEW_DRAWN_COLLECTION,
    };
};

export const toggleDrawing = () => {
    return {
        type: AppEvent.draw.TOGGLE_DRAWING,
    };
};
