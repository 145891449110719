import React from 'react';
import { connect } from 'react-redux';
import TrailGeneralProperties from '../components/client/TrailGeneralProperties';
import TrailElevationCharts from '../components/client/TrailElevationCharts';
import TrailWaypoints from '../components/client/TrailWaypoints';

const SingleTrailLayout = (props) => {
    const { collection } = props;
    return (
        <div className="content">
            <div className="content__list--wider">
                <TrailGeneralProperties collection={collection} />
                <div className="margin-vertical" />
                <TrailElevationCharts collection={collection} />
                <TrailWaypoints collection={collection} />
            </div>
        </div>
    );
};

const mapStateToProps = (state) => {
    return {
        mapLoaded: state.presentationState.mapLoaded,
    };
};

const mapDispatchToProps = () => {
    return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(SingleTrailLayout);
