export const AppEvent = {
    admin: {
        LOAD_LOCALE_STORAGE_INTO_STATE: 'LOAD_LOCALE_STORAGE_INTO_STATE',
        SAVE_STATE_INTO_LOCALE_STORAGE: 'SAVE_STATE_INTO_LOCALE_STORAGE',
        GOT_TOKEN: 'GOT_TOKEN',
        GOT_POIS: 'GOT_POIS',
        GOT_INITIAL_SETUP: 'GOT_INITIAL_SETUP',
        GEO_FILE_UPLOADED: 'GEO_FILE_UPLOADED',
        GEO_FILE_FETCHED_4_UPDATE: 'GEO_FILE_FETCHED_4_UPDATE',
        UPDATE_SINGLE_COLLECTION_IN_BATCH_PROCESS: 'UPDATE_SINGLE_COLLECTION_IN_BATCH_PROCESS',
        COLLECTION_BATCH_UPDATE_COMPLETED: 'COLLECTION_BATCH_UPDATE_COMPLETED',
        SIMPLIFY_LINES: 'SIMPLIFY_LINES',
        SMOOTH_LINES: 'SMOOTH_LINES',
        ELEVATED_LINES: 'ELEVATED_LINES',
        ELEVATE_COLLECTION: 'ELEVATE_COLLECTION',
        REMOVE_FEATURE_FROM_COLLECTION: 'REMOVE_FEATURE_FROM_COLLECTION',
        ENRICH_COLLECTION: 'ENRICH_COLLECTION',
        INVERT_COLLECTION: 'INVERT_COLLECTION',
        COLLECTION_PUBLISHED: 'COLLECTION_PUBLISHED',
        STICKY_POINTS_2_LINES: 'STICKY_POINTS_2_LINES',
        COORDINATES_FETCHING_STARTED: 'COORDINATES_FETCHING_STARTED',
        COORDINATES_RESOLVED: 'COORDINATES_RESOLVED',
        COORDINATES_LOADED_FROM_STORAGE: 'COORDINATES_LOADED_FROM_STORAGE',
        UPDATE_SINGLE_FEATURE_PROPERTY: 'UPDATE_SINGLE_FEATURE_PROPERTY',
        PICK_CURRENT_ODOMETER: 'PICK_CURRENT_ODOMETER',
        HOME_GEOJSON_LOADED: 'HOME_GEOJSON_LOADED_ADMIN',
        TOGGLE_ACTION_FLAG: 'TOGGLE_ACTION_FLAG',
        COLLECTION_2_COMBINE: 'COLLECTION_2_COMBINE',
        REMOVE_COLLECTION_FROM_COMBINE: 'REMOVE_COLLECTION_FROM_COMBINE',
        COMBINE_COLLECTIONS: 'COMBINE_COLLECTIONS',
        PURGE_COLLECTIONS: 'PURGE_COLLECTIONS',
        POPULATE_CANDIDATES_FOR_BATCH_FETCH: 'POPULATE_CANDIDATES_FOR_BATCH_FETCH',
        POPULATE_CANDIDATES_FOR_BATCH_UPDATE: 'POPULATE_CANDIDATES_FOR_BATCH_UPDATE',
        NEAREST_TRAILS_LOADED: 'NEAREST_TRAILS_LOADED',
        GENERATE_FEATURE_NAMES: 'GENERATE_FEATURE_NAMES',
    },
    map: {
        PREVIEW_ACTIVE_COLLECTION: 'PREVIEW_ACTIVE_COLLECTION',
        UPDATE_ACTIVE_COLLECTION: 'UPDATE_ACTIVE_COLLECTION',
        EDIT_ACTIVE_COLLECTION: 'EDIT_ACTIVE_COLLECTION',
        SAVE_EDITED_COLLECTION: 'SAVE_EDITED_COLLECTION',
        SET_COLLECTION_ACTIVE_BY_ID: 'SET_COLLECTION_ACTIVE_BY_ID',
        FOCUS_MAP_FEATURE: 'FOCUS_MAP_FEATURE',
        MAP_LOADED: 'MAP_LOADED',
        SET_LAYER_SET_VISIBILITY: 'SET_LAYER_SET_VISIBILITY',
    },
    client: {
        SET_CLIENT_MOBILE_LAYOUT: 'SET_CLIENT_MOBILE_LAYOUT',
        SET_MODAL_STATE: 'SET_MODAL_STATE',
        HOME_GEOJSON_LOADED: 'HOME_GEOJSON_LOADED_CLIENT',
        GROUP_GEOJSON_LOADED: 'GROUP_GEOJSON_LOADED_CLIENT',
        TRAIL_GEOJSON_LOADED: 'TRAIL_GEOJSON_LOADED',
        PREVIEW_ACTIVE_TRAIL_COLLECTION: 'PREVIEW_ACTIVE_TRAIL_COLLECTION',
        PREVIEW_ACTIVE_HOME_COLLECTION: 'PREVIEW_ACTIVE_HOME_COLLECTION',
        PREVIEW_ACTIVE_TRAILS_GROUP_COLLECTION:
            'PREVIEW_ACTIVE_TRAILS_GROUP_COLLECTION',
        SET_TRAIL_LIST_LAYOUT: 'SET_TRAIL_LIST_LAYOUT',
        SET_COLLECTION_SORT_RULE: 'SET_COLLECTION_SORT_RULE',
        TOGGLE_PLAY_TRAIL_PREVIEW: 'TOGGLE_PLAY_TRAIL_PREVIEW',
        SET_TEMPORARY_TRAILS_SELECTION: 'SET_TEMPORARY_TRAILS_SELECTION',
    },
    draw: {
        ADD_COORDINATE_2_STACK: 'ADD_COORDINATE_2_STACK',
        APPEND_COORDINATE_2_TRAIL: 'APPEND_COORDINATE_2_TRAIL',
        CLEAR_LAST_SEGMENT: 'CLEAR_LAST_SEGMENT',
        CLEAR_ALL_SEGMENTS: 'CLEAR_ALL_SEGMENTS',
        ADD_PATH_2_STACK: 'ADD_PATH_2_STACK',
        PREVIEW_DRAWN_COLLECTION: 'PREVIEW_DRAWN_COLLECTION',
        PARSE_DRAWN_PATH: 'PARSE_DRAWN_PATH',
        TOGGLE_DRAWING: 'TOGGLE_DRAWING',
    },
};
