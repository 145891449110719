import React, { useState, useEffect } from 'react';
import {
    setCollectionActiveById,
    initiateActiveCollectionPreview,
    initiateActiveCollectionEdit,
    saveEditedCollection,
} from '../../store/adminActions';
import { getAllRevisions } from '../../store/adminSelector';
import { connect } from 'react-redux';

function ManuallyEditCollectionOnMap(props) {
    const { allRevisions, saveEditedCollection, editCollectionById } = props;
    const [activeRevisionId, setActiveRevisionId] = useState();
    const [editModeActive, setEditModeActive] = useState(false);
    useEffect(() => {
        if (allRevisions && allRevisions.length) {
            setActiveRevisionId(allRevisions[allRevisions.length - 1].id);
        }
    }, [allRevisions]);
    const onSaveEditInitiated = () => {
        saveEditedCollection();
        setEditModeActive(false);
    };
    const onEditInitiated = () => {
        editCollectionById(activeRevisionId);
        setEditModeActive(true);
    };
    const renderStatistics = () => {
        if (!activeRevisionId) {
            return null;
        }
        const {
            totalPoints,
            totalLines,
            totalCoordinatesByLine,
        } = allRevisions.find((r) => r.id === activeRevisionId).statistics;
        return (
            <label>
                Total {JSON.stringify(totalCoordinatesByLine)} coordinates in{' '}
                {totalLines} lines + {totalPoints} points.
            </label>
        );
    };
    return (
        <>
            {editModeActive ? (
                <button onClick={onSaveEditInitiated}>Save</button>
            ) : (
                <button onClick={onEditInitiated}>Manually edit & draw</button>
            )}
            {renderStatistics()}
        </>
    );
}

const mapStateToProps = (state) => {
    return {
        allRevisions: getAllRevisions(state),
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        previewCollectionById: (id) => {
            dispatch(setCollectionActiveById(id));
            dispatch(initiateActiveCollectionPreview(true));
        },
        editCollectionById: (id) => {
            if (id) {
                dispatch(setCollectionActiveById(id));
            }
            dispatch(initiateActiveCollectionEdit());
        },
        saveEditedCollection: () => {
            dispatch(saveEditedCollection());
        },
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(ManuallyEditCollectionOnMap);
