import React, { useEffect, useState } from 'react';
import Fuse from 'fuse.js';
import {
    getAllTrailsList,
    getTrailGeoJson2Combine,
    initiateActiveCollectionPreview,
    initiateCombine,
    removeTrailFromCombine,
    getNearestTrailsList,
} from '../../store/adminActions';
import { focusFeatureOnMap } from '../../store/presentationActions';
import { trails2combine } from '../../store/adminSelector';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import classNames from 'classnames';
import { decodeWpGeoJSON2Line } from '../../helpers/geoSpatialHelper';

function CollectionsCombiner(props) {
    const {
        initAllTrailsList,
        trailsList,
        getTrailGeoJson2Combine,
        trails2combine,
        removeTrailFromCombine,
        initiateCombine,
        getNearestTrailsList,
        before,
        after,
        focusFeatureOnMap,
    } = props;

    const [searchTerm, setSearchTerm] = useState('');
    const [typeFilter, setTypeFilter] = useState(0);
    const [selectedPlaceUUID, setSelectedPlaceUUID] = useState(0);
    const [localTrails2combine, setLocalTrails2combine] = useState(trails2combine);

    // trals list load
    useEffect(() => {
        if (!trailsList.length) {
            initAllTrailsList();
        }
    }, [trailsList, initAllTrailsList]);

    // trails2combine change
    useEffect(() => {
        if (JSON.stringify(trails2combine) !== JSON.stringify(localTrails2combine)) {
            setLocalTrails2combine(trails2combine);
        }
    }, [trails2combine, localTrails2combine, setLocalTrails2combine]);

    // trails2combine change
    useEffect(() => {
        if (localTrails2combine.length) {
            const { start } = localTrails2combine[0];
            const { end } = localTrails2combine[localTrails2combine.length - 1];
            getNearestTrailsList(start, 'before');
            getNearestTrailsList(end, 'after');
        }
    }, [localTrails2combine, getNearestTrailsList]);
    // Loader
    if (!trailsList || !trailsList.length) {
        return <FormattedMessage id="admin.loadingTrails" />;
    }
    const onSuggest = () => {
        if (trails2combine.length) {
            const { start } = trails2combine[0];
            const { end } = trails2combine[trails2combine.length - 1];
            getNearestTrailsList(start, 'before');
            getNearestTrailsList(end, 'after');
        }
    };
    const onSelectTrail = (e) => {
        if (e.target.value) {
            getTrailGeoJson2Combine(e.target.value);
        }
    };
    const onSetTypeFilter = (e) => {
        if (e.target.value) {
            setTypeFilter(parseInt(e.target.value, 10));
        }
    };
    const onSetSelectedPlaceUUID = (e) => {
        if (e.target.value) {
            setSelectedPlaceUUID(e.target.value);
        }
    };
    const handleFocusFeatureOnMap = (wpGeoJSON, properties) => {
        const line2focus = decodeWpGeoJSON2Line(wpGeoJSON, properties);
        focusFeatureOnMap(line2focus);
    };
    let allPlaces = [];
    trailsList.forEach((trail) => {
        trail.placeObjects.forEach((place) => {
            if (!allPlaces.some((p) => p.uuid === place.uuid)) {
                allPlaces.push(place);
            }
        });
    });
    allPlaces = allPlaces.sort((a, b) => a.name - b.name);
    const enrichedTrails = trailsList.map((item) => {
        return {
            ...item,
            places: item.placeObjects.map((item) => item.name).join(','),
        };
    });
    const fuse = new Fuse(enrichedTrails, {
        keys: ['name'],
        minMatchCharLength: 5,
        findAllMatches: true,
        threshold: 0.3,
    });
    let filteredTrails2combine =
        searchTerm.length > 2
            ? fuse.search(searchTerm).map((e) => e.item)
            : enrichedTrails;
    // Filter by type
    if (typeFilter) {
        filteredTrails2combine = filteredTrails2combine.filter(
            (trail) => trail.status === typeFilter,
        );
    }
    // Filter by place
    if (selectedPlaceUUID) {
        filteredTrails2combine = filteredTrails2combine.filter((trail) =>
            trail.placeObjects.some(
                (place) => place.uuid === selectedPlaceUUID,
            ),
        );
    }
    filteredTrails2combine = filteredTrails2combine.sort(
        (a, b) => a.trailID - b.trailID,
    );
    return (
        <div>
            <div>
                <input
                    type="text"
                    value={searchTerm}
                    onChange={(e) => {
                        setSearchTerm(e.target.value);
                    }}
                />
                <select value={typeFilter} onChange={onSetTypeFilter}>
                    <option key={0} value={0}>
                        All statuses
                    </option>
                    <option key={1} value={1}>
                        Trails
                    </option>
                    <option key={2} value={2}>
                        Segments
                    </option>
                </select>
                <select
                    value={selectedPlaceUUID}
                    onChange={onSetSelectedPlaceUUID}
                >
                    <option key={0} value={0}>
                        All places
                    </option>
                    {allPlaces.map((place) => (
                        <option key={place.uuid} value={place.uuid}>
                            {place.name}
                        </option>
                    ))}
                </select>
                <button
                    onClick={initiateCombine}
                    className={classNames({
                        inactive: trails2combine.length < 2,
                    })}
                >
                    <span>Combine</span>
                </button>
                <button
                    onClick={onSuggest}
                    className={classNames({
                        inactive: !trails2combine.length,
                    })}
                >
                    <span>Suggest</span>
                </button>
            </div>
            <div>
                {!trails2combine.length && (
                    <select onChange={onSelectTrail}>
                        <option key={'dummy'} value={null}>
                            Pick a trail to start combining
                        </option>
                        {filteredTrails2combine.map((trail) => {
                            const { trailUUID, trailID, name, places } = trail;
                            return (
                                <option key={trailUUID} value={trailUUID}>
                                    {`${trailID} - ${name} # (${places})`}
                                </option>
                            );
                        })}
                    </select>
                )}
            </div>
            <ul className="combine-list">
                {before.map((trail) => {
                    const { trailUUID, name, distance, wpGeoJSON, elevGain, elevLoss } = trail;
                    return (
                        <li
                            key={trailUUID}
                            className="combine-list__suggestion"
                            onMouseEnter={() =>
                                handleFocusFeatureOnMap(wpGeoJSON, trail)
                            }
                        >
                            <button
                                onClick={(e) => {
                                    e.stopPropagation();
                                    getTrailGeoJson2Combine(trailUUID, true);
                                }}
                            >
                                &#8675;
                            </button>
                            {`${name} [${distance} km, +${elevGain} m, -${elevLoss} m]`}
                        </li>
                    );
                })}
            </ul>
            <ol className="combine-list">
                {trails2combine.map((trail) => {
                    const { trailUUID, trailName, lines, points } = trail;
                    return (
                        <li key={trailUUID}>
                            {`${trailName} (${lines} + ${points})`}
                            <button
                                onClick={() =>
                                    removeTrailFromCombine(trailUUID)
                                }
                            >
                                -
                            </button>
                        </li>
                    );
                })}
            </ol>
            <ul className="combine-list">
                {after.map((trail) => {
                    const { trailUUID, name, distance, wpGeoJSON, elevGain, elevLoss } = trail;
                    return (
                        <li
                            key={trailUUID}
                            className="combine-list__suggestion"
                            onMouseEnter={() =>
                                handleFocusFeatureOnMap(wpGeoJSON, trail)
                            }
                        >
                            <button
                                onClick={(e) => {
                                    e.stopPropagation();
                                    getTrailGeoJson2Combine(trailUUID);
                                }}
                            >
                                &#8673;
                            </button>
                            {`${name} [${distance} km, +${elevGain} m, -${elevLoss} m]`}
                        </li>
                    );
                })}
            </ul>
        </div>
    );
}

const mapStateToProps = (state) => {
    return {
        trailsList: state.adminState.trailsList,
        trails2combine: trails2combine(state),
        before: state.adminState.combiner.before,
        after: state.adminState.combiner.after,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        getTrailGeoJson2Combine: (uuid, unshift) =>
            dispatch(getTrailGeoJson2Combine(uuid, unshift)),
        focusFeatureOnMap: (feature) => dispatch(focusFeatureOnMap(feature)),
        removeTrailFromCombine: (uuid) =>
            dispatch(removeTrailFromCombine(uuid)),
        initiateCombine: () => {
            dispatch(initiateCombine());
            dispatch(initiateActiveCollectionPreview(true));
        },
        initAllTrailsList: () => dispatch(getAllTrailsList()),
        getNearestTrailsList: (point, part) =>
            dispatch(getNearestTrailsList(point, part)),
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(CollectionsCombiner);
