/* global togpx */
/* global tokml */
import React from 'react';
import { connect } from 'react-redux';
import { getTrailCollectionForExport } from '../../store/presentationSelector';
import Divider from '@material-ui/core/Divider';
import Modal from './Modal';
import { saveString2File, exportCSVFile } from '../../helpers/fileHelper';
import { makeStyles } from '@material-ui/core/styles';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionActions from '@material-ui/core/AccordionActions';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Button from '@material-ui/core/Button';
import GetAppIcon from '@material-ui/icons/GetApp';
import {getLines, getPoints} from "../../helpers/collectionHelper";

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
    },
    heading: {
        fontSize: theme.typography.pxToRem(15),
        fontWeight: theme.typography.fontWeightRegular,
        flexBasis: '25%',
        flexShrink: 0,
    },
    secondaryHeading: {
        fontSize: theme.typography.pxToRem(15),
        color: theme.palette.text.secondary,
    },
}));

const ExportTrail2File = (props) => {
    const { collection, richCollection, fileName } = props;
    const classes = useStyles();
    const [expanded, setExpanded] = React.useState(false);

    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };

    const saveGPX = () => {
        const gpx = togpx(collection);
        saveString2File(gpx, `${fileName}.gpx`);
    };
    const saveKML = () => {
        const kml = tokml(collection);
        saveString2File(kml, `${fileName}.kml`);
    };
    const saveGeoJSON = () => {
        saveString2File(JSON.stringify(collection), `${fileName}.geojson`);
    };
    const savePathCoordinates = () => {
        const headers = {
            lng: 'Geografska duzina',
            lat: 'Geografska sirina',
            elevation: 'Nadmorska visina u metrima',
        };
        const pathCoordinates = getLines(richCollection)[0]
            .geometry
            .coordinates
            .map((c) => {
                return {
                    lng: c[0],
                    lat: c[1],
                    elevation: c[2],
                };
            });
        exportCSVFile(headers, pathCoordinates,`${fileName}_koordinate_staze.csv`);
    };
    const saveWaypoints = () => {
        const headers = {
            name: 'Naziv lokacije',
            elevation: 'Nadmorska visina u metrima',
            odometer: 'Udaljenost od pocetka [km]',
            nextStepDist: 'Udaljenost do sljedece tacke [km]',
            elevGain: 'Ukupni uspon do tacke [m]',
            elevLoss: 'Ukupni spust do tacke [m]',
            lng: 'Geografska duzina',
            lat: 'Geografska sirina',
        };
        const waypoints = getPoints(richCollection)
            .map((c) => {
                const {
                    name,
                    odometer,
                    nextStepDist,
                    elevGain,
                    elevLoss,
                } = c.properties;
                const { coordinates } = c.geometry;
                return {
                    name,
                    elevation: coordinates[2],
                    odometer,
                    nextStepDist,
                    elevGain,
                    elevLoss,
                    lng: coordinates[0],
                    lat: coordinates[1],
                };
            })
            .sort((a, b) => a.odometer - b.odometer);
        exportCSVFile(headers, waypoints,`${fileName}_tacke_puta.csv`);
    };
    return (
        <Modal
            buttonLabel="Preuzmi"
            buttonVariant="icon"
            buttonIcon={<GetAppIcon />}
            modalTitle="Preuzmi stazu u standardnim formatima"
        >
            <div className="margin-vertical" />
            <div className={classes.root}>
                <Typography>
                    Pružamo vam mogućnost da ovu stazu preuzmete u odgovarajućem
                    formatu. Molimo da pregledate sljedeće dostupne opcije:
                </Typography>
                <div className="margin-vertical" />
                <Accordion
                    expanded={expanded === 'gpx'}
                    onChange={handleChange('gpx')}
                >
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                    >
                        <Typography className={classes.heading}>GPX</Typography>
                        <Typography className={classes.secondaryHeading}>
                            Standardni format GPS zapisa
                        </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Typography>
                            GPX je format GPS zapisa u XML shemi. Ovo je
                            uobičajeni format GPS podataka standardan za skoro
                            sve aplikacije za mapiranje i rutiranje. ukolik ne
                            znate koji format odabrati, ovaj će najvjerovatnije
                            biti ispravan.{' '}
                            <a
                                href="https://www.topografix.com/gpx.asp"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                Tehnički detalji i specifikacija
                            </a>
                        </Typography>
                    </AccordionDetails>
                    <Divider />
                    <AccordionActions>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={saveGPX}
                        >
                            Spasi u GPX formatu
                        </Button>
                    </AccordionActions>
                </Accordion>
                <Accordion
                    expanded={expanded === 'kml'}
                    onChange={handleChange('kml')}
                >
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel2a-content"
                        id="panel2a-header"
                    >
                        <Typography className={classes.heading}>KML</Typography>
                        <Typography className={classes.secondaryHeading}>
                            Format za pregled u Google Earth
                        </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Typography>
                            KML je Google-ov standard GPS zapisa i populariziran
                            je korištenjem Google Earth preglednika. Ovaj format
                            je pogodan upravo za ovu namjenu, tj. ukoliko želite
                            pregledati ovu stazu u 3D okruženju Google Earth-a.{' '}
                            <a
                                href="https://developers.google.com/kml/documentation"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                Tehnički detalji i specifikacija
                            </a>
                        </Typography>
                    </AccordionDetails>
                    <Divider />
                    <AccordionActions>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={saveKML}
                        >
                            Spasi u KML formatu
                        </Button>
                    </AccordionActions>
                </Accordion>
                <Accordion
                    expanded={expanded === 'tcx'}
                    onChange={handleChange('tcx')}
                >
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel2a-content"
                        id="panel2a-header"
                    >
                        <Typography className={classes.heading}>TCX</Typography>
                        <Typography className={classes.secondaryHeading}>
                            Format zapisa za Garmin uređaje
                        </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Typography>
                            TCX je XML standard zapisa GPS podataka i trening
                            aktivnost na svim Garmin uređajima. Ovaj format ćete
                            odabrati ukoliko želite snimiti ovu stazu direktno
                            na vaš Garmin trening ili uređaj za navigaciju.
                            <a
                                href="https://en.wikipedia.org/wiki/Training_Center_XML"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                Tehnički detalji i specifikacija
                            </a>
                        </Typography>
                    </AccordionDetails>
                    <Divider />
                    <AccordionActions>
                        <Button disabled variant="contained">
                            Uskoro...
                        </Button>
                    </AccordionActions>
                </Accordion>
                <Accordion
                    expanded={expanded === 'geojson'}
                    onChange={handleChange('geojson')}
                >
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel2a-content"
                        id="panel2a-header"
                    >
                        <Typography className={classes.heading}>
                            GeoJSON
                        </Typography>
                        <Typography className={classes.secondaryHeading}>
                            JSON standard
                        </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Typography>
                            GeoJSON je usvojeni JSON standard za zapis
                            georeferenciranih podataka. Ovaj format ćete
                            najvjerovatnije koristiti ukoliko ste programer ili
                            GIS stručnjak za potrebe uvoza i obrade u druge
                            aplikacije.{' '}
                            <a
                                href="https://geojson.org/"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                Tehnički detalji i specifikacija
                            </a>
                        </Typography>
                    </AccordionDetails>
                    <Divider />
                    <AccordionActions>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={saveGeoJSON}
                        >
                            Spasi u GeoJSON formatu
                        </Button>
                    </AccordionActions>
                </Accordion>
                <Accordion
                    expanded={expanded === 'csv'}
                    onChange={handleChange('csv')}
                >
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel2a-content"
                        id="panel2a-header"
                    >
                        <Typography className={classes.heading}>
                            CSV
                        </Typography>
                        <Typography className={classes.secondaryHeading}>
                            Tabelarni standard
                        </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Typography>
                            CSV je najprihvaceniji standard za tabelarni zapis podataka.
                            Ovaj format ćete koristiti zelite izvesti sirove podatke o ovoj
                            stazi kako bi ih obradili u drugim alatima popout MS Excela.{' '}
                        </Typography>
                    </AccordionDetails>
                    <Divider />
                    <AccordionActions>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={savePathCoordinates}
                        >
                            Spasi koordninate staze
                        </Button>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={saveWaypoints}
                        >
                            Spasi tacke puta
                        </Button>
                    </AccordionActions>
                </Accordion>
            </div>
            <div className="margin-vertical" />
        </Modal>
    );
};

const mapStateToProps = (state) => {
    return {
        collection: getTrailCollectionForExport(state),
        richCollection: getTrailCollectionForExport(state, true),
    };
};

const mapDispatchToProps = () => {
    return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(ExportTrail2File);
