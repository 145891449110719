import React from 'react';
import { makeStyles } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { green, lime, grey } from '@material-ui/core/colors';
import LocationCityIcon from '@material-ui/icons/LocationCity';
import TerrainIcon from '@material-ui/icons/Terrain';
import Chip from '@material-ui/core/Chip';
import { getPlaceLink } from '../../helpers/commonHelper';

const useStyles = makeStyles({
    chip: {
        marginRight: 10,
        marginTop: 5,
        marginBottom: 5,
    },
});

const TrailPlaceObjects = (props) => {
    const classes = useStyles();
    const history = useHistory();
    const { placeObjects } = props.properties;
    return placeObjects.map((o) => {
        return (
            <Chip
                key={o.uuid}
                className={classes.chip}
                style={{
                    backgroundColor:
                        o.type === 'mountain' ? lime[600] : grey[300],
                }}
                icon={
                    o.type === 'mountain' ? (
                        <TerrainIcon style={{ color: green[600] }} />
                    ) : (
                        <LocationCityIcon />
                    )
                }
                label={o.name}
                onClick={() => {
                    history.push(getPlaceLink(o));
                }}
            />
        );
    });
};

export default TrailPlaceObjects;
