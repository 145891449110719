import { combineReducers } from 'redux';
import adminState from './adminReducer';
import presentationState from './presentationReducer';
import drawState from './drawReducer';

export default combineReducers({
    adminState,
    presentationState,
    drawState,
});
