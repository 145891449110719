import React from 'react';
import classNames from 'classnames';
import { collectionPublished } from '../../store/adminActions';
import {
    getActiveCollection4Publish,
    actionFlags,
} from '../../store/adminSelector';
import { connect } from 'react-redux';
import { UrlContstants } from '../../constants/constants';

function PublishActiveCollection(props) {
    const {
        payload4save,
        payload4publish,
        activeCollectionPublished,
        actionFlags,
    } = props;
    const onPublishCollection = (p2, status) => {
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'text/plain' },
            body: JSON.stringify({
                ...p2,
                status,
            }),
        };
        fetch(UrlContstants.publishCollection, requestOptions)
            .then((response) => response.json())
            .then((data) => {
                if (data.status) {
                    console.log(data);
                    activeCollectionPublished();
                } else {
                    console.warn(data);
                }
            })
            .catch((error) => {
                console.error('Error:', error);
            });
    };
    const onUploadCollection = (p1, p2, status = 2) => {
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'text/plain' },
            body: JSON.stringify(p1),
        };
        fetch(UrlContstants.uploadCollection, requestOptions)
            .then((response) => response.json())
            .then((data) => {
                if (data.status) {
                    console.log(data);
                    onPublishCollection(p2, status);
                } else {
                    console.warn(data);
                }
            })
            .catch((error) => {
                console.error('Error:', error);
            });
    };
    return (
        <>
            <button
                onClick={() => {
                    onUploadCollection(payload4save, payload4publish, 0);
                }}
                className={classNames({ inactive: !payload4save })}
            >
                {actionFlags.published ? 'Draft Saved' : 'Save Draft'}
            </button>
            <button
                onClick={() => {
                    onUploadCollection(payload4save, payload4publish);
                }}
                className={classNames({ inactive: !payload4save })}
            >
                {actionFlags.published ? 'Segment Saved' : 'Save Segment'}
            </button>
            <button
                onClick={() => {
                    onUploadCollection(payload4save, payload4publish, 1);
                }}
                className={classNames({ inactive: !payload4save })}
            >
                {actionFlags.published ? 'Trail Published' : 'Publish Trail'}
            </button>
        </>
    );
}

const mapStateToProps = (state) => {
    const {
        lines,
        waypoints,
        generalFacts,
        collection,
        uuid,
    } = getActiveCollection4Publish(state);
    return {
        payload4save: {
            lines,
            waypoints,
            generalFacts,
        },
        payload4publish: {
            collection,
            uuid,
        },
        actionFlags: actionFlags(state),
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        activeCollectionPublished: () => {
            dispatch(collectionPublished());
        },
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(PublishActiveCollection);
