import React from 'react';
import { useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import GridList from '@material-ui/core/GridList';
import GridListTile from '@material-ui/core/GridListTile';
import GridListTileBar from '@material-ui/core/GridListTileBar';
import IconButton from '@material-ui/core/IconButton';
import ZoomIn from '@material-ui/icons/ZoomIn';
import { getTrailLink } from '../../helpers/commonHelper';
import { setTemporaryTrailsSelection } from '../../store/presentationActions';
import { connect } from 'react-redux';
import { decodeWpGeoJSON2Line } from '../../helpers/geoSpatialHelper';
import {uiConstants} from "../../constants/constants";

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'space-around',
        overflow: 'hidden',
        backgroundColor: theme.palette.background.paper,
        // marginBottom: 16,
    },
    gridList: {
        width: '100%',
        height: 'auto',
    },
    icon: {
        color: 'rgba(255, 255, 255, 0.54)',
    },
    listItem: {
        cursor: 'pointer',
    },
    faded: {
        opacity: 0.2,
    }
}));

function AllTrailsListGrid(props) {
    const classes = useStyles();
    const history = useHistory();
    const { collection, setTemporaryTrailsSelection } = props;
    if (!collection || !collection.features) {
        return null;
    }
    const handleFocusFeatureOnMap = (wpGeoJSON, properties) => {
        const line2focus = decodeWpGeoJSON2Line(wpGeoJSON, properties);
        setTemporaryTrailsSelection([line2focus], true);
    };
    return (
        <div className={classes.root}>
            <GridList
                cellHeight={180}
                className={classes.gridList}
                cols={Math.floor(Math.min(window.innerWidth * 0.5, 800) / 250)}
            >
                {collection.features.map((feature, idx) => {
                    const {
                        trailUUID,
                        imageUrl,
                        name,
                        distance,
                        elevGain,
                        wpGeoJSON,
                        faded,
                    } = feature.properties;
                    // TODO: fix this below key template
                    return (
                        <GridListTile
                            key={`${trailUUID}.${idx}`}
                            className={faded ? classes.faded: classes.listItem}
                            onClick={() => {
                                if (faded) {
                                    setTemporaryTrailsSelection([], true);
                                    document.getElementById(uiConstants.sidebarContentID).scroll(0,0);
                                } else {
                                    history.push(getTrailLink(feature.properties));
                                }
                            }}
                        >
                            <img src={imageUrl} alt={name} />
                            <GridListTileBar
                                title={name}
                                subtitle={
                                    <span>
                                        {Math.round(distance)} km /{' '}
                                        {Math.round(elevGain)} m uspona
                                    </span>
                                }
                                actionIcon={
                                    <IconButton
                                        aria-label={`info about ${name}`}
                                        className={classes.icon}
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            if (!faded) {
                                                handleFocusFeatureOnMap(wpGeoJSON, feature.properties);
                                                document.getElementById(uiConstants.sidebarContentID).scroll(0,0);
                                            }
                                        }}
                                    >
                                        <ZoomIn />
                                    </IconButton>
                                }
                            />
                        </GridListTile>
                    );
                })}
            </GridList>
        </div>
    );
}

const mapStateToProps = () => {
    return {};
};

const mapDispatchToProps = (dispatch) => {
    return {
        setTemporaryTrailsSelection: (trails, mode) => dispatch(setTemporaryTrailsSelection(trails, mode)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(AllTrailsListGrid);
