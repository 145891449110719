export const getInProgressSegment = (state) => {
    if (!state.drawState.fetchStack.length) return null;
    const el = state.drawState.fetchStack.find((e) => !!e.start && !e.end);
    return el;
};

export const getSegments4Fetch = (state) => {
    return state.drawState.fetchStack.filter(
        (e) => !!e.start && !!e.end && !e.path,
    );
};

export const getLastCollection = (state) => {
    const last =
        state.drawState.collectionStack[
            state.drawState.collectionStack.length - 1
        ];
    return { ...last };
};
