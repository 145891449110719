import React from 'react';
import { connect } from 'react-redux';
import { setLayersVisibility } from '../../store/adminActions';
import { layerSets } from '../../constants/constants';

function LayersToggler(props) {
    const { setLayerSetVisibility } = props;
    const onToggleChange = (setId, value) => {
        console.log(setId);
        console.log(value);
        setLayerSetVisibility(setId, value);
    };
    return (
        <div className="layers-toggler">
            {
                layerSets.map((layerSet) => {
                    const { id, name } = layerSet;
                    return <div key={id} className="flex justify-space-between">
                        <label htmlFor={id}>{name}</label>
                        <input
                            type="checkbox"
                            id={id}
                            name={id}
                            value={id}
                            onClick={(e) => {
                                onToggleChange(id, e.target.checked);
                            }}
                        />
                    </div>;
                })
            }
        </div>
    );
}

const mapStateToProps = () => {
    return {};
};

const mapDispatchToProps = (dispatch) => {
    return {
        setLayerSetVisibility: (layerSet, visible) => {
            dispatch(setLayersVisibility(layerSet, visible));
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(LayersToggler);
