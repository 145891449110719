import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import SpaceBarIcon from '@material-ui/icons/SpaceBar';
import GradientIcon from '@material-ui/icons/Gradient';
import SignalCellular4BarIcon from '@material-ui/icons/SignalCellular4Bar';
import SignalCellular2BarIcon from '@material-ui/icons/SignalCellular2Bar';
import WarningIcon from '@material-ui/icons/Warning';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';

const useStyles = makeStyles((theme) => ({
    root: {
        flex: '1 1 50%',
    },
    listItem: {
        alignItems: 'flex-start',
    },
    listItemIcon: {
        marginTop: 8,
    },
    nestedListItem: {
        margin: 0,
        fontSize: theme.typography.pxToRem(14),
        paddingLeft: 0,
    },
}));

const SurfaceTypeProperties = (props) => {
    const classes = useStyles();
    const { trail } = props;

    return (
        <div className={classes.root}>
            <List component="nav" aria-label="main mailbox folders">
                <ListItem button className={classes.listItem}>
                    <ListItemIcon className={classes.listItemIcon}>
                        <SpaceBarIcon />
                    </ListItemIcon>
                    <ListItemText primary={trail.width} secondary="Širina" />
                </ListItem>
                <ListItem button className={classes.listItem}>
                    <ListItemIcon className={classes.listItemIcon}>
                        <GradientIcon />
                    </ListItemIcon>
                    <ListItemText primary={trail.surface} secondary="Podloga" />
                </ListItem>
                <ListItem button className={classes.listItem}>
                    <ListItemIcon className={classes.listItemIcon}>
                        <SignalCellular2BarIcon />
                    </ListItemIcon>
                    <ListItemText
                        primary={trail.avgGrade}
                        secondary="Prosječan nagib"
                    />
                </ListItem>
                <ListItem button className={classes.listItem}>
                    <ListItemIcon className={classes.listItemIcon}>
                        <SignalCellular4BarIcon />
                    </ListItemIcon>
                    <ListItemText
                        primary={trail.maxGrade}
                        secondary="Najveći nagib"
                    />
                </ListItem>
                <ListItem button className={classes.listItem}>
                    <ListItemIcon className={classes.listItemIcon}>
                        <WarningIcon />
                    </ListItemIcon>
                    <ListItemText
                        primary="Prepreke"
                        disableTypography={true}
                        secondary={
                            <List disablePadding>
                                {trail.obstacles.map((o, i) => (
                                    <ListItem
                                        key={i}
                                        className={classes.nestedListItem}
                                    >
                                        <Typography
                                            className={classes.nestedListItem}
                                        >
                                            {o}
                                        </Typography>
                                    </ListItem>
                                ))}
                            </List>
                        }
                    />
                </ListItem>
            </List>
        </div>
    );
};

export default SurfaceTypeProperties;
