/* global turf */
/* global polyline */
import { parseFeatureMetadata } from '../helpers/waypointHelper';
import {
    geoCodePoints,
    assignGeoPoints2Line,
    getRegionsAndMountains,
    minimizeCoordinates,
    minimizeCoordinate,
    decodeWpGeoJSON2Line,
    calculateBearing,
    encodeLine2wpGeoJSON,
    nivelateBearing,
} from '../helpers/geoSpatialHelper';
import { cleanUpSurfaceCollection, enrichLine } from '../helpers/trailHelper';
import {
    enrichPoint,
    generatePointsDescription,
    calculateIntermittentValues,
    generateIconMarkers,
    cleanUpSymbols,
} from '../helpers/waypointHelper';
import {
    last,
    first,
    containsWords,
    getUUID,
    slug,
    invertPictogram,
    roundDecimal,
    replaceString,
} from '../helpers/commonHelper';
import {
    config,
    defaultGeoFeatureProperties,
    processingParameters,
} from '../constants/constants';

export const getLines = (collection) => {
    if (!collection || !collection.features) {
        return [];
    }
    return collection.features.filter(
        (f) => f && f.geometry && f.geometry.type && f.geometry.type.indexOf('Line') === 0,
    );
};

export const getPoints = (collection) => {
    if (!collection || !collection.features) {
        return [];
    }
    return collection.features.filter((f) => f && f.geometry && f.geometry.type && f.geometry.type === 'Point');
};

export const getStatistics = (collection) => {
    const allLines = collection.features.filter(
        (f) => f.geometry.type.indexOf('Line') === 0,
    );
    const totalLines = allLines.length;
    const totalCoordinatesByLine = allLines.map(
        (l) => l.geometry.coordinates.length,
    );
    const totalPoints = collection.features.filter(
        (f) => f.geometry.type === 'Point',
    ).length;
    return {
        totalPoints,
        totalLines,
        totalCoordinatesByLine,
    };
};

export const parseCollection = (collection, state, isDrawn = false) => {
    const newCollection = { ...collection };
    const initialLines = getLines(newCollection);
    const initialPoints = isDrawn ? assignGeoPoints2Line(newCollection, state) : getPoints(newCollection);
    newCollection.features = [...initialLines, ...initialPoints].map((f) => {
        return {
            ...f,
            properties: parseFeatureMetadata(f),
        };
    });
    // Get regions and mountains
    const lines = getLines(newCollection);
    const points = getPoints(newCollection);
    const placeObjects = getRegionsAndMountains(newCollection, state);
    lines.forEach((line) => {
        line.properties = {
            ...defaultGeoFeatureProperties.LineString,
            ...line.properties,
        };
        if (!line.properties.placeObjects.length) {
            line.properties.placeObjects = placeObjects;
        }
        line.properties.imageCredits = getDefaultImageCredits(line);
        line.geometry.coordinates = minimizeCoordinates(
            line.geometry.coordinates,
        );
        // Find and substitute the first point
        const lineFirstPoint = turf.point(line.geometry.coordinates[0], {
            id: getUUID(),
            name: 'Polazna tačka',
            symbol: 'START',
            pictogram: '90',
        });
        const hasFirstPoint = points.some(
            (p) =>
                turf.distance(lineFirstPoint, p) <
                processingParameters.stickyPointsTreshold,
        );
        if (!hasFirstPoint) {
            points.unshift(lineFirstPoint);
        }
        // Find and substitute the last point
        const lineLastPoint = turf.point(
            line.geometry.coordinates[line.geometry.coordinates.length - 1],
            {
                id: getUUID(),
                name: 'Odredište',
                symbol: 'END',
                pictogram: '270',
            },
        );
        const hasLastPoint = points.some(
            (p) =>
                turf.distance(lineLastPoint, p) <
                processingParameters.stickyPointsTreshold,
        );
        if (!hasLastPoint) {
            points.push(lineLastPoint);
        }
    });
    points.forEach((point) => {
        point.properties.imageCredits = getDefaultImageCredits(point);
        if (isImageUserUploaded(point)) {
            if (point.properties.symbol === 'CROSSROAD') {
                point.properties.symbol = 'PHOTO';
            }
        }
        point.properties = {
            ...defaultGeoFeatureProperties.Point,
            ...point.properties,
        };
        point.geometry.coordinates = minimizeCoordinate(
            point.geometry.coordinates,
        );
        point.properties.bearing = calculateBearing(point, lines);
    });
    geoCodePoints(newCollection, state);
    return newCollection;
};

export const reParseCollection = (collection, state) => {
    const newCollection = { ...collection };
    // Initial parse
    newCollection.features = newCollection.features.map((f) => {
        if (f.properties.id) {
            return { ...f };
        } else {
            return {
                ...f,
                properties: parseFeatureMetadata(f),
            };
        }
    });
    // Get regions and mountains
    const placeObjects = getRegionsAndMountains(newCollection, state);
    const lines = getLines(newCollection);
    lines.forEach((line) => {
        if (
            !line.properties.placeObjects ||
            !line.properties.placeObjects.length
        ) {
            line.properties.placeObjects = placeObjects;
        }
        line.properties.imageCredits = getDefaultImageCredits(line);
        line.geometry.coordinates = minimizeCoordinates(
            line.geometry.coordinates,
        );
    });
    const points = getPoints(newCollection);
    points.forEach((point) => {
        point.geometry.coordinates = minimizeCoordinate(
            point.geometry.coordinates,
        );
        point.properties.imageCredits = getDefaultImageCredits(point);
        if (isImageUserUploaded(point)) {
            if (point.properties.symbol === 'CROSSROAD') {
                point.properties.symbol = 'PHOTO';
            }
        }
        point.properties.bearing = calculateBearing(point, lines);
    });
    // Geo code waypoints
    geoCodePoints(newCollection, state);
    return newCollection;
};

export const clientParseCollection = (collection, initialSetup) => {
    const newCollection = { ...collection };
    const points = getPoints(newCollection).sort(
        (a, b) => a.properties.odometer - b.properties.odometer,
    );
    calculateIntermittentValues(points);
    generatePointsDescription(points, initialSetup, newCollection);
    cleanUpSymbols(points);
    generateIconMarkers(points);
    getLines(newCollection).forEach((line) => {
        const cameraPath = turf.simplify(line, {
            tolerance: 0.003,
            highQuality: false,
        });
        line.properties.cameraPath = cameraPath;
        const { elevGain, elevLoss } = line.properties;
        const isAscend = elevGain > elevLoss;
        const linePointsCount = cameraPath.geometry.coordinates.length;
        const midIndex = Math.floor(linePointsCount / 2);
        const midPoint = turf.point(cameraPath.geometry.coordinates[midIndex]);
        const startPoint = turf.point(cameraPath.geometry.coordinates[0]);
        const endPoint = turf.point(cameraPath.geometry.coordinates[cameraPath.geometry.coordinates.length - 1]);
        const bearing = turf.bearing(isAscend ? startPoint : endPoint, midPoint);
        line.properties.initialBearing = nivelateBearing(bearing);
    });
    return newCollection;
};

export const isImageUserUploaded = (feature) => {
    const { imageUrl } = feature.properties;
    return imageUrl && imageUrl.indexOf('_user_upload_') > 1;
};

export const getDefaultImageCredits = (feature) => {
    const { properties } = feature;
    const { imageUrl, imageCredits } = properties;
    if (imageUrl && (!imageCredits || !imageCredits.length)) {
        if (imageUrl.indexOf('_generated_satellite_') > 1) {
            return config.defaultCredits;
        } else if (isImageUserUploaded(feature)) {
            return config.alternateCredits;
        }
    }
    if (!imageCredits) return '';
    return imageCredits;
};

export const appendCoordinate2Collection = (coordinate, state) => {
    const lastCollection =
        last(state.collectionStack) || turf.featureCollection([]);
    const existingCollection = JSON.parse(JSON.stringify(lastCollection));
    const existingLines = getLines(existingCollection);
    if (!existingLines.length) {
        return null;
    }
    let updated = false;
    existingLines.forEach((line) => {
        const { geometry } = line;
        let { coordinates } = geometry;
        const lastExistingCoordinate = last(coordinates);
        const distance2Last = turf.distance(
            turf.point(lastExistingCoordinate),
            turf.point(coordinate),
        );
        if (distance2Last < processingParameters.offRoadPointTreshold) {
            coordinates.push(coordinate);
            updated = true;
        } else {
            console.warn(
                `Clicked point too far (${distance2Last}) from existing trail`,
            );
        }
    });
    if (!updated) {
        return false;
    }
    return existingCollection;
};

export const parseDrawnPath2Collection = (path, state) => {
    if (!path['points_encoded']) {
        console.error('Points not encoded. Check out the log');
        console.log(path);
        return false;
    }
    const { points, instructions, distance } = path;
    const legDistanceKms = Math.round(distance / 10) / 100;
    let currentTotalDistance = 0;
    // console.log(
    //     `snapped_waypoints = ${JSON.stringify(
    //         polyline.decode(path['snapped_waypoints']),
    //     )}`,
    // );
    // Parse path into line
    const newSegmentCoordinates = polyline
        .decode(points)
        .map((c) => [c[1], c[0]]);
    const newLine = turf.lineString(newSegmentCoordinates, {
        isDraw: true,
        distance: legDistanceKms,
        surfaceCollection:
            defaultGeoFeatureProperties.LineString.surfaceCollection,
    });
    const firstNewSegmentCoordinate = first(newSegmentCoordinates);
    // Existing collection and line append
    const lastCollection =
        last(state.collectionStack) || turf.featureCollection([]);
    const existingCollection = JSON.parse(JSON.stringify(lastCollection));
    // Appending or adding lines
    const existingLines = getLines(existingCollection);
    if (!existingLines.length) {
        existingCollection.features.push(newLine);
    } else if (existingLines.length > 1) {
        console.error('More than 1 active line!!!');
    } else {
        existingLines.forEach((line) => {
            currentTotalDistance = turf.length(line);
            const { geometry } = line;
            let { coordinates } = geometry;
            const lastExistingCoordinate = last(coordinates);
            const distanceLast2First = turf.distance(
                turf.point(lastExistingCoordinate),
                turf.point(firstNewSegmentCoordinate),
            );
            if (
                distanceLast2First < processingParameters.stickyPointsTreshold
            ) {
                line.geometry.coordinates = [
                    ...coordinates,
                    ...newSegmentCoordinates,
                ];
                line.properties.distance += legDistanceKms;
            } else {
                // Unwanted situation....
                // existingCollection.features.push(newLine);
                console.warn(
                    'Distance bewtween old and new line too far, adding another line to collection',
                );
            }
        });
    }
    const trailLine = getLines(existingCollection)[0];
    let surface = trailLine.properties.surfaceCollection[trailLine.properties.surfaceCollection.length - 1].value;
    instructions
        .sort((a, b) => a.distance - b.distance)
        .forEach((instruction) => {
            const {
                coordinate,
                distance, // in meters
                name, // just name
                text, // Directions. Parse 'Nastavite' & 'Skrenite' with 'lijevo', 'desno' + 'oštro'
                annotations, // Surface transitions: paved, unpaved, small_way
                pushbike, // Slope and terrain indication
                speed, // Can indicate the surface & slope: 10 - flat paved, 2 - mild steep gravel, 16 - downhill
            } = instruction;
            const isTurn = containsWords(text, ['turn', 'skrenite']);
            const isKeep = containsWords(text, ['keep', 'nastavite']);
            const isLeft = containsWords(text, ['left', 'lijev']);
            const isRight = containsWords(text, ['right', 'desn']);
            const isHard = containsWords(text, ['hard', 'oštro']);
            const isEasy = containsWords(text, ['blago', 'easy']);
            const odometer =
                Math.round(
                    (currentTotalDistance +
                        Math.round((distance || 0) / 10) / 100) *
                        100,
                ) / 100;
            // Surface calculation
            let surfaceChange = false;
            let newSurface = 'A';
            if (annotations.some((e) => e === 'unpaved')) {
                newSurface = 'M';
            }
            if (annotations.some((e) => e === 'off_bike')) {
                newSurface = speed > 4 ? 'S' : 'P';
            }
            if (newSurface !== surface) {
                console.log(`#  ${surface} -> ${newSurface}`);
                surface = newSurface;
                surfaceChange = true;
                const existingSurfaceTransition = trailLine.properties.surfaceCollection.find(
                    (e) => e.key === roundDecimal(odometer),
                );
                if (existingSurfaceTransition) {
                    existingSurfaceTransition.value = surface;
                } else {
                    const lastSurface = trailLine.properties.surfaceCollection[trailLine.properties.surfaceCollection.length - 1];
                    if (
                        lastSurface.value !== surface &&
                        (roundDecimal(odometer) - lastSurface.key) > processingParameters.stickyPointsTreshold
                    ) {
                        trailLine.properties.surfaceCollection.push({
                            key: roundDecimal(odometer),
                            value: surface,
                        });
                    }
                }
            }
            // Pictogram parsing
            const pictogramDrawn = [];
            if (isTurn) {
                if (isHard) {
                    if (isLeft) {
                        pictogramDrawn.push(225);
                    }
                    if (isRight) {
                        pictogramDrawn.push(315);
                    }
                } else if (isEasy) {
                    if (isLeft) {
                        pictogramDrawn.push(135);
                    }
                    if (isRight) {
                        pictogramDrawn.push(45);
                    }
                } else {
                    if (isLeft) {
                        pictogramDrawn.push(180);
                    }
                    if (isRight) {
                        pictogramDrawn.push(0);
                    }
                }
            }
            if (isKeep) {
                if (isHard) {
                    if (isLeft) {
                        pictogramDrawn.push(225);
                        pictogramDrawn.push(90);
                    }
                    if (isRight) {
                        pictogramDrawn.push(315);
                        pictogramDrawn.push(90);
                    }
                } else if (isEasy) {
                    if (isLeft) {
                        pictogramDrawn.push(135);
                        pictogramDrawn.push(45);
                    }
                    if (isRight) {
                        pictogramDrawn.push(45);
                        pictogramDrawn.push(135);
                    }
                } else {
                    if (isLeft) {
                        pictogramDrawn.push(180);
                        pictogramDrawn.push(45);
                    }
                    if (isRight) {
                        pictogramDrawn.push(0);
                        pictogramDrawn.push(135);
                    }
                }
            }
            const newPoint = turf.point([coordinate[1], coordinate[0]], {
                name,
                nameEn: name,
                odometer,
                desc: text,
                isDraw: true,
                annotations,
                pushbike,
                speed,
                pictogramDrawn: pictogramDrawn.join('-'),
            });
            if (!surfaceChange) {
                existingCollection.features.push(newPoint);
            }
        });
    return existingCollection;
};

export const getAllCoordinates = (collection) => {
    let coordinates = [];
    const points = getPoints(collection);
    const lines = getLines(collection);
    points.forEach((p) => {
        coordinates.push(p.geometry.coordinates);
    });
    lines.forEach((l) => {
        coordinates = [...coordinates, ...l.geometry.coordinates];
    });
    return coordinates.map((c) => {
        return {
            lng: c[0],
            lat: c[1],
        };
    });
};

export const getEnrichedCollection = (collection) => {
    const lines = getLines(collection).map((line) => enrichLine(line));
    let points = getPoints(collection);
    let newCollection = {
        ...collection,
        features: [...lines, ...points],
    };
    points = points.map((point) => enrichPoint(point, newCollection));
    generateIconMarkers(points);
    newCollection = {
        ...newCollection,
        features: [...lines, ...points],
    };
    return {
        ...newCollection,
        features: [...lines, ...points],
    };
};

export const getInvertedCollection = (collection) => {
    const points = getPoints(collection).map((point) => {
        return {
            ...point,
            properties: {
                ...point.properties,
                id: getUUID(),
                elevGain: 0,
                elevLoss: 0,
                nextElevGain: 0,
                nextElevLoss: 0,
                odometer: 0,
                nextStepDist: 0,
                bearing: 0,
                time: 0,
                pictogram: invertPictogram(point.properties.pictogram),
                imageUrl: isImageUserUploaded(point) ? point.properties.imageUrl : null,
                wpGeoJSON: null,
            },
        };
    });
    const lines = getLines(collection).map((line) => {
        const { coordinates } = line.geometry;
        const newCoordinates = coordinates.reverse();
        // New properties
        const newUUUID = `${line.properties.trailUUID}_r`;
        const newName = line.properties.trailName
            .split('-')
            .map((word) => word.trimRight().trimLeft())
            .reverse()
            .join(' - ');
        // Surface collection
        let surfaceCollectionFull = [...line.properties.surfaceCollection];
        const lastSegment =
            surfaceCollectionFull[surfaceCollectionFull.length - 1];
        surfaceCollectionFull.push({
            ...lastSegment,
            key: roundDecimal(line.properties.distance),
        });
        surfaceCollectionFull = surfaceCollectionFull.sort(
            (a, b) => b.key - a.key,
        );
        let newSurfaceCollection = [];
        surfaceCollectionFull.forEach((segment, idx) => {
            const nextSegment = surfaceCollectionFull[idx + 1];
            if (nextSegment) {
                newSurfaceCollection.push({
                    key: roundDecimal(line.properties.distance - segment.key),
                    value: nextSegment.value,
                });
            }
        });
        const newProperties = {
            ...line.properties,
            trailUUID: newUUUID,
            id: newUUUID,
            trailName: newName,
            trailNameEn: newName,
            trailSlug: slug(newName),
            elevGain: line.properties.elevLoss,
            elevLoss: line.properties.elevGain,
            surfaceCollection: newSurfaceCollection,
            start: line.properties.end,
            end: line.properties.start,
        };
        return turf.lineString(newCoordinates, newProperties);
    });
    return {
        ...collection,
        features: [...lines, ...points],
    };
};

export const generateFeaturesNames = (collection) => {
    const points = getPoints(collection);
    points.forEach((point) => {
        const { suggestedObjects } = point.properties;
        if (suggestedObjects && suggestedObjects.length > 1) {
            point.properties.name = suggestedObjects[1].id; // Name has distance incorporated
        }
    });
    const startName = points[0].properties.name;
    const endName = points[points.length - 1].properties.name;
    const lines = getLines(collection);
    lines.forEach((line) => {
        line.properties.trailName = `${startName} - ${endName}`;
    });
    return {
        ...collection,
        features: [...lines, ...points],
    };
};

export const getHomeCollection = (trails, uuid) => {
    const features = [];
    trails.forEach((trail) => {
        // const trailHasThePlace = trail.placeObjects.some(
        //     (p) => p.uuid === uuid,
        // );
        // const isPlaceSearch = uuid && trailHasThePlace;
        // if (!uuid || isPlaceSearch) {
        //     if (trail.center && trail.center.length) {
        //         const newPoint = turf.point(trail.center, {
        //             ...trail,
        //             isHome: true,
        //         });
        //         features.push(newPoint);
        //     }
        // }
        if (trail.wpGeoJSON) {
            features.push(decodeWpGeoJSON2Line(trail.wpGeoJSON, trail));
        }
    });
    return turf.featureCollection(features);
};

export const getCollectionLinePreviewFeatures = (collections) => {
    const features = [];
    collections.forEach((collection) => {
        const lines = getLines(collection);
        if (lines.length) {
            lines.forEach((line) => {
                line.properties.isHome = true;
                features.push(line);
            });
        }
    });
    return turf.featureCollection(features);
};

export const filterAndSortCollection = (collection, sortRule, uuids = []) => {
    if (!collection) {
        return false;
    }
    let sortedSollection = {
        ...collection,
    };
    if (uuids && uuids.length) {
        const selectedFeatures = sortedSollection.features
            .filter((feature) => uuids.some((uuid) => uuid === feature.properties.trailUUID));
        const fadedFeatures = sortedSollection.features
            .filter((feature) => !uuids.some((uuid) => uuid === feature.properties.trailUUID))
            .map((feature) => {
                return {
                    ...feature,
                    properties: {
                        ...feature.properties,
                        faded: true,
                    },
                };
            });
        return {
            ...sortedSollection,
            features: [ ...selectedFeatures, ...fadedFeatures ],
        };
    }
    if (!sortRule) {
        return {
            ...sortedSollection,
            features: sortedSollection.features.filter(
                (e) => e.properties.name !== undefined,
            ),
        };
    }
    const { featureKey, ascending } = sortRule;
    return {
        ...sortedSollection,
        features: sortedSollection.features
            .filter((e) => e.properties[featureKey] !== undefined)
            .sort((a, b) => {
                let result = 0;
                if (a.properties[featureKey] > b.properties[featureKey]) {
                    result = 1;
                }
                if (a.properties[featureKey] < b.properties[featureKey]) {
                    result = -1;
                }
                if (!ascending) {
                    result = -1 * result;
                }
                return result;
            }),
    };
};

export const combineCollections = (collections) => {
    const newUuid = getUUID();
    const newLine = {
        type: 'Feature',
        properties: {
            ...defaultGeoFeatureProperties.LineString,
            trailUUID: newUuid,
            id: newUuid,
        },
        geometry: {
            type: 'LineString',
            coordinates: [],
        },
    };
    let newFeatures = [];
    const namesUnique = [];
    collections.forEach((collection, cIdx) => {
        // Points
        const points = getPoints(collection);
        points.forEach((point, pIdx) => {
            let shouldPointBeAdded = true;
            const nextPointExists = !!points[pIdx + 1];
            const nextCollectionExists = !!collections[cIdx + 1];
            const { pictogram } = point.properties;
            if (
                (nextPointExists || nextCollectionExists) &&
                pictogram.startsWith('270')
            ) {
                shouldPointBeAdded = false;
            }
            if (shouldPointBeAdded) {
                newFeatures.push({
                    ...point,
                    properties: {
                        ...point.properties,
                        elevGain:
                            newLine.properties.elevGain +
                            point.properties.elevGain,
                        elevLoss:
                            newLine.properties.elevLoss +
                            point.properties.elevLoss,
                        odometer:
                            newLine.properties.distance +
                            point.properties.odometer,
                    },
                });
            }
        });
        // Lines
        const lines = getLines(collection);
        lines.forEach((line) => {
            newLine.geometry.coordinates = [
                ...newLine.geometry.coordinates,
                ...line.geometry.coordinates,
            ];
            const {
                trailName,
                trailDesc,
                trailDescEn,
                typeID,
                surfaceCollection,
                distance,
                elevMin,
                elevMax,
                elevGain,
                elevLoss,
                reviewLandscape,
                reviewFun,
                requiredFitness,
                requiredTechnique,
                placeObjects,
            } = line.properties;
            // Name
            const names = trailName
                .split('-')
                .map((e) => e.trimRight().trimLeft());
            names.forEach((name) => {
                if (!namesUnique.some((nu) => nu === name)) {
                    namesUnique.push(name);
                }
            });
            // Description
            if (trailDesc && trailDesc.length) {
                newLine.properties.trailDesc += `\n*** ${trailDesc}`;
            }
            if (trailDescEn && trailDescEn.length) {
                newLine.properties.trailDescEn += `\n*** ${trailDescEn}`;
            }
            // TypeID
            newLine.properties.typeID = Math.max(
                newLine.properties.typeID,
                typeID,
            );
            // surfaceCollection - intentionaly before distance
            const newSurfaceCollection = surfaceCollection.map((change) => {
                const { key, value } = change;
                return {
                    key: roundDecimal(newLine.properties.distance + key),
                    value,
                };
            });
            if (newLine.properties.distance) {
                newLine.properties.surfaceCollection = [
                    ...newLine.properties.surfaceCollection,
                    ...newSurfaceCollection,
                ].sort((a, b) => a.key - b.key);
            } else {
                newLine.properties.surfaceCollection = [
                    ...newSurfaceCollection,
                ];
            }
            newLine.properties.surfaceCollection = cleanUpSurfaceCollection(
                newLine.properties.surfaceCollection,
            );
            // Distance
            newLine.properties.distance += distance;
            // elevMin
            newLine.properties.elevMin = Math.min(
                newLine.properties.elevMin,
                elevMin,
            );
            // elevMax
            newLine.properties.elevMax = Math.max(
                newLine.properties.elevMax,
                elevMax,
            );
            // elevGain
            newLine.properties.elevGain += elevGain;
            // elevLoss
            newLine.properties.elevLoss += elevLoss;
            // reviewLandscape
            newLine.properties.reviewLandscape = Math.max(
                newLine.properties.reviewLandscape,
                reviewLandscape,
            );
            // reviewLandscape
            newLine.properties.reviewFun = Math.max(
                newLine.properties.reviewFun,
                reviewFun,
            );
            // reviewLandscape
            newLine.properties.requiredFitness = Math.max(
                newLine.properties.requiredFitness,
                requiredFitness,
            );
            // reviewLandscape
            newLine.properties.requiredTechnique = Math.max(
                newLine.properties.requiredTechnique,
                requiredTechnique,
            );
            // placeObjects
            placeObjects.forEach((place) => {
                if (
                    !newLine.properties.placeObjects.some(
                        (p) => p.uuid === place.uuid,
                    )
                ) {
                    newLine.properties.placeObjects.push(place);
                }
            });
        });
        newLine.properties.trailName = namesUnique.join(' - ');
        newLine.properties.trailNameEn = namesUnique.join(' - ');
        newLine.properties.trailSlug = slug(newLine.properties.trailName);
        newLine.properties.wpGeoJSON = encodeLine2wpGeoJSON(newLine);
        newLine.properties.bounds = turf.bbox(
            turf.featureCollection([newLine]),
        );
        newLine.properties.center = turf.centerOfMass(
            newLine,
        ).geometry.coordinates;
    });
    newFeatures = [newLine, ...newFeatures];
    return turf.featureCollection(newFeatures);
};

export const getCollectionForExport = (collection) => {
    const features = collection.features.map((feature) => {
        const { properties, geometry } = feature;
        const { name, desc, trailName, trailDesc } = properties;
        let { coordinates, type } = geometry;
        if (type === 'Point') {
            if (coordinates.length > 2) {
                coordinates = [coordinates[0], coordinates[1]];
            }
        } else {
            coordinates = coordinates.map((coordinate) => {
                if (coordinate.length > 2) {
                    return [coordinate[0], coordinate[1]];
                }
                return coordinate;
            });
        }
        return {
            ...feature,
            properties: {
                name: name || trailName,
                desc: desc || trailDesc,
            },
            geometry: {
                type,
                coordinates,
            },
        };
    });
    return turf.featureCollection(features);
};

export const preparePayload4Publish = (collection) => {
    const lines = getLines(collection);
    const waypoints = getPoints(collection);
    if (
        !lines.length &&
        waypoints.length &&
        waypoints.length ===
            waypoints.filter((p) => isImageUserUploaded(p)).length
    ) {
        const generalFacts = waypoints[0].properties;
        generalFacts.trailUUID = config.photoWaypointsUUID;
        generalFacts.trailName = config.photoWaypointsUUID;
        generalFacts.trailSlug = config.photoWaypointsUUID;
        return {
            lines: [],
            waypoints: [],
            generalFacts: {
                ...defaultGeoFeatureProperties.LineString,
                ...generalFacts,
            },
            collection: {
                type: 'FeatureCollection',
                features: [...waypoints],
            },
            uuid: config.photoWaypointsUUID,
        };
    }
    if (
        !lines.length &&
        waypoints.length &&
        waypoints.length ===
        waypoints.filter((p) => p.properties.symbol === 'CROSSROAD').length
    ) {
        const generalFacts = waypoints[0].properties;
        generalFacts.trailUUID = config.crossroadsUUID;
        generalFacts.trailName = config.crossroadsUUID;
        generalFacts.trailSlug = config.crossroadsUUID;
        return {
            lines: [],
            waypoints: [],
            generalFacts: {
                ...defaultGeoFeatureProperties.LineString,
                ...generalFacts,
            },
            collection: {
                type: 'FeatureCollection',
                features: [...waypoints],
            },
            uuid: config.crossroadsUUID,
        };
    }
    if (!lines.length) {
        return false;
    }
    lines.forEach((line) => {
        if (line.properties.richLine) {
            delete line.properties.richLine;
            // Skip deleting wpGeoJSON in order to render simplified trails on home
            // delete line.properties.wpGeoJSON;
        }
        line.properties.published = true;
        const start = line.geometry.coordinates[0];
        const end =
            line.geometry.coordinates[line.geometry.coordinates.length - 1];
        line.properties.start = `POINT(${start[0]} ${start[1]})`;
        line.properties.end = `POINT(${end[0]} ${end[1]})`;
        line.properties.bounds = turf.bbox(collection);
        line.properties.center = turf.centerOfMass(line).geometry.coordinates;
        line.properties.imageCredits = getDefaultImageCredits(line);
    });
    waypoints.forEach((point) => {
        if (point.properties.wpGeoJSON) {
            delete point.properties.wpGeoJSON;
            delete point.properties.suggestedObjects;
            delete point.properties.parent.index;
            delete point.properties.parent.dist;
            delete point.properties.parent.location;
        }
        point.properties.published = true;
        point.properties.imageCredits = getDefaultImageCredits(point);
        if (point.properties.annotations) {
            delete point.properties.annotations;
        }
    });
    const generalFacts = lines[0].properties;
    return {
        lines,
        waypoints,
        generalFacts,
        collection: {
            type: 'FeatureCollection',
            features: [...lines, ...waypoints],
        },
        uuid: generalFacts.trailUUID,
    };
};

export const isPhotoCollection = (collection) => {
    const lines = getLines(collection);
    if (lines.length) return false;
    const points = getPoints(collection);
    if (!points.length) return false;
    const photoPoints = points.filter((f) => f.properties.symbol === 'PHOTO');
    return points.length === photoPoints.length;
};

export const isCrossroadCollection = (collection) => {
    const lines = getLines(collection);
    if (lines.length) return false;
    const points = getPoints(collection);
    if (!points.length) return false;
    const photoPoints = points.filter((f) => f.properties.symbol === 'CROSSROAD');
    return points.length === photoPoints.length;
};

export const collectionCleanUp4BatchUpdate = (collection, state) => {
    let { features } = collection;
    features.forEach((feature) => {
        let { properties } = feature;
        // 1. String replace
        const keys = [
            'trailName',
            'trailDesc',
            'trailNameEn',
            'trailDescEn',
            'name',
            'nameEn',
            'desc',
            'descEn',
        ];
        const swaps = [
            ['Viječnica', 'Vijećnica'],
            ['Vječnica', 'Vijećnica'],
            ['Ćolina ', 'Čolina '],
        ];
        keys.forEach((key) => {
            if (properties[key]) {
                swaps.forEach((swap) => {
                    properties[key] = replaceString(properties[key], swap);
                });
            }
        });
        // 2. Place objects
        if (properties.placeObjects !== undefined) {
            const placeObjects = getRegionsAndMountains(collection, state);
            properties.placeObjects = placeObjects;
        }
    });
    return turf.featureCollection(features);
};
