import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { getTokenSetup } from '../../store/adminSelector';
import { getNextMapAction, getTrailCollection } from '../../store/presentationSelector';
import { fetchTokenSetup, fetchInitialSetup } from '../../store/adminActions';
import { setMapLoaded } from '../../store/presentationActions';
import { initialiseTerrainPreviewMap } from '../../helpers/mapHelper';
import { executeMapAction } from '../../helpers/mapActions';
import {getCollectionBounds, getCollectionCenter} from "../../helpers/mapToolsHelper";

let mapInstance;

function TerrainPreviewMap(props) {
    const history = useHistory();
    let mapContainer = undefined;
    const { tokenSetup, mapAction, initSetup, setMapLoaded, collection, mapLoaded } = props;
    const defaultMapPosition = collection ? {
        bbox: getCollectionBounds(collection),
        center: getCollectionCenter(collection).geometry.coordinates,
    } : null;
    // Init token
    useEffect(() => {
        if (!tokenSetup) {
            initSetup();
        }
    }, [tokenSetup, initSetup]);
    // Init map
    useEffect(() => {
        if (tokenSetup && defaultMapPosition && !mapLoaded) {
            initialiseTerrainPreviewMap(
                mapContainer,
                mapInstance,
                tokenSetup,
                () => {
                    setMapLoaded();
                },
                defaultMapPosition,
            );
        }
    }, [tokenSetup, mapContainer, setMapLoaded, mapLoaded, history, defaultMapPosition]);
    // Action dependent
    useEffect(() => {
        if (mapLoaded && mapAction) {
            executeMapAction(mapAction);
        }
    }, [mapAction, mapLoaded]);
    return (
        <div
            className="map-container"
            id="client-map"
            ref={(el) => (mapContainer = el)}
        />
    );
}

const mapStateToProps = (state) => {
    return {
        mapLoaded: state.presentationState.mapLoaded,
        tokenSetup: getTokenSetup(state),
        mapAction: getNextMapAction(state),
        collection: getTrailCollection(state),
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        initSetup: () => {
            dispatch(fetchTokenSetup());
            dispatch(fetchInitialSetup());
        },
        setMapLoaded: () => dispatch(setMapLoaded()),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(TerrainPreviewMap);
