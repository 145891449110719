/* globals Highcharts */
import React, { useEffect } from 'react';
import { getChartSetup4Line } from '../../helpers/elevationChartHelper';

function ClientChartContainer(props) {
    const { id, collection } = props;
    useEffect(() => {
        const newSetup = getChartSetup4Line(collection, id, false);
        if (window.innerWidth < 720) {
            newSetup.chart.width = window.innerWidth - 32;
        }
        new Highcharts.Chart(id, newSetup);
    });
    return React.createElement('div', { id });
}

export default ClientChartContainer;
