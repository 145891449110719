import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

const SplashScreen = () => {
    const localFlag = window.localStorage.getItem('preventSplashScreen');
    const preventSplashScreen =
        localFlag && localFlag.length ? JSON.parse(localFlag) : false;
    const [opened, setOpened] = useState(!preventSplashScreen);

    const handleClose = () => {
        setOpened(false);
    };

    const handleCloseAndPrevent = () => {
        window.localStorage.setItem(
            'preventSplashScreen',
            JSON.stringify(true),
        );
        setOpened(false);
    };

    return (
        <Dialog
            open={opened}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">
                Važna napomena prije korištenja
            </DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    Namjeravate otvoriti i koristiti neoficijelnu verziju
                    aplikacije. Mapa minske situacije u BiH prikazana na mapama
                    je samo informativnog karaktera te ne odgovara u potpunosti
                    svarnom stanju na terenu. Molimo za oprez prilikom korištnja
                    posebno u realnim uslovima na terenu.
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button
                    onClick={handleClose}
                    color="primary"
                    variant="contained"
                >
                    Želim nastaviti
                </Button>
                <Button
                    onClick={handleCloseAndPrevent}
                    color="secondary"
                    variant="contained"
                >
                    Ne prikazuj više ovo upozorenje
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default SplashScreen;
