import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import { getLines } from '../../helpers/collectionHelper';
import ClientChartContainer from '../base/ClientChartContainer';

const useStyles = makeStyles({
    root: {
        width: '100%',
    },
    media: {
        height: '15vh',
    },
});

const TrailElevationCharts = (props) => {
    const { collection } = props;
    const lines = getLines(collection);
    const classes = useStyles();
    return lines.map((line) => {
        const { properties } = line;
        const { trailUUID } = properties;
        return (
            <Card key={trailUUID} className={classes.root}>
                <CardContent>
                    <ClientChartContainer
                        collection={collection}
                        id={trailUUID}
                    />
                </CardContent>
            </Card>
        );
    });
};

export default TrailElevationCharts;
