import React, { useEffect } from 'react';
import { getPois } from '../../store/adminSelector';
import { fetchPOIsource } from '../../store/adminActions';
import { connect } from 'react-redux';

import { FormattedMessage } from 'react-intl';

function PoisLoader(props) {
    const { pois, initPOIs } = props;
    // POIs load
    useEffect(() => {
        if (pois === null) {
            initPOIs();
        }
    }, [pois, initPOIs]);
    if (!pois) {
        return <FormattedMessage id="admin.loadingPois" />;
    }
    return (
        <>
            <a className="margin-horizontal--half" href="/admin/preview">
                Preview
            </a>{' '}
            |
            <a className="margin-horizontal--half" href="/admin/all/trails">
                All trails
            </a>{' '}
            |
            <a className="margin-horizontal--half" href="/admin/upload">
                Upload
            </a>{' '}
            |
            <a className="margin-horizontal--half" href="/admin/draw">
                Draw
            </a>{' '}
            |
            <a className="margin-horizontal--half" href="/admin/manual">
                Manual
            </a>{' '}
            |
            <a className="margin-horizontal--half" href="/admin/edit">
                Edit
            </a>{' '}
            |
            <a className="margin-horizontal--half" href="/admin/combine">
                Combine
            </a>{' '}
            |
            <a className="margin-horizontal--half" href="/admin/batch">
                Batch
            </a>
        </>
    );
}

const mapStateToProps = (state) => {
    return {
        pois: getPois(state),
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        initPOIs: () => {
            dispatch(fetchPOIsource('gradovi'));
            dispatch(fetchPOIsource('planine'));
            dispatch(fetchPOIsource('crossroads'));
            dispatch(fetchPOIsource('photo-waypoints'));
            dispatch(fetchPOIsource('pois'));
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(PoisLoader);
