import React from 'react';
import { useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import Link from '@material-ui/core/Link';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Divider from '@material-ui/core/Divider';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';
import { getTrailLink, getPlaceLink } from '../../helpers/commonHelper';
import { setTemporaryTrailsSelection } from "../../store/presentationActions";
import { connect } from "react-redux";
import {uiConstants} from "../../constants/constants";

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
    },
    listItem: {
        cursor: 'pointer',
    },
    faded: {
        opacity: 0.2,
    },
    listAvatar: {
        width: theme.spacing(7),
        height: theme.spacing(7),
        marginRight: theme.spacing(2),
    },
}));

function AllTrailsListList(props) {
    const classes = useStyles();
    const history = useHistory();
    const { collection, setTemporaryTrailsSelection } = props;
    if (!collection || !collection.features) {
        return null;
    }
    return (
        <div className={classes.root}>
            <List className={classes.root}>
                {collection.features.map((feature, idx) => {
                    const {
                        trailUUID,
                        imageUrl,
                        name,
                        distance,
                        elevGain,
                        placeObjects,
                        faded,
                    } = feature.properties;
                    return (
                        <div key={`${trailUUID}.${idx}`}>
                            {idx ? <Divider component="li" /> : null}
                            <ListItem
                                alignItems="flex-start"
                                className={faded ? classes.faded: classes.listItem}
                                onClick={() => {
                                    if (faded) {
                                        setTemporaryTrailsSelection([], true);
                                        document.getElementById(uiConstants.sidebarContentID).scroll(0,0);
                                    } else {
                                        history.push(getTrailLink(feature.properties));
                                    }
                                }}
                            >
                                <ListItemAvatar>
                                    <Avatar
                                        alt={name}
                                        src={imageUrl}
                                        variant="rounded"
                                        className={classes.listAvatar}
                                    />
                                </ListItemAvatar>
                                <ListItemText
                                    primary={name}
                                    secondary={
                                        <span>
                                            {Math.round(distance)} km /{' '}
                                            {Math.round(elevGain)} m
                                            uspona.&nbsp;&nbsp;
                                            {placeObjects.map((place, pIdx) => {
                                                return (
                                                    <span
                                                        key={`link2.${place.uuid}`}
                                                    >
                                                        {pIdx ? (
                                                            <span>, </span>
                                                        ) : null}
                                                        <Link
                                                            // href={getPlaceLink(
                                                            //     place,
                                                            // )}
                                                            // href="#"
                                                            onClick={(e) => {
                                                                e.stopPropagation();
                                                                if (!faded) {
                                                                    history.push(getPlaceLink(place));
                                                                }
                                                            }}
                                                        >
                                                            {place.name}
                                                        </Link>
                                                    </span>
                                                );
                                            })}
                                        </span>
                                    }
                                />
                            </ListItem>
                        </div>
                    );
                })}
            </List>
        </div>
    );
}

const mapStateToProps = () => {
    return {};
};

const mapDispatchToProps = (dispatch) => {
    return {
        setTemporaryTrailsSelection: (trails, mode) => dispatch(setTemporaryTrailsSelection(trails, mode)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(AllTrailsListList);
