import React, { useState } from 'react';
import { connect } from 'react-redux';
import {
    getAllTrailsList,
    purgeTrailByUUID,
    updateTrailProperty,
} from '../../store/adminActions';
import { getPlaceLink, getTrailLink } from '../../helpers/commonHelper';

const EditSingleTrail = (props) => {
    const { trail, purgeTrailByUUID, updateTrailProperty } = props;
    const [deleteEnabled, enableDelete] = useState(false);
    const {
        trailID,
        trailUUID,
        name,
        distance,
        elevGain,
        imageUrl,
        status,
        placeObjects,
    } = trail;
    return (
        <tr>
            <td>
                <img src={imageUrl} height={50} alt={name} />
            </td>
            <td>{trailID}</td>
            <td>
                <h3>{name}</h3>
                <a
                    href={getTrailLink(trail)}
                    className="x-small"
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    {trailUUID}
                </a>
            </td>
            <td>
                {parseInt(distance, 10)} km / {elevGain} m
            </td>
            <td>
                <button
                    onClick={() => {
                        enableDelete(!deleteEnabled);
                    }}
                >
                    Update
                </button>
            </td>
            <td className="x-small">
                <ul>
                    {placeObjects.map((o) => (
                        <li key={o.uuid}>
                            <a
                                href={getPlaceLink(o)}
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                {o.name}
                            </a>
                        </li>
                    ))}
                </ul>
            </td>
            <td>
                <select
                    name="status"
                    id="status"
                    value={status}
                    onChange={(e) => {
                        const newValue = e.target.value;
                        updateTrailProperty(
                            trailUUID,
                            'publish_status',
                            newValue,
                        );
                    }}
                >
                    <option value={0}>Draft</option>
                    <option value={1}>Published</option>
                    <option value={2}>Segment</option>
                </select>
            </td>
            <td>
                <button
                    onClick={() => {
                        enableDelete(!deleteEnabled);
                    }}
                >
                    Toggle
                </button>
                <br />
                <button
                    disabled={!deleteEnabled}
                    onClick={() => {
                        purgeTrailByUUID(trailUUID);
                    }}
                >
                    Delete
                </button>
            </td>
        </tr>
    );
};

const mapStateToProps = () => {
    return {};
};

const mapDispatchToProps = (dispatch) => {
    return {
        purgeTrailByUUID: (uuid) => dispatch(purgeTrailByUUID(uuid)),
        updateTrailProperty: (uuid, propName, propValue) => {
            dispatch(updateTrailProperty(uuid, propName, propValue));
            dispatch(getAllTrailsList());
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(EditSingleTrail);
