import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { connect } from 'react-redux';
import {
    getTrailGeoJson,
    showTrailGeoJsonOnMap,
} from '../store/presentationActions';
import { getTrailCollection } from '../store/presentationSelector';
import DataLoaderSpinner from '../components/base/DataLoaderSpinner';
import SingleTrailLayout from '../layouts/SingleTrailLayout';
import { UrlContstants } from '../constants/constants';

const SingleTrailDataLoader = (props) => {
    const params = useParams();
    const { slug } = params;
    const { collection, initCollection, mapLoaded, previewCollection, loadOnly } = props;
    const [uuid, setUUID] = useState(null);
    // Decode slug into uuid
    useEffect(() => {
        if (!uuid) {
            fetch(`${UrlContstants.decodeSlug}${slug}`)
                .then((response) => response.json())
                .then((data) => {
                    if (data.status) {
                        console.log(data);
                        if (data.result.type === 'trail') {
                            setUUID(data.result.uuid);
                        }
                    } else {
                        console.warn(data);
                    }
                })
                .catch((error) => {
                    console.error('Error:', error);
                });
        }
    }, [uuid, slug]);
    // Load trail
    useEffect(() => {
        if (uuid && !collection) {
            initCollection(uuid);
        }
    }, [collection, uuid, initCollection]);
    // Preview trail
    useEffect(() => {
        if (uuid && collection && mapLoaded) {
            previewCollection(uuid);
        }
    }, [collection, mapLoaded, uuid, previewCollection]);
    if (loadOnly) {
        return null;
    }
    if (collection && mapLoaded) {
        return <SingleTrailLayout collection={collection} />;
    }
    return <DataLoaderSpinner />;
};

const mapStateToProps = (state) => {
    return {
        collection: getTrailCollection(state),
        mapLoaded: state.presentationState.mapLoaded,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        initCollection: (uuid) => dispatch(getTrailGeoJson(uuid)),
        previewCollection: (uuid) => dispatch(showTrailGeoJsonOnMap(uuid)),
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(SingleTrailDataLoader);
