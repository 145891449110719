import React from 'react';
import { useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import MenuIcon from '@material-ui/icons/Menu';
import TerrainIcon from '@material-ui/icons/Terrain';
import MapIcon from '@material-ui/icons/Map';
import { is3dMap, getTrailPreviewLink, getTrailLink, slug } from '../../helpers/commonHelper';
import SocialNetworkShare from './SocialNetworkShare';
import { getTrailFacts } from '../../store/presentationSelector';
import { connect } from 'react-redux';
import ExportTrail2File from './ExportTrail2File';

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    button: {
        marginRight: theme.spacing(2),
    },
    title: {
        flexGrow: 1,
    },
    grow: {
        position: 'sticky',
    },
}));

const HeaderToolbar = (props) => {
    const { properties } = props;
    const history = useHistory();
    const classes = useStyles();
    return (
        <div className={classes.grow}>
            <AppBar position="static">
                <Toolbar>
                    <IconButton
                        edge="start"
                        className={classes.button}
                        color="inherit"
                        aria-label="menu"
                    >
                        <MenuIcon />
                    </IconButton>
                    <Typography
                        variant="h6"
                        className={classes.title}
                    >
                        <span> </span>
                    </Typography>
                    {properties && !is3dMap() && <IconButton
                        className={classes.button}
                        color="inherit"
                        aria-label="3d terrain preview"
                        onClick={() =>
                            window.open(getTrailPreviewLink(properties), '_blank')
                        }
                    >
                        <TerrainIcon />
                    </IconButton>}
                    {properties && is3dMap() && <IconButton
                        className={classes.button}
                        color="inherit"
                        aria-label="Map preview"
                        onClick={() =>
                            history.push(getTrailLink(properties))
                        }
                    >
                        <MapIcon />
                    </IconButton>}
                    {properties && (
                        <ExportTrail2File
                            fileName={slug(properties.trailName)}
                        />
                    )}
                    <div className="margin-horizontal--half" />
                    <SocialNetworkShare />
                </Toolbar>
            </AppBar>
        </div>
    );
};

const mapStateToProps = (state) => {
    return {
        properties: getTrailFacts(state),
    };
};

const mapDispatchToProps = () => {
    return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(HeaderToolbar);
