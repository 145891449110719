import React from 'react';
import { SvgIcon } from '@material-ui/core';

function SurfaceIcon(props) {
    const { color, dasharray } = props.style;
    const isDash = !!dasharray[1];
    if (isDash) {
        return (
            <SvgIcon viewBox="0 0 32 32">
                <line
                    id="full-outline"
                    x1="1"
                    x2="31"
                    y1="16"
                    y2="16"
                    stroke="#000"
                    strokeWidth="8"
                />
                <line
                    id="full-line"
                    x1="1"
                    x2="31"
                    y1="16"
                    y2="16"
                    stroke="#FFF"
                    strokeWidth="6"
                />
                <line
                    id="dash-line_1"
                    x1="1"
                    x2="8"
                    y1="16"
                    y2="16"
                    stroke={color}
                    strokeWidth="6"
                />
                <line
                    id="dash-line_1"
                    x1="12"
                    x2="20"
                    y1="16"
                    y2="16"
                    stroke={color}
                    strokeWidth="6"
                />
                <line
                    id="dash-line_1"
                    x1="24"
                    x2="31"
                    y1="16"
                    y2="16"
                    stroke={color}
                    strokeWidth="6"
                />
            </SvgIcon>
        );
    }
    return (
        <SvgIcon viewBox="0 0 32 32">
            <line
                id="full-outline"
                x1="1"
                x2="31"
                y1="16"
                y2="16"
                stroke="#000"
                strokeWidth="8"
            />
            <line
                id="full-line"
                x1="1"
                x2="31"
                y1="16"
                y2="16"
                stroke={color}
                strokeWidth="6"
            />
        </SvgIcon>
    );
}

export default SurfaceIcon;
