import { createStore, compose, applyMiddleware } from 'redux';
import rootReducer from './rootReducer';
import thunk from 'redux-thunk';

const logger = ({ getState }) => {
    return (next) => (action) => {
        console.log('will dispatch', action);
        // Call the next dispatch method in the middleware chain.
        const returnValue = next(action);
        console.log('state after dispatch', getState());
        // This will likely be the action itself, unless
        // a middleware further in chain changed it.
        return returnValue;
    };
};

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

/* eslint-disable no-underscore-dangle */
const store = createStore(
    rootReducer,
    /* preloadedState, */ composeEnhancers(applyMiddleware(thunk, logger)),
);
/* eslint-enable */

export default store;
