import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import {
    getHomeTrails,
    showHomeGeoJsonOnMap,
} from '../store/presentationActions';
import { getHomeCollection } from '../store/presentationSelector';
import DataLoaderSpinner from '../components/base/DataLoaderSpinner';
import AllTrailsLayout from '../layouts/AllTrailsLayout';

const AllTrailsDataLoader = (props) => {
    const query = new URLSearchParams(window.location.search);
    const segments = query.get('segments');
    const {
        collection,
        initCollection,
        previewCollection,
        includeSegments,
        mapLoaded,
    } = props;
    useEffect(() => {
        if (!collection) {
            initCollection(includeSegments || segments);
        }
    }, [collection, initCollection, includeSegments, segments]);
    useEffect(() => {
        if (mapLoaded) {
            previewCollection();
        }
    }, [mapLoaded, previewCollection]);
    if (collection && mapLoaded) {
        return <AllTrailsLayout collection={collection} />;
    }
    return <DataLoaderSpinner />;
};

const mapStateToProps = (state) => {
    return {
        mapLoaded: state.presentationState.mapLoaded,
        collection: getHomeCollection(state),
        listLayout: state.presentationState.uiStates.trailListLayout,
        sortRule: state.presentationState.uiStates.trailListSortRule,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        initCollection: (includeSegments) => dispatch(getHomeTrails(includeSegments)),
        previewCollection: () => dispatch(showHomeGeoJsonOnMap()),
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(AllTrailsDataLoader);
