import React from 'react';
import Map from '../components/map/AdminMap';
import GeoFileUpload from '../components/admin/GeoFileUpload';
import PreviewActiveCollectionOnMap from '../components/admin/PreviewActiveCollectionOnMap';
import CollectionFeaturesPreview from '../components/admin/CollectionFeaturesPreview';
import EditCollection from '../components/admin/EditCollection';
import PublishActiveCollection from '../components/admin/PublishActiveCollection';
import GeoFileDownload from '../components/admin/GeoFileDownload';
import CollectionsCombiner from '../components/admin/CollectionsCombiner';
import EditDrawCollection from '../components/admin/EditDrawCollection';
import ManuallyEditCollectionOnMap from '../components/admin/ManuallyEditCollectionOnMap';
import BatchUpdater from '../components/admin/BatchUpdater';
import PoisLoader from '../components/admin/PoisLoader';
import LayersToggler from '../components/admin/LayersToggler';

function AdminLayout(props) {
    const { canDraw, combine, edit, upload, manual, batch } = props;
    return (
        <>
            <div className="admin">
                {!batch && <div className="admin__map">
                    <Map canDraw={!!canDraw} />
                    <LayersToggler/>
                </div>}
                <div className="admin__sidebar flex column">
                    <div className="sidebar__row">
                        <PoisLoader />
                    </div>
                    {combine && (
                        <div className="sidebar__row">
                            <CollectionsCombiner />
                        </div>
                    )}
                    {edit && (
                        <div className="sidebar__row">
                            <GeoFileDownload />
                        </div>
                    )}
                    {upload && (
                        <div className="sidebar__row">
                            <GeoFileUpload />
                        </div>
                    )}
                    {canDraw && (
                        <div className="sidebar__row">
                            <EditDrawCollection />
                        </div>
                    )}
                    {manual && (
                        <div className="sidebar__row">
                            <ManuallyEditCollectionOnMap />
                        </div>
                    )}
                    {batch ? (
                        <div className="sidebar__row">
                            <BatchUpdater />
                        </div>
                    ) : (
                        <>
                            <div className="sidebar__row">
                                <PreviewActiveCollectionOnMap />
                            </div>
                            <div className="sidebar__row">
                                <EditCollection />
                            </div>
                            <div className="sidebar__row">
                                <CollectionFeaturesPreview />
                            </div>
                            <div className="sidebar__row">
                                <PublishActiveCollection />
                            </div>
                        </>
                    )}
                </div>
            </div>
        </>
    );
}

export default AdminLayout;
