import React, { useState } from 'react';
import { surfaceTypes } from '../../constants/constants';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import SurfaceTypesDescriptions from '../client/SurfaceTypesDescriptions';
import SurfaceIcon from '../client/SurfaceIcon';

function Legend() {
    const [opened, setOpened] = useState(false);
    if (!opened) {
        return (
            <Button
                variant="contained"
                color="secondary"
                aria-label="expand legend"
                className="legend__popup-icon"
                onClick={() => {
                    setOpened(true);
                }}
                endIcon={<ExpandLessIcon />}
            >
                Legenda
            </Button>
        );
    }

    return (
        <div className="legend__container">
            <div className="legend__header">
                <span className="legend__title">Legenda</span>
                <IconButton
                    onClick={() => {
                        setOpened(false);
                    }}
                    className="legend__close"
                    size="small"
                >
                    <ExpandMoreIcon />
                </IconButton>
            </div>
            <div className="legend__content">
                {surfaceTypes.map((surface) => {
                    const { id, name, style } = surface;
                    return (
                        <div className="legend__item" key={`surface-${id}`}>
                            <div className="legend__item-icon">
                                <SurfaceIcon style={style} />
                            </div>
                            <div className="legend__item-label">{name}</div>
                        </div>
                    );
                })}
                <div className="legend__item">
                    <div className="legend__item-icon-mines" />
                    <div className="legend__item-label">
                        Potencijalna minska polja
                    </div>
                </div>
                <div className="margin-vertical--quarter" />
                <div className="legend__item">
                    <SurfaceTypesDescriptions />
                </div>
            </div>
        </div>
    );
}

export default Legend;
