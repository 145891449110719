import React from 'react';
import Modal from './Modal';
import { makeStyles } from '@material-ui/core/styles';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { surfaceTypes } from '../../constants/constants';
import PowerInputIcon from '@material-ui/icons/PowerInput';
import SurfaceIcon from './SurfaceIcon';
import SurfaceTypeProperties from './SurfaceTypeProperties';
import SurfaceTypeExamples from './SurfaceTypeExamples';

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
    },
    heading: {
        fontSize: theme.typography.pxToRem(15),
        fontWeight: theme.typography.fontWeightRegular,
        marginLeft: 16,
    },
    listItem: {
        alignItems: 'flex-start',
    },
    listItemIcon: {
        marginTop: 8,
    },
    nestedListItem: {
        margin: 0,
        fontSize: theme.typography.pxToRem(14),
    },
}));

const SurfaceTypesDescriptions = () => {
    const classes = useStyles();
    const [expanded, setExpanded] = React.useState(false);

    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };

    return (
        <Modal
            buttonLabel="Detaljniji opis"
            buttonIcon={<PowerInputIcon />}
            buttonVariant="outlined"
            modalTitle="Detljniji opis tipova podloge"
            maxWidth="md"
        >
            <div className="margin-vertical" />
            <div className={classes.root}>
                <Typography>
                    U cilju ispravnog tumačenja tipova staza i podloga na mapi i
                    visinskom profilu, pogledajte okvirnu specifikaciju za svaki
                    tip standardiziranu po pravilima Međunarodne
                    Brdsko-biciklističke Asocijacije (
                    <a
                        href="https://www.imba.com/project/guidelines-quality-trail-experience"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        IMBA
                    </a>
                    )
                </Typography>
                <div className="margin-vertical" />
                {surfaceTypes.map((surface) => {
                    const { id, name, style, trail } = surface;
                    return (
                        <Accordion
                            key={id}
                            expanded={expanded === id}
                            onChange={handleChange(id)}
                        >
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                            >
                                <SurfaceIcon style={style} />
                                <Typography className={classes.heading}>
                                    {name}
                                </Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <div className="flex row mobile-column">
                                    <SurfaceTypeExamples id={id} />
                                    <div className="margin-horizontal--half" />
                                    <SurfaceTypeProperties trail={trail} />
                                </div>
                            </AccordionDetails>
                        </Accordion>
                    );
                })}
            </div>
            <div className="margin-vertical" />
        </Modal>
    );
};

export default SurfaceTypesDescriptions;
