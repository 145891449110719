/* global turf */
import { AppEvent } from '../constants/events';
// import { getCollectionBounds } from '../helpers/mapToolsHelper';
import {
    parseDrawnPath2Collection,
    appendCoordinate2Collection,
} from '../helpers/collectionHelper';
import { last } from '../helpers/commonHelper';
const initialState = {
    fetchStack: [],
    mapActionStack: [],
    collectionStack: [turf.featureCollection([])],
};

export default function drawState(state = initialState, action) {
    switch (action.type) {
        case AppEvent.draw.ADD_COORDINATE_2_STACK: {
            const newState = { ...state };
            const { coordinate, onRoad } = action;
            const inProgressSegment = newState.fetchStack.find(
                (e) => !!e.start && !e.end,
            );
            if (inProgressSegment) {
                inProgressSegment.end = coordinate;
                inProgressSegment.onRoadEnd = onRoad;
                newState.fetchStack.push({
                    start: coordinate,
                    onRoadStart: onRoad,
                });
                return newState;
            }
            newState.fetchStack.push({
                start: coordinate,
                onRoadStart: onRoad,
            });
            return newState;
        }
        case AppEvent.draw.ADD_PATH_2_STACK: {
            const newState = { ...state };
            const { path } = action;
            const fetchedSegment = newState.fetchStack.find(
                (e) => !!e.start && !!e.end && !e.path,
            );
            if (fetchedSegment) {
                fetchedSegment.path = path;
            }
            return newState;
        }
        case AppEvent.draw.PARSE_DRAWN_PATH: {
            const newState = { ...state };
            const { path } = action;
            const collection = parseDrawnPath2Collection(path, newState);
            if (collection) {
                newState.collectionStack.push(collection);
            }
            return newState;
        }
        case AppEvent.draw.APPEND_COORDINATE_2_TRAIL: {
            const newState = { ...state };
            const { coordinate } = action;
            const collection = appendCoordinate2Collection(
                coordinate,
                newState,
            );
            if (collection) {
                newState.collectionStack.push(collection);
            } else {
                const { fetchStack } = newState;
                fetchStack.pop();
                fetchStack[fetchStack.length - 1].path = undefined;
                fetchStack[fetchStack.length - 1].end = undefined;
                fetchStack[fetchStack.length - 1].onRoadEnd = undefined;
            }
            return newState;
        }
        case AppEvent.draw.PREVIEW_DRAWN_COLLECTION: {
            const newState = { ...state };
            const { collectionStack } = newState;
            const collection = last(collectionStack);
            const newAction = {
                collection,
                drawRoute: true,
            };
            // if (collection.features.length === 1) {
            //     newAction.bounds = getCollectionBounds(collection);
            // }
            // window.localStorage.setItem('testCollection', JSON.stringify(collection));
            newState.mapActionStack.push(newAction);
            return newState;
        }
        case AppEvent.draw.CLEAR_LAST_SEGMENT: {
            const newState = { ...state };
            const { collectionStack, fetchStack } = newState;
            fetchStack.pop();
            fetchStack[fetchStack.length - 1].path = undefined;
            fetchStack[fetchStack.length - 1].end = undefined;
            collectionStack.pop();
            return newState;
        }
        case AppEvent.draw.CLEAR_ALL_SEGMENTS: {
            const newState = {
                ...state,
                fetchStack: [],
                collectionStack: [turf.featureCollection([])],
            };
            newState.mapActionStack.push({
                collection: turf.featureCollection([]),
                drawRoute: true,
            });
            return newState;
        }
        default:
            return state;
    }
}
