import { getUUID, containsWords } from '../helpers/commonHelper';
import { defaultGeoFeatureProperties, config } from '../constants/constants';
import { parseLineFeatureMetadata } from '../helpers/trailHelper';
import {
    getDefaultImageCredits,
    getLines,
    isImageUserUploaded,
} from './collectionHelper';
import { surfaceTypes } from '../constants/constants';

export const parseFeatureMetadata = (feature) => {
    switch (true) {
        case feature.geometry.type.indexOf('Line') === 0:
            return {
                ...parseLineFeatureMetadata(feature),
            };
        case feature.geometry.type === 'Point':
            return {
                ...parsePointFeatureMetadata(feature),
            };
        default:
            return feature.properties;
    }
};

export const parsePointFeatureMetadata = (feature) => {
    // The sign of new trail
    const metadata = feature.properties;
    if (!metadata.iconMarker) {
        metadata.iconMarker = defaultGeoFeatureProperties.Point.iconMarker;
    }
    if (metadata.pictogram || metadata.published) {
        return metadata;
    }
    let tempName = metadata.name ? metadata.name : config.defaultWpName;
    if (tempName.substr(0, 13).toLocaleLowerCase() === 'skretanje za ') {
        tempName = tempName.substr(10);
    }
    metadata.name = tempName;
    metadata.pictogram = metadata.pictogramDrawn;
    let tempDescArray = '',
        tempDesc = '',
        tempPictogram = '';
    if (metadata.desc !== undefined && metadata.desc.indexOf('#') > -1) {
        tempDescArray = metadata.desc
            .replace('#\n\n', '#\n')
            .replace('#\n\n', '#\n')
            .replace('#\n', '#')
            .replace('#\n', '#')
            .split('#');
        tempDesc = tempDescArray[2];
        tempPictogram = tempDescArray[1];
        delete metadata.desc;
    } else if (metadata.cmt !== undefined && metadata.cmt.indexOf('#') > -1) {
        tempDescArray = metadata.cmt
            .replace('#\n\n', '#\n')
            .replace('#\n\n', '#\n')
            .replace('#\n', '#')
            .replace('#\n', '#')
            .split('#');
        tempDesc = tempDescArray[2];
        tempPictogram = tempDescArray[1];
        delete metadata.cmt;
    } else if (metadata.desc !== undefined) {
        tempDesc = metadata.desc;
        tempPictogram =
            metadata.pictogram !== undefined ? metadata.pictogram : '90';
    } else {
        tempDesc = '';
        tempPictogram =
            metadata.pictogram !== undefined ? metadata.pictogram : '90';
    }
    metadata.imageCredits = getDefaultImageCredits(feature);
    metadata.desc = tempDesc;
    metadata.id = getUUID();
    metadata.desc = tempDesc;
    metadata.pictogram = tempPictogram;
    if (isImageUserUploaded(feature)) {
        metadata.symbol = 'PHOTO';
    } else {
        metadata.symbol = metadata.symbol || 'CROSSROAD';
    }
    return {
        ...defaultGeoFeatureProperties.Point,
        ...metadata,
    };
};

export const enrichPoint = (point, collection) => {
    if (!point.properties.parent) {
        return { ...point };
    }
    const { id, index } = point.properties.parent;
    const lines = getLines(collection);
    const line = lines.find((l) => l.properties.id === id);
    if (!line) {
        return { ...point };
    }
    const { richLine } = line.properties;
    const { remain, elevGain, elevLoss } = richLine[index];
    return {
        ...point,
        properties: {
            ...point.properties,
            elevGain,
            elevLoss,
            remain,
        },
    };
};

export const wpIsPlace = (point) => {
    const { symbol } = point.properties;
    if (!symbol) {
        return false;
    }
    return symbol === 'CITY' || symbol === 'PLACE' || symbol === 'VILLAGE';
};

export const wpIsOrienteering = (point) => {
    const { symbol } = point.properties;
    if (!symbol) {
        return false;
    }
    return (
        symbol === 'CROSSROAD' ||
        symbol === 'PASS' ||
        symbol === 'RIVER' ||
        symbol === 'SUMMIT' ||
        symbol === 'START' ||
        symbol === 'END'
    );
};

export const wpIsAmenities = (point) => {
    const { symbol } = point.properties;
    if (!symbol) {
        return false;
    }
    return (
        symbol === 'FOOD' ||
        symbol === 'SLEEP' ||
        symbol === 'PHOTO' ||
        symbol === 'WATER'
    );
};

export const wpIsWarning = (point) => {
    const { symbol } = point.properties;
    if (!symbol) {
        return false;
    }
    return symbol === 'DANGER' || symbol === 'MINES';
};

export const wpIsNamedPlace = (point) => {
    const { symbol } = point.properties;
    if (!symbol) {
        return false;
    }
    return (
        wpIsPlace(point) || (symbol !== 'CROSSROAD' && wpIsOrienteering(point))
    );
};

const parseDirection = (angle) => {
    let retVar = 'dalje';
    if (isNaN(angle)) {
        return retVar;
    }
    const directions = [
        {
            from: 0,
            to: 20,
            desc: 'desno',
        },
        {
            from: 20,
            to: 70,
            desc: 'polu-desno',
        },
        {
            from: 70,
            to: 110,
            desc: 'pravo',
        },
        {
            from: 110,
            to: 160,
            desc: 'polu-lijevo',
        },
        {
            from: 160,
            to: 200,
            desc: 'lijevo',
        },
        {
            from: 200,
            to: 250,
            desc: 'oštro/natrag lijevo',
        },
        {
            from: 270,
            to: 270,
            desc: 'natrag istim putem',
        },
        {
            from: 250,
            to: 290,
            desc: 'natrag',
        },
        {
            from: 290,
            to: 340,
            desc: 'oštro/natrag desno',
        },
        {
            from: 340,
            to: 360,
            desc: 'desno',
        },
    ];
    directions.forEach((direction) => {
        if (
            parseInt(angle, 10) >= direction.from &&
            parseInt(angle, 10) <= direction.to
        ) {
            retVar = direction.desc;
        }
    });
    return retVar;
};

export const generatePointsDescription = (
    points,
    initialSetup,
    newCollection,
    index = 0,
) => {
    const current = points[index];
    current.properties.desc = '';
    const next = points[index + 1];
    if (!next) {
        current.properties.desc += 'Stigli ste na odrediste.';
        return;
    }
    const {
        symbol,
        name,
        pictogram,
        nextStepDist,
        nextElevGain,
        nextElevLoss,
        odometer,
        parent,
    } = current.properties;
    const line = getLines(newCollection).find(
        (l) => l.properties.id === parent.id,
    );
    const { surfaceCollection } = line.properties;
    // 1.   Type & Name
    const type = initialSetup.pointTypes.find((t) => t.id === symbol);
    if (type) {
        if (wpIsNamedPlace(current)) {
            current.properties.desc += `${type.name} ${name}. `;
        } else {
            current.properties.desc += `${type.name} "${name}". `;
        }
    }
    // 2.   Directions - parsed from pictogram
    const directions = pictogram.split('-');
    const directionOptions = [
        { code: null, label: 'Sporedni putevi' },
        { code: 'v', label: 'Izvor pitke vode' },
        { code: 'z', label: 'Zabranjen prolaz dalje' },
        { code: 'n', label: 'Naselje' },
        { code: 'm', label: 'Most' },
        { code: 'r', label: 'Rijeka/potok' },
        { code: 'o', label: 'Odmoriste/izlet' },
        { code: 'h', label: 'Hrana/restoran' },
        { code: 'p', label: 'Planinarski dom/skloniste' },
        { code: 'x', label: 'Planinski prevoj' },
        { code: 't', label: 'Tunel' },
        { code: 'l', label: 'Livada' },
        { code: 's', label: 'Suma' },
        { code: 'k', label: 'Kamenjar' },
        { code: 'g', label: 'Grad, urbana sredina' },
    ];

    const nextDirection = directions.shift();

    current.properties.desc += `Nastaviti ${parseDirection(
        nextDirection,
    )} drzeci se glavnog puta. `;

    directionOptions.forEach((option) => {
        const { code, label } = option;
        if (code) {
            const optionDirections = directions.filter((d) =>
                containsWords(d, [code]),
            );
            if (optionDirections.length) {
                current.properties.desc += `${label} ${optionDirections
                    .map((d) => parseDirection(d.replace(code, '')))
                    .join(', ')}. `;
            }
        } else {
            const optionDirections = directions.filter(
                (d) => parseInt(d, 10) !== undefined,
            );
            if (optionDirections.length) {
                current.properties.desc += `${label}: ${optionDirections
                    .filter((d) => !isNaN(d))
                    .map((d) => parseDirection(d.replace(code, '')))
                    .join(', ')}. `;
            }
        }
    });
    // 3.   Next section: distance, elevatin, surface change
    current.properties.desc += `Slijedi sekcija duzine ${nextStepDist} km`;
    if (nextElevGain > 0) {
        current.properties.desc += ` sa ${nextElevGain} m visinskog uspona`;
    }
    if (Math.abs(nextElevLoss) > 0) {
        current.properties.desc += ` i ${Math.abs(
            nextElevLoss,
        )} m visinskog spusta`;
    }
    current.properties.desc += `${parseSurfaceTransition(
        odometer,
        odometer + nextStepDist,
        surfaceCollection,
    )}.`;
    // 4.   Name the next point.
    current.properties.desc += ` Sljedeca kontrolna tacka (${
        next.properties.name
    }) na ${next.properties.odometer.toFixed(2)} km od pocetka.`;
    generatePointsDescription(points, initialSetup, newCollection, index + 1);
};

export const calculateIntermittentValues = (points, index = 0) => {
    const current = points[index];
    current.properties.odometer = Math.round(current.properties.odometer * 100) / 100;
    const next = points[index + 1];
    if (!next) {
        return;
    }
    current.properties.nextStepDist =
        Math.round(
            (next.properties.odometer - current.properties.odometer) * 100,
        ) / 100;
    current.properties.nextElevGain = parseInt(
        next.properties.elevGain - current.properties.elevGain,
        10,
    );
    current.properties.nextElevLoss = parseInt(
        next.properties.elevLoss - current.properties.elevLoss,
        10,
    );
    calculateIntermittentValues(points, index + 1);
};

const parseSurfaceTransition = (odoStart, odoEnd, surfaceArray) => {
    const sortedSurfaceArray = surfaceArray.sort((a, b) => a.key - b.key);
    const currentSurface = sortedSurfaceArray
        .filter((s) => s.key <= odoStart)
        .pop();
    const currentSurfaceLabel = surfaceTypes.find(
        (t) => t.id === currentSurface.value,
    ).name;
    let transitions = sortedSurfaceArray.filter(
        (t) => t.key >= odoStart && t.key <= odoEnd,
    );
    if (transitions[0] && transitions[0].value === currentSurface.value) {
        transitions = transitions.unshift();
    }
    if (!transitions.length) {
        return ` bez promjene podloge (${currentSurfaceLabel})`;
    } else {
        const transitionLabels = transitions.map((transition) => {
            const label = surfaceTypes.find(
                (type) => type.id === transition.value,
            ).name;
            const odom = transition.key;
            return `${label} (na ${odom} km)`;
        });
        return ` sa promjenama podloge: ${currentSurfaceLabel} -> ${transitionLabels.join(
            ' -> ',
        )}`;
    }
};

export const cleanUpSymbols = (points) => {
    points.forEach((point, index) => {
        const { name, symbol, pictogram } = point.properties;
        if (!index) {
            point.properties.symbol = 'START';
        } else if (index === points.length - 1) {
            point.properties.symbol = 'END';
        } else {
            if (symbol === 'END' || symbol === 'START') {
                point.properties.symbol = 'CROSSROAD';
            }
            point.properties.symbol = 'PLACE';
            if (containsWords(name, ['za ', 'sporedni ', 'put ', 'raskrsnica'])) {
                point.properties.symbol = 'CROSSROAD';
            }
            if (containsWords(name, ['PD ', 'planinarski', 'dom '])) {
                point.properties.symbol = 'FOOD';
            }
            if (containsWords(name, ['prevoj '])) {
                point.properties.symbol = 'PASS';
            }
            if (containsWords(name, ['vrh '])) {
                point.properties.symbol = 'SUMMIT';
            }
            if (
                containsWords(name, [' vode', 'izvor '])
                || name.toLowerCase().indexOf('voda') === 0
                || pictogram.toLowerCase().indexOf('v') > -1
            ) {
                point.properties.symbol = 'WATER';
            }
        }
    });
};

export const generateIconMarkers = (points) => {
    points.forEach((point) => {
        const { symbol } = point.properties;
        switch (symbol) {
            case 'PLACE':
            case 'VILLAGE':
                point.properties.iconMarker = 'star-15';
                break;
            case 'CITY':
                point.properties.iconMarker = 'city';
                break;
            case 'DANGER':
            case 'MINES':
                point.properties.iconMarker = 'danger';
                break;
            case 'WATER':
                point.properties.iconMarker = 'drinking-water';
                break;
            case 'FOOD':
                point.properties.iconMarker = 'restaurant';
                break;
            case 'SLEEP':
                point.properties.iconMarker = 'lodging';
                break;
            case 'PHOTO':
                point.properties.iconMarker = 'photo';
                break;
            case 'PASS':
                point.properties.iconMarker = 'pass';
                break;
            case 'RIVER':
                point.properties.iconMarker = 'photo';
                break;
            case 'SUMMIT':
                point.properties.iconMarker = 'summit';
                break;
            case 'START':
                point.properties.iconMarker = 'marker-bike-cyan';
                break;
            case 'END':
                point.properties.iconMarker = 'marker-bike-amber';
                break;
            default:
                point.properties.iconMarker = 'crossroad';
        }
    });
};

