/* global polyline */
// import { encode } from '@googlemaps/polyline-codec';
import { AppEvent } from '../constants/events';
import { UrlContstants, processingParameters } from '../constants/constants';
import { calculateZoomLevel } from '../helpers/geoSpatialHelper';
import { slug } from '../helpers/commonHelper';

export const toggleActionFlag = (flagName) => {
    return {
        type: AppEvent.admin.TOGGLE_ACTION_FLAG,
        flagName,
    };
};

export const loadLocalStorageIntoState = () => {
    return {
        type: AppEvent.admin.LOAD_LOCALE_STORAGE_INTO_STATE,
    };
};

export const saveStateIntoLocalStorage = () => {
    return {
        type: AppEvent.admin.SAVE_STATE_INTO_LOCALE_STORAGE,
    };
};

export const pickCurrentPositionOdometer = (odometer) => {
    return {
        type: AppEvent.admin.PICK_CURRENT_ODOMETER,
        odometer,
    };
};

export const simplifyCollectionLines = () => {
    return {
        type: AppEvent.admin.SIMPLIFY_LINES,
    };
};

export const elevateCollection = () => {
    return {
        type: AppEvent.admin.ELEVATE_COLLECTION,
    };
};

export const stickyCollectionPoints2lines = () => {
    return {
        type: AppEvent.admin.STICKY_POINTS_2_LINES,
    };
};

export const enrichCollection = () => {
    return {
        type: AppEvent.admin.ENRICH_COLLECTION,
    };
};

export const invertCollection = () => {
    return {
        type: AppEvent.admin.INVERT_COLLECTION,
    };
};

export const loadResolvedCoordinatesFromLocalStorage = (coordinates) => {
    return {
        type: AppEvent.admin.COORDINATES_LOADED_FROM_STORAGE,
        coordinates,
    };
};

export const updateActiveCollection = (payload) => {
    return {
        type: AppEvent.map.UPDATE_ACTIVE_COLLECTION,
        payload,
    };
};

export const focusFeatureOnMap = (feature) => {
    return {
        type: AppEvent.map.FOCUS_MAP_FEATURE,
        feature,
    };
};

export const removeFeatureOnMap = (feature) => {
    return {
        type: AppEvent.admin.REMOVE_FEATURE_FROM_COLLECTION,
        feature,
    };
};

export const initiateActiveCollectionPreview = (fitBounds = false) => {
    return {
        type: AppEvent.map.PREVIEW_ACTIVE_COLLECTION,
        payload: {
            fitBounds,
        },
    };
};

export const initiateActiveCollectionEdit = (fitBounds = false) => {
    return {
        type: AppEvent.map.EDIT_ACTIVE_COLLECTION,
        payload: {
            fitBounds,
        },
    };
};

export const saveEditedCollection = (payload) => {
    return {
        type: AppEvent.map.SAVE_EDITED_COLLECTION,
        payload,
    };
};

export const setCollectionActiveById = (payload) => {
    return {
        type: AppEvent.map.SET_COLLECTION_ACTIVE_BY_ID,
        payload,
    };
};

export const geoFileUploaded = (payload) => {
    return {
        type: AppEvent.admin.GEO_FILE_UPLOADED,
        payload,
    };
};

export const collectionPublished = () => {
    return {
        type: AppEvent.admin.COLLECTION_PUBLISHED,
    };
};

export const generateFeaturesNames = () => {
    return {
        type: AppEvent.admin.GENERATE_FEATURE_NAMES,
    };
};

export const fetchTokenSetup = () => {
    return function (dispatch) {
        console.log(UrlContstants.getToken);
        return fetch(UrlContstants.getToken)
            .then((response) => {
                return response.json();
            })
            .then((data) => {
                dispatch({
                    type: AppEvent.admin.GOT_TOKEN,
                    payload: data,
                });
            })
            .catch((error) => {
                console.error('Error:', error);
            });
    };
};

export const fetchInitialSetup = () => {
    return function (dispatch) {
        // console.log(payload);
        return fetch(UrlContstants.getInitialSetup)
            .then((response) => {
                return response.json();
            })
            .then((data) => {
                dispatch({
                    type: AppEvent.admin.GOT_INITIAL_SETUP,
                    payload: data,
                });
            })
            .catch((error) => {
                console.error('Error:', error);
            });
    };
};

export const fetchPOIsource = (keyname) => {
    return function (dispatch) {
        // console.log(payload);
        return fetch(`${UrlContstants.getJson}${keyname}`)
            .then((response) => {
                return response.json();
            })
            .then((data) => {
                dispatch({
                    type: AppEvent.admin.GOT_POIS,
                    payload: {
                        keyname,
                        data,
                    },
                });
            })
            .catch((error) => {
                console.error('Error:', error);
            });
    };
};

export const initiateCoordinatesElevationFetch = () => {
    return {
        type: AppEvent.admin.COORDINATES_FETCHING_STARTED,
    };
};

export const fetchElevation4Coordinates = (coordinates) => {
    return function (dispatch) {
        // console.log(`#Fetching new ${coordinates.length} coordinates`);
        const polylined = polyline.encode(coordinates);
        return fetch(`${UrlContstants.elevation}${polylined}`)
            .then((response) => {
                return response.json();
            })
            .then((data) => {
                if (!data.status || data.status !== 'OK') {
                    console.error(data);
                } else {
                    dispatch({
                        type: AppEvent.admin.COORDINATES_RESOLVED,
                        data: data.results.map((r) => {
                            return {
                                lng: r.location.lng,
                                lat: r.location.lat,
                                elevation: 0 || r.elevation,
                            };
                        }),
                    });
                }
            })
            .catch((error) => {
                console.error('Error:', error);
            });
    };
};

export const fetchFeatureThumbnail = (feature, tokenSetup) => {
    return function (dispatch) {
        const isPoint = !!feature.properties.parent;
        const accessToken = tokenSetup.token;
        const deleteFile = '';
        let url;
        if (isPoint) {
            const geojson = encodeURIComponent(
                JSON.stringify(feature.properties.wpGeoJSON),
            );
            const mapParams = `${feature.geometry.coordinates[0]},${feature.geometry.coordinates[1]},16,${feature.properties.bearing},45`;
            const folder = `${feature.properties.parent.id}/`;
            const fileName = `point_generated_satellite_${feature.properties.id}.png`;
            url = `${UrlContstants.waypointGeneration}?geojson=${geojson}&mapParams=${mapParams}&folder=${folder}&fileName=${fileName}&deleteFile=${deleteFile}&accessToken=${accessToken}`;
        } else {
            const { coordinates } = feature.geometry;
            const coordinatesInverted = coordinates.map((c) => [c[1], c[0]]);
            const coordinatesPolilined = polyline.encode(coordinatesInverted);
            const polylinedPath = encodeURIComponent(coordinatesPolilined);
            const mapParams = `${feature.properties.center[0]},${
                feature.properties.center[1]
            },${calculateZoomLevel(feature) - 1}`;
            // const mapParams = 'auto';
            const folder = `${feature.properties.id}/`;
            const fileName = `line_generated_satellite_${feature.properties.id}.png`;
            url = `${UrlContstants.trailPreviewGeneration}?polyline=${polylinedPath}&mapParams=${mapParams}&folder=${folder}&fileName=${fileName}&deleteFile=${deleteFile}&accessToken=${accessToken}`;
        }
        // console.log(url);
        return fetch(url)
            .then((response) => {
                return response.json();
            })
            .then((data) => {
                if (data.success) {
                    const value = `${UrlContstants.imageRepository}${data.url}`;
                    // console.log(data.geturl);
                    // console.log(value);
                    dispatch({
                        type: AppEvent.admin.UPDATE_SINGLE_FEATURE_PROPERTY,
                        id: feature.properties.id,
                        key: 'imageUrl',
                        value,
                    });
                } else {
                    console.error(data);
                }
            })
            .catch((error) => {
                console.error('Error:', error);
            });
    };
};

export const getAllTrailsList = () => {
    return function (dispatch) {
        return fetch(UrlContstants.getAllTrails)
            .then((response) => {
                return response.json();
            })
            .then((data) => {
                if (data.length) {
                    dispatch({
                        type: AppEvent.admin.HOME_GEOJSON_LOADED,
                        data,
                    });
                }
            })
            .catch((error) => {
                console.error('Error:', error);
            });
    };
};

export const getNearestTrailsList = (point, part) => {
    const url = `${UrlContstants.getNearestTrails}?${
        part === 'before' ? 'end' : 'start'
    }=${point}&treshold=${
        processingParameters.offRoadPointTreshold
    }&status=1,2`;
    // part [before, after]
    return function (dispatch) {
        return fetch(url)
            .then((response) => {
                return response.json();
            })
            .then((data) => {
                console.log(`${url}   ${data.length}`);
                if (data.length) {
                    dispatch({
                        type: AppEvent.admin.NEAREST_TRAILS_LOADED,
                        payload: {
                            data,
                            part,
                        },
                    });
                }
            })
            .catch((error) => {
                console.error('Error:', error);
            });
    };
};

export const getTrailGeoJson = (uuid) => {
    return function (dispatch) {
        // console.log(payload);
        return fetch(`${UrlContstants.getJson}${uuid}`)
            .then((response) => {
                return response.json();
            })
            .then((data) => {
                const { features } = data;
                const { trailName, name } = features[0].properties;
                const slugKey = slug(trailName || name);
                dispatch({
                    type: AppEvent.admin.GEO_FILE_UPLOADED,
                    payload: {
                        collection: data,
                        slugKey,
                        fileName: 'Downloaded',
                    },
                });
                dispatch({
                    type: AppEvent.map.PREVIEW_ACTIVE_COLLECTION,
                    payload: {
                        fitBounds: true,
                    },
                });
            })
            .catch((error) => {
                console.error('Error:', error);
            });
    };
};

export const getTrailGeoJson4Update = (uuid) => {
    return function (dispatch) {
        // console.log(payload);
        return fetch(`${UrlContstants.getJson}${uuid}`)
            .then((response) => {
                return response.json();
            })
            .then((collection) => {
                fetch(`${UrlContstants.getTrailStatus}${uuid}`)
                    .then((response) => {
                        return response.json();
                    })
                    .then((status) => {
                        dispatch({
                            type: AppEvent.admin.GEO_FILE_FETCHED_4_UPDATE,
                            payload: {
                                collection,
                                uuid,
                                status,
                            },
                        });
                    })
                    .catch((error) => {
                        console.error('Error:', error);
                    });
            })
            .catch((error) => {
                console.error('Error:', error);
            });
    };
};

export const getTrailGeoJson2Combine = (uuid, unshift = false) => {
    return function (dispatch) {
        return fetch(`${UrlContstants.getJson}${uuid}`)
            .then((response) => {
                return response.json();
            })
            .then((data) => {
                dispatch({
                    type: AppEvent.admin.COLLECTION_2_COMBINE,
                    collection: data,
                    unshift,
                });
            })
            .catch((error) => {
                console.error('Error:', error);
            });
    };
};

export const initiateCombine = () => {
    return {
        type: AppEvent.admin.COMBINE_COLLECTIONS,
    };
};

export const removeTrailFromCombine = (uuid) => {
    return {
        type: AppEvent.admin.REMOVE_COLLECTION_FROM_COMBINE,
        uuid,
    };
};

export const purgeTrailByUUID = (uuid) => {
    return function (dispatch) {
        return fetch(`${UrlContstants.purgeTrail}${uuid}`)
            .then((response) => {
                return response.json();
            })
            .then((data) => {
                const { done, uuid } = data;
                if (done) {
                    dispatch({
                        type: AppEvent.admin.PURGE_COLLECTIONS,
                        uuid,
                    });
                }
            })
            .catch((error) => {
                console.error('Error:', error);
            });
    };
};

export const updateTrailProperty = (uuid, propName, propValue) => {
    return function () {
        return fetch(
            `${UrlContstants.updateTrailProperty}?uuid=${uuid}&propName=${propName}&propValue=${propValue}`,
        )
            .then((response) => {
                return response.json();
            })
            .then((data) => {
                console.log(data);
            })
            .catch((error) => {
                console.error('Error:', error);
            });
    };
};

export const populateCandidates4Fetch = (candidates) => {
    return {
        type: AppEvent.admin.POPULATE_CANDIDATES_FOR_BATCH_FETCH,
        candidates,
    };
};

export const populateCandidates4Update = (candidates) => {
    return {
        type: AppEvent.admin.POPULATE_CANDIDATES_FOR_BATCH_UPDATE,
        candidates,
    };
};

export const updateSingleCollectionInBatchProcess = (candidate) => {
    return {
        type: AppEvent.admin.UPDATE_SINGLE_COLLECTION_IN_BATCH_PROCESS,
        candidate,
    };
};

export const uploadSingleCollectionInBatchProcess = (payload) => {
    const { p1, p2, status, uuid } = payload;
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'text/plain' },
        body: JSON.stringify(p1),
    };
    return function (dispatch) {
        fetch(UrlContstants.uploadCollection, requestOptions)
            .then((response) => response.json())
            .then((data) => {
                if (data.status) {
                    console.log(data);

                    const requestOptions = {
                        method: 'POST',
                        headers: { 'Content-Type': 'text/plain' },
                        body: JSON.stringify({
                            ...p2,
                            status,
                        }),
                    };
                    fetch(UrlContstants.publishCollection, requestOptions)
                        .then((response) => response.json())
                        .then((data) => {
                            if (data.status) {
                                console.log(data);
                                dispatch({
                                    type: AppEvent.admin.COLLECTION_BATCH_UPDATE_COMPLETED,
                                    uuid,
                                });
                            } else {
                                console.warn(data);
                            }
                        })
                        .catch((error) => {
                            console.error('Error:', error);
                        });

                } else {
                    console.warn(data);
                }
            })
            .catch((error) => {
                console.error('Error:', error);
            });
    }
};

export const singleCollectionBatchUpdateCompleted = (uuid) => {
    return {
        type: AppEvent.admin.COLLECTION_BATCH_UPDATE_COMPLETED,
        uuid,
    };
};

export const setLayersVisibility = (layerSet, visible) => {
    return {
        type: AppEvent.map.SET_LAYER_SET_VISIBILITY,
        payload: {
            layerSet,
            visible,
        },
    };
};
