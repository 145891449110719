export const AppAction = {
    COMBINED: 'Combined',
    GEO_FILE_UPLOAD: 'Uploaded',
    DRAW_UPDATE: 'Drawed',
    DATA_UPDATE: 'Data updated',
    SIMPLIFY: 'Simplified',
    STICKYPOINTS: 'Points2Lines',
    ELEVATE: 'Elevated',
    SMOOTHED: 'Smoothed',
    ENRICHED: 'Enriched',
    INVERTED: 'Inverted',
    FEATURE_REMOVED: 'Feature removed',
    NAME_GENERATED: 'Names generated',
};
