import React from 'react';
import { connect } from 'react-redux';
import { getSocialNetworkSharingSetup } from '../../store/presentationSelector';
import { copyToClipboard } from '../../helpers/fileHelper';

import Modal from './Modal';
import SocialNetworkShareItems from './SocialNetworkShareItems';
import Button from '@material-ui/core/Button';
import ShareIcon from '@material-ui/icons/Share';
import TextField from '@material-ui/core/TextField';

const SocialNetworkShare = (props) => {
    const { setup } = props;

    return (
        <Modal
            buttonLabel="Podijeli"
            buttonVariant="icon"
            buttonIcon={<ShareIcon />}
            modalTitle="Podijeli na drustvenim mrezama"
        >
            <div className="margin-vertical" />
            <SocialNetworkShareItems setup={setup} />
            <div className="margin-vertical--double" />
            <TextField
                id="outlined-multiline-static"
                label="HTML kod za ubacivanje u vasu web stranicu"
                multiline
                rows={4}
                fullWidth
                value={setup.embed}
                variant="outlined"
                InputProps={{
                    readOnly: true,
                }}
            />
            <div className="margin-vertical" />
            <Button
                variant="contained"
                className="desktop-only"
                color="primary"
                onClick={() => {
                    copyToClipboard(setup.embed);
                }}
            >
                Kopiraj
            </Button>
            <div className="margin-vertical" />
        </Modal>
    );
};

const mapStateToProps = (state) => {
    return {
        setup: getSocialNetworkSharingSetup(state),
    };
};

const mapDispatchToProps = () => {
    return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(SocialNetworkShare);
