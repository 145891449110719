import React, { useEffect, useState } from 'react';
import SurfaceCollectionEditor from './SurfaceCollectionEditor';
import PlaceObjectsEditor from './PlaceObjectsEditor';
import ImageUploader from './ImageUploader';

function EditProperty(props) {
    const {
        label,
        featureKey,
        properties,
        storeValue,
        initialSetup,
        size,
        boolean,
        type,
        multiline,
        select,
        selectFromData,
        surfaceControl,
        placesControl,
        imageUploader,
        onUpdate,
        isPhotoCollection,
    } = props;
    const [value, setValue] = useState(properties[featureKey] || '');
    const calcSize = isPhotoCollection ? 0.6 * size : size;
    // console.log(`Render ${featureKey} with value ${value}`);
    useEffect(() => {
        setValue(storeValue);
    }, [storeValue]);
    const onValueUpdate = (e) => {
        if (boolean) {
            setValue(!value);
            onUpdate(properties.id, featureKey, !value);
        } else {
            setValue(e.target.value);
        }
    };
    const confirmValueUpdate = () => {
        onUpdate(properties.id, featureKey, value);
    };
    const onKeyDownEvent = (e) => {
        if (e.key === 'Enter') {
            confirmValueUpdate();
        }
    };
    let element;
    switch (true) {
        case !!multiline:
            element = (
                <textarea
                    name={featureKey}
                    value={value}
                    // cols={size || 30}
                    rows={2}
                    style={{ width: calcSize || 100 }}
                    onChange={onValueUpdate}
                    onKeyDown={onKeyDownEvent}
                />
            );
            break;
        case !!selectFromData:
            element = (
                <select
                    onChange={onValueUpdate}
                    value={value}
                    onKeyDown={onKeyDownEvent}
                    style={{ width: calcSize || 100 }}
                >
                    {properties[selectFromData].map((option, idx) => {
                        const { id, key, name } = option;
                        return (
                            <option key={`${idx}-${key || id}`} value={id}>
                                {name}
                            </option>
                        );
                    })}
                </select>
            );
            break;
        case !!select:
            element = (
                <select
                    onChange={onValueUpdate}
                    value={value}
                    onKeyDown={onKeyDownEvent}
                    style={{ width: calcSize || 100 }}
                >
                    {initialSetup[select].map((option, idx) => {
                        const { id, name } = option;
                        return (
                            <option key={`${idx}-${id}`} value={id}>
                                {name}
                            </option>
                        );
                    })}
                </select>
            );
            break;
        case boolean:
            element = (
                <input
                    name={featureKey}
                    type="checkbox"
                    value={value}
                    checked={value}
                    onChange={onValueUpdate}
                />
            );
            break;
        case surfaceControl:
            element = (
                <SurfaceCollectionEditor
                    value={value}
                    onUpdate={(value) => {
                        setValue(value);
                        onUpdate(properties.id, featureKey, value);
                    }}
                />
            );
            break;
        case placesControl:
            element = (
                <PlaceObjectsEditor
                    value={value}
                    onUpdate={(value) => {
                        setValue(value);
                        onUpdate(properties.id, featureKey, value);
                    }}
                />
            );
            break;
        case imageUploader:
            element = (
                <ImageUploader
                    value={value}
                    properties={properties}
                    isPhotoCollection={isPhotoCollection}
                    onUpdate={(value) => {
                        setValue(value);
                        onUpdate(properties.id, featureKey, value);
                    }}
                />
            );
            break;
        default:
            element = (
                <input
                    name={featureKey}
                    type={type || 'text'}
                    value={value}
                    style={{ width: calcSize || 100 }}
                    onChange={onValueUpdate}
                    onKeyDown={onKeyDownEvent}
                />
            );
    }
    // console.log(`${featureKey} - ${value}`);
    return (
        <div className="feature__property">
            <label htmlFor={featureKey}>{label}</label>
            {element}
        </div>
    );
}

export default EditProperty;
