import React from 'react';
// import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Collapse from '@material-ui/core/Collapse';
import CardActions from '@material-ui/core/CardActions';
import Avatar from '@material-ui/core/Avatar';
// import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
// import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Divider from '@material-ui/core/Divider';
import RoomIcon from '@material-ui/icons/Room';
import Button from '@material-ui/core/Button';
import Icon from '@material-ui/core/Icon';
import { connect } from 'react-redux';
import { focusFeatureOnMap } from '../../store/presentationActions';
import { FormattedMessage } from 'react-intl';
import { grey } from '@material-ui/core/colors';

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        width: '100%',
        flexDirection: 'column',
    },
    card: {
        display: 'flex',
        flexDirection: 'row',
        width: '100%',
    },
    details: {
        display: 'flex',
        flex: '1 1 100%',
        flexDirection: 'column',
    },
    content: {
        flex: '1 0 100%',
    },
    image: {
        flex: '0 0 30%',
        minWidth: 120,
    },
    controls: {
        display: 'flex',
        alignItems: 'center',
        paddingLeft: theme.spacing(1),
        paddingBottom: theme.spacing(1),
    },
    playIcon: {
        height: 38,
        width: 38,
    },
    large: {
        width: theme.spacing(7),
        height: theme.spacing(7),
        padding: 8,
        boxShadow:
            '0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)',
    },
    expandOpen: {
        transform: 'rotate(180deg)',
    },
    buttonText: {
        color: grey,
        textTransform: 'none',
    },
}));

const SingleTrailWaypoint = (props) => {
    const classes = useStyles();
    const { feature, focusFeatureOnMap } = props;
    const {
        name,
        desc,
        odometer,
        imageUrl,
        pictogram,
        imageCredits,
    } = feature.properties;
    const elevation = feature.geometry.coordinates[2];
    const [expanded, setExpanded] = React.useState(false);
    const handleFocusFeatureOnMap = () => {
        focusFeatureOnMap(feature);
    };
    const handleExpandClick = () => {
        setExpanded(!expanded);
    };
    return (
        <>
            <div className="margin-vertical" />
            <Card className={classes.root}>
                <div className={classes.card}>
                    <div className={classes.details}>
                        <CardHeader
                            avatar={
                                <Avatar
                                    alt={name}
                                    variant="rounded"
                                    src={`//www.staze.net/images/svg/getsvg.php?opis=${pictogram}`}
                                    className={classes.large}
                                />
                            }
                            title={
                                <Typography variant="body2">{`${Math.round(
                                    odometer,
                                )} km, ${Math.round(
                                    elevation,
                                )} mnv`}</Typography>
                            }
                            subheader={
                                <Typography variant="h6">{name}</Typography>
                            }
                        />
                        <CardActions>
                            <div className="flex row justify-space-between fullwidth">
                                <Button
                                    startIcon={<RoomIcon />}
                                    onClick={handleFocusFeatureOnMap}
                                    className={classes.buttonText}
                                >
                                    {name}
                                </Button>
                                <Button
                                    endIcon={
                                        <Icon>
                                            {expanded
                                                ? 'expand_less'
                                                : 'expand_more'}
                                        </Icon>
                                    }
                                    onClick={handleExpandClick}
                                    className={classes.buttonText}
                                >
                                    <FormattedMessage id="common.description" />
                                </Button>
                            </div>
                        </CardActions>
                    </div>
                    <CardMedia
                        className={classes.image}
                        image={imageUrl}
                        title={`Foto: ${imageCredits}`}
                    />
                </div>
                <Collapse in={expanded} timeout="auto" unmountOnExit>
                    <Divider />
                    <CardContent>
                        <Typography variant="subtitle1" color="textSecondary">
                            Opis:
                        </Typography>
                        <Typography paragraph>{desc}</Typography>
                    </CardContent>
                </Collapse>
            </Card>
        </>
    );
};

const mapStateToProps = () => {
    return {};
};

const mapDispatchToProps = (dispatch) => {
    return {
        focusFeatureOnMap: (feature) => dispatch(focusFeatureOnMap(feature)),
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(SingleTrailWaypoint);
