import { AppEvent } from '../constants/events';
import {
    getHomeCollection,
    clientParseCollection,
} from '../helpers/collectionHelper';
import { getCollectionBounds, getFocusAction } from '../helpers/mapToolsHelper';
import { getTrailSegments4Map } from '../helpers/elevationChartHelper';
import { uiConstants } from '../constants/constants';

const initialState = {
    mapLoaded: false,
    collection: {
        activeGuid: null,
        home: {},
        trail: {},
        group: {},
    },
    mapActionStack: [],
    uiStates: {
        trailListLayout: uiConstants.trailListLayout.grid,
        trailListSortRule: null,
    },
    modal: {
        opened: false,
    },
    mobileLayout: {
        map: true,
        details: false,
        chart: false,
    },
    preview: {
        playing: false,
        index: 0,
    },
    tempTrailSelection: {
        uuids: [],
        locked: false,
    },
};

export default function presentationState(state = initialState, action) {
    switch (action.type) {
        case AppEvent.client.SET_CLIENT_MOBILE_LAYOUT: {
            const newState = { ...state };
            newState.mobileLayout = action.layout;
            return newState;
        }
        case AppEvent.client.SET_COLLECTION_SORT_RULE: {
            const newState = { ...state };
            newState.uiStates.trailListSortRule = action.newRule;
            return newState;
        }
        case AppEvent.client.SET_TRAIL_LIST_LAYOUT: {
            const newState = { ...state };
            newState.uiStates.trailListLayout = action.newLayout;
            return newState;
        }
        case AppEvent.map.FOCUS_MAP_FEATURE: {
            const newState = { ...state };
            newState.mapActionStack.push(getFocusAction([action.feature], true));
            newState.mobileLayout = {
                map: true,
                details: false,
                chart: false,
            };
            return newState;
        }
        // MAP PREVIEW
        case AppEvent.client.PREVIEW_ACTIVE_HOME_COLLECTION: {
            const newState = { ...state };
            newState.collection.activeGuid = null;
            const collection = {
                ...newState.collection.home,
            };
            newState.mapActionStack.push({
                collection,
                bounds: getCollectionBounds(collection),
            });
            return newState;
        }
        case AppEvent.client.PREVIEW_ACTIVE_TRAILS_GROUP_COLLECTION: {
            const newState = { ...state };
            const { uuid } = action;
            newState.collection.activeGuid = uuid;
            const collection = {
                ...newState.collection.group[uuid],
            };
            newState.mapActionStack.push({
                collection,
                bounds: getCollectionBounds(collection),
            });
            return newState;
        }
        case AppEvent.client.PREVIEW_ACTIVE_TRAIL_COLLECTION: {
            const newState = { ...state };
            const { uuid } = action;
            newState.collection.activeGuid = uuid;
            const collection = {
                ...newState.collection.trail[uuid],
            };
            newState.mapActionStack.push({
                collection: getTrailSegments4Map(collection),
                bounds: getCollectionBounds(collection),
            });
            return newState;
        }
        // COLLECTION LOAD
        case AppEvent.client.HOME_GEOJSON_LOADED: {
            const newState = { ...state };
            const { trails } = action;
            const collection = getHomeCollection(trails);
            newState.collection.activeGuid = null;
            newState.collection.home = collection;
            // newState.mapActionStack.push({
            //     collection,
            //     bounds: getCollectionBounds(collection),
            // });
            return newState;
        }
        case AppEvent.client.GROUP_GEOJSON_LOADED: {
            const newState = { ...state };
            const { trails, uuid } = action;
            const collection = getHomeCollection(trails, uuid);
            newState.collection.activeGuid = uuid;
            newState.collection.group[uuid] = {
                ...collection,
            };
            // newState.mapActionStack.push({
            //     collection,
            //     bounds: getCollectionBounds(collection),
            // });
            return newState;
        }
        case AppEvent.client.TRAIL_GEOJSON_LOADED: {
            const newState = { ...state };
            const { collection, uuid } = action.payload;
            const parsedCollection = clientParseCollection(
                collection,
                newState.initialSetup,
            );
            newState.collection.activeGuid = uuid;
            newState.collection.trail[uuid] = parsedCollection;
            // newState.collection.home = {};
            // newState.mapActionStack.push({
            //     collection,
            //     bounds: getCollectionBounds(collection),
            // });
            return newState;
        }
        case AppEvent.client.SET_MODAL_STATE: {
            const newState = { ...state };
            newState.modal = { ...action.payload };
            return newState;
        }
        case AppEvent.map.MAP_LOADED: {
            const newState = { ...state };
            newState.mapLoaded = true;
            return newState;
        }
        case AppEvent.admin.GOT_INITIAL_SETUP: {
            const newState = {
                ...state,
                initialSetup: action.payload,
            };
            return newState;
        }
        case AppEvent.client.TOGGLE_PLAY_TRAIL_PREVIEW: {
            const newState = { ...state };
            newState.preview.playing = !state.preview.playing;
            newState.mapActionStack.push({
                preview: {
                    ...newState.preview,
                    collection: newState.collection.trail[newState.collection.activeGuid]
                },
            });
            return newState;
        }
        case AppEvent.client.SET_TEMPORARY_TRAILS_SELECTION: {
            const newState = { ...state };
            const { trails, locked } = action.payload;
            if (state.tempTrailSelection.locked && !locked) {
                return newState;
            }
            if (trails.length) {
                newState.tempTrailSelection = {
                    uuids: trails.map((t) => t.properties.trailUUID),
                    locked,
                };
                newState.mapActionStack.push(getFocusAction(trails, false, !locked));
            } else {
                newState.tempTrailSelection = {
                    uuids: [],
                    locked: false,
                };
                newState.mapActionStack.push(getFocusAction([]));
            }
            return newState;
        }
        default:
            return state;
    }
}
