class CollectionControl {
    constructor(map) {
        this._map = map;
    }

    onAdd() {
        this._container = document.createElement('div');
        this._container.className = 'mapboxgl-ctrl-group mapboxgl-ctrl';

        // Splitter
        this._trailSpritterButton = document.createElement('button');
        this._trailSpritterButton.className =
            'mapbox-gl-draw_ctrl-draw-btn split';
        this._trailSpritterButton.title =
            'Split trail line string near the selected point on map';
        this._trailSpritterButton.addEventListener('click', () => {
            this._action = 'splitter';
        });
        this._container.appendChild(this._trailSpritterButton);

        this._map.on('click', (e) => {
            if (this._action === 'splitter') {
                const clickedPoint = JSON.parse(JSON.stringify(e.lngLat));
                this._map.fire('lineSlice', { position: clickedPoint });
                setTimeout(() => {
                    this._action = null;
                }, 10);
            }
        });

        return this._container;
    }

    onRemove() {
        this._container.parentNode.removeChild(this._container);
    }
}

export default CollectionControl;
