import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import {
    getAllTrailsList,
    populateCandidates4Fetch,
    populateCandidates4Update,
    getTrailGeoJson4Update,
    updateSingleCollectionInBatchProcess,
    uploadSingleCollectionInBatchProcess,
} from '../../store/adminActions';
import {
    getNextTrailFetchCandidate,
    getNextTrailUpdateCandidate,
} from '../../store/adminSelector';

// Update strings: Vijecnica, Colina kapa
// Update placeObjects where !placeObjects.length

function BatchUpdater(props) {
    const {
        initAllTrailsList,
        trailsList,
        fetchCandidate,
        updateCandidate,
        populateCandidates4Fetch,
        populateCandidates4Update,
        getTrailGeoJson4Update,
        fetched,
        updated,
        updateSingleCollectionInBatchProcess,
        uploadSingleCollectionInBatchProcess,
        uploadCandidate,
    } = props;

    // INITIAL load all trails
    useEffect(() => {
        if (!trailsList.length) {
            initAllTrailsList();
        }
    }, [trailsList, initAllTrailsList]);

    // Fetch candidate one by one
    useEffect(() => {
        if (fetchCandidate) {
            getTrailGeoJson4Update(fetchCandidate.trailUUID);
        }
    }, [fetchCandidate, getTrailGeoJson4Update]);

    // // Update candidate one by one
    // useEffect(() => {
    //     if (updateCandidate) {
    //         updateSingleCollectionInBatchProcess(updateCandidate);
    //     }
    // }, [updateCandidate, updateSingleCollectionInBatchProcess]);
    //
    // // Update candidate one by one
    // useEffect(() => {
    //     if (uploadCandidate) {
    //         uploadSingleCollectionInBatchProcess(uploadCandidate);
    //     }
    // }, [uploadCandidate, uploadSingleCollectionInBatchProcess]);

    // Update candidate one by one
    useEffect(() => {
        if (updateCandidate) {
            if (uploadCandidate) {
                uploadSingleCollectionInBatchProcess(uploadCandidate);
            } else {
                updateSingleCollectionInBatchProcess(updateCandidate)
            }
        }
    }, [
        updateCandidate,
        uploadCandidate,
        updateSingleCollectionInBatchProcess,
        uploadSingleCollectionInBatchProcess,
    ]);

    // Empty render
    if (!trailsList || !trailsList.length) {
        return <FormattedMessage id="admin.loadingTrails" />;
    }
    // Initiate fetching of all trails
    const initiateFetchCandidates = () => {
        populateCandidates4Fetch(trailsList);
    };
    // Initiate update of all trails
    const initiateUpdateCandidates = () => {
        populateCandidates4Update(fetched);
    };
    return (
        <div className="flex column fullwidth">
            <div className="sidebar__row">
                <div className="flex size-qarter">
                    <button onClick={initiateFetchCandidates}>Fetch all candidates for update</button>
                </div>
                <div className="flex size-qarter">
                    <span>All trails ({trailsList.length})   --->   Fetched ({fetched.length})</span>
                </div>
                <div className="flex size-half">
                    <div className="loader__container">
                        <div className="loader__slider" style={{ width: `${(fetched.length/trailsList.length)*100}%` }}></div>
                    </div>
                </div>
            </div>
            <div className="sidebar__row">
                <div className="flex size-half">
                    <span>{fetchCandidate && <span>Fetching {fetchCandidate.name} ...</span>}</span>
                </div>
            </div>
            <div className="sidebar__row">
                <div className="flex size-qarter">
                    <button onClick={initiateUpdateCandidates}>Start updating all candidates</button>
                </div>
                <div className="flex size-qarter">
                    <span>Candidates ({fetched.length})   --->   Updated ({updated.length})</span>
                </div>
                <div className="flex size-half">
                    <div className="loader__container">
                        <div className="loader__slider" style={{ width: `${(updated.length/fetched.length)*100}%` }}></div>
                    </div>
                </div>
            </div>
            <div className="sidebar__row">
                <div className="flex size-half">
                    <span>{updateCandidate && <span>Updating {updateCandidate.name} ...</span>}</span>
                </div>
            </div>
        </div>
    );
}

const mapStateToProps = (state) => {
    return {
        trailsList: state.adminState.trailsList,
        fetchCandidate: getNextTrailFetchCandidate(state),
        updateCandidate: getNextTrailUpdateCandidate(state),
        fetchCandidates: state.adminState.batchUpdateStack.fetchCandidates,
        fetched: state.adminState.batchUpdateStack.fetched,
        updateCandidates: state.adminState.batchUpdateStack.updateCandidates,
        updated: state.adminState.batchUpdateStack.updated,
        uploadCandidate: state.adminState.batchUpdateStack.uploadCandidate,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        initAllTrailsList: () => dispatch(getAllTrailsList()),
        updateSingleCollectionInBatchProcess: (candidate) => dispatch(updateSingleCollectionInBatchProcess(candidate)),
        uploadSingleCollectionInBatchProcess: (payload) => dispatch(uploadSingleCollectionInBatchProcess(payload)),
        populateCandidates4Fetch: (candidates) =>
            dispatch(populateCandidates4Fetch(candidates)),
        populateCandidates4Update: (candidates) =>
            dispatch(populateCandidates4Update(candidates)),
        getTrailGeoJson4Update: (uuid) =>
            dispatch(getTrailGeoJson4Update(uuid)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(BatchUpdater);
