import { MapConstants } from '../../constants/constants';

class MapTypeControl {
    constructor(map, admin) {
        this._map = map;
        this._admin = admin;
    }

    onAdd() {
        this._container = document.createElement('div');
        this._container.className = 'mapboxgl-ctrl-group mapboxgl-ctrl';

        this._button1 = document.createElement('button');
        this._button1.className = 'mapbox-gl-draw_ctrl-draw-btn icon satellite';
        this._button1.title = 'Satelitski snimak';
        this._button1.addEventListener(
            'click',
            this.onToggleSatellite.bind(this),
        );
        this._container.appendChild(this._button1);

        // this._button3 = document.createElement('button');
        // this._button3.className = 'mapbox-gl-draw_ctrl-draw-btn icon rgbTerrain';
        // this._button3.title = 'rgbTerrain snimak';
        // this._button3.addEventListener('click', this.onToggleRGBTerrain.bind(this));
        // this._container.appendChild(this._button3);

        this._button2 = document.createElement('button');
        this._button2.className = 'mapbox-gl-draw_ctrl-draw-btn icon mines';
        this._button2.title = 'Sumnjiva povrsina, minska polja';
        this._button2.addEventListener('click', this.onToggleMines.bind(this));
        this._container.appendChild(this._button2);

        if (this._admin) {
            this._button6 = document.createElement('button');
            this._button6.className =
                'mapbox-gl-draw_ctrl-draw-btn icon strava off';
            this._button6.title = 'Toggle STRAVA RUN';
            this._button6.addEventListener(
                'click',
                this.onToggleStravaRun.bind(this),
            );
            this._container.appendChild(this._button6);

            this._button7 = document.createElement('button');
            this._button7.className =
                'mapbox-gl-draw_ctrl-draw-btn icon strava off';
            this._button7.title = 'Toggle STRAVA RIDE';
            this._button7.addEventListener(
                'click',
                this.onToggleStravaRide.bind(this),
            );
            this._container.appendChild(this._button7);
        }

        // this._button4 = document.createElement('button');
        // this._button4.className =
        //     'mapbox-gl-draw_ctrl-draw-btn icon elevation3d';
        // this._button4.title = 'Prikazi 3d vertikalni profil';
        // this._button4.addEventListener(
        //     'click',
        //     this.onToggle3DeLevation.bind(this),
        // );
        // this._container.appendChild(this._button4);

        // this._button5 = document.createElement('button');
        // this._button5.className = 'mapbox-gl-draw_ctrl-draw-btn icon home';
        // this._button5.title = 'Prikazi stazu';
        // this._button5.addEventListener('click', this.onFitHome.bind(this));
        // this._container.appendChild(this._button5);

        return this._container;
    }

    onRemove() {
        this._container.parentNode.removeChild(this._container);
    }

    onToggleRGBTerrain() {
        if (this._map.getLayer(MapConstants.id.layer.rgbTerrain)) {
            if (
                this._map.getLayoutProperty(
                    MapConstants.id.layer.rgbTerrain,
                    'visibility',
                ) === 'visible'
            ) {
                this._map.setLayoutProperty(
                    MapConstants.id.layer.rgbTerrain,
                    'visibility',
                    'none',
                );
                this._button1.className =
                    'mapbox-gl-draw_ctrl-draw-btn icon rgbTerrain';
                this._button1.title = 'Show rgbTerrain map';
            } else {
                this._button1.className =
                    'mapbox-gl-draw_ctrl-draw-btn icon outdoors';
                this._button1.title = 'Show rgbTerrain map';
                this._map.setLayoutProperty(
                    MapConstants.id.layer.rgbTerrain,
                    'visibility',
                    'visible',
                );
            }
        }
    }

    onToggleSatellite() {
        if (this._map.getLayer(MapConstants.id.layer.satellite)) {
            if (
                this._map.getLayoutProperty(
                    MapConstants.id.layer.satellite,
                    'visibility',
                ) === 'visible'
            ) {
                this._map.setLayoutProperty(
                    MapConstants.id.layer.satellite,
                    'visibility',
                    'none',
                );
                this._button1.className =
                    'mapbox-gl-draw_ctrl-draw-btn icon satellite';
                this._button1.title = 'Show satellite map';
            } else {
                this._button1.className =
                    'mapbox-gl-draw_ctrl-draw-btn icon outdoors';
                this._button1.title = 'Show outdoor map';
                this._map.setLayoutProperty(
                    MapConstants.id.layer.satellite,
                    'visibility',
                    'visible',
                );
            }
        }
    }

    onToggleMines() {
        if (this._map.getLayer(MapConstants.id.layer.mines)) {
            if (
                this._map.getLayoutProperty(
                    MapConstants.id.layer.mines,
                    'visibility',
                ) === 'visible'
            ) {
                this._map.setLayoutProperty(
                    MapConstants.id.layer.mines,
                    'visibility',
                    'none',
                );
                this._button2.className =
                    'mapbox-gl-draw_ctrl-draw-btn icon mines off';
            } else {
                this._map.setLayoutProperty(
                    MapConstants.id.layer.mines,
                    'visibility',
                    'visible',
                );
                this._button2.className =
                    'mapbox-gl-draw_ctrl-draw-btn icon mines on';
            }
        }
    }

    // onToggle3DeLevation() {
    //     if (this._map.getLayer('trail-extrusion')) {
    //         if (
    //             this._map.getLayoutProperty('trail-extrusion', 'visibility') ===
    //             'visible'
    //         ) {
    //             this._button4.className =
    //                 'mapbox-gl-draw_ctrl-draw-btn icon elevation3d';
    //             this._button4.title = 'Prikazi 3d vertikalni profil';
    //         } else {
    //             this._button4.className =
    //                 'mapbox-gl-draw_ctrl-draw-btn icon elevation2d';
    //             this._button4.title = 'Prikazi 2d vertikalni profil';
    //         }
    //         this._map.fire('toggle3Delevation');
    //     } else {
    //         this._button4.className =
    //             'mapbox-gl-draw_ctrl-draw-btn icon elevation2d';
    //         this._button4.title = 'Prikazi 2d vertikalni profil';
    //         this._map.fire('build3Delevation');
    //     }
    // }

    onFitHome() {
        this._map.fire('fitHome');
    }

    onToggleStravaRun() {
        if (this._map.getLayer(MapConstants.id.layer.stravaRun)) {
            if (
                this._map.getLayoutProperty(
                    MapConstants.id.layer.stravaRun,
                    'visibility',
                ) === 'visible'
            ) {
                this._map.setLayoutProperty(
                    MapConstants.id.layer.stravaRun,
                    'visibility',
                    'none',
                );
                this._button6.className =
                    'mapbox-gl-draw_ctrl-draw-btn icon strava off';
            } else {
                this._map.setLayoutProperty(
                    MapConstants.id.layer.stravaRun,
                    'visibility',
                    'visible',
                );
                this._button6.className =
                    'mapbox-gl-draw_ctrl-draw-btn icon strava on';
            }
        }
    }

    onToggleStravaRide() {
        if (this._map.getLayer(MapConstants.id.layer.stravaRide)) {
            if (
                this._map.getLayoutProperty(
                    MapConstants.id.layer.stravaRide,
                    'visibility',
                ) === 'visible'
            ) {
                this._map.setLayoutProperty(
                    MapConstants.id.layer.stravaRide,
                    'visibility',
                    'none',
                );
                this._button7.className =
                    'mapbox-gl-draw_ctrl-draw-btn icon strava off';
            } else {
                this._map.setLayoutProperty(
                    MapConstants.id.layer.stravaRide,
                    'visibility',
                    'visible',
                );
                this._button7.className =
                    'mapbox-gl-draw_ctrl-draw-btn icon strava on';
            }
        }
    }
}

export default MapTypeControl;
