/* global toGeoJSON */
import React, { useState } from 'react';
import { getPois } from '../../store/adminSelector';
import {
    geoFileUploaded,
    loadLocalStorageIntoState,
    saveStateIntoLocalStorage,
} from '../../store/adminActions';
import { connect } from 'react-redux';
import { slug, extractFileName } from '../../helpers/commonHelper';

import { FormattedMessage, useIntl } from 'react-intl';

function GeoFileUpload(props) {
    const intl = useIntl();
    const {
        pois,
        geoFileUploaded,
        initLoadLocalStorageIntoState,
        initSaveStateIntoLocalStorage,
    } = props;
    const [fileName, setFileName] = useState('');
    const [slugKey, setSlugKey] = useState('');
    const [collection, setCollection] = useState(false);
    const onFileSelectedEvent = (e) => {
        if (e.target.files.length > 0) {
            const file = e.target.files[0];
            const r = new FileReader();
            r.onload = (e) => {
                const domParser = new DOMParser().parseFromString(
                    e.target.result,
                    'text/xml',
                );
                const fileExtension = file.name.split('.').pop();
                let newCollection;
                switch (fileExtension) {
                    case 'gpx':
                        newCollection = toGeoJSON.gpx(domParser);
                        break;
                    case 'kml':
                        newCollection = toGeoJSON.kml(domParser);
                        break;
                    default:
                        console.warn(
                            intl.formatMessage({ id: 'admin.unknownFormat' }),
                        );
                }
                if (!newCollection) {
                    return;
                }
                setCollection(newCollection);
                setFileName(file.name);
                setSlugKey(slug(extractFileName(file.name)));
            };
            r.readAsText(file);
        }
    };
    const uploadCollection = () => {
        if (!collection) {
            return;
        }
        geoFileUploaded({
            collection,
            slugKey,
            fileName,
        });
    };
    if (!pois) {
        return <FormattedMessage id="admin.loadingPois" />;
    }
    return (
        <>
            <input
                type="file"
                onChange={onFileSelectedEvent}
                style={{ width: 90 }}
            />
            <input
                type="text"
                value={slugKey}
                size={30}
                onChange={(e) => setSlugKey(e.target.value)}
            />
            <button onClick={uploadCollection}>
                <FormattedMessage id="common.upload" />
            </button>
            <button
                onClick={() => {
                    initLoadLocalStorageIntoState();
                }}
            >
                <FormattedMessage id="common.load" />
            </button>
            <button
                onClick={() => {
                    initSaveStateIntoLocalStorage();
                }}
            >
                <FormattedMessage id="common.save" />
            </button>
        </>
    );
}

const mapStateToProps = (state) => {
    return {
        pois: getPois(state),
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        geoFileUploaded: (payload) => dispatch(geoFileUploaded(payload)),
        initLoadLocalStorageIntoState: () => {
            dispatch(loadLocalStorageIntoState());
        },
        initSaveStateIntoLocalStorage: () => {
            dispatch(saveStateIntoLocalStorage());
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(GeoFileUpload);
