/* global turf */
import { MapConstants } from '../constants/constants';

export const getCollectionBounds = (collection) => {
    if (!collection || !collection.features || !collection.features.length) {
        return MapConstants.defaultBBox;
    }
    return turf.bbox(collection);
};

export const getCollectionCenter = (collection) => {
    if (!collection || !collection.features || !collection.features.length) {
        return MapConstants.defaultCenter;
    }
    return turf.center(collection);
};

export const getFocusAction = (features, focusFeature = false, noFocus = false) => {
    const newAction = {};
    if (focusFeature) {
        newAction.focusFeature = true;
        features.forEach((feature) => {
            feature.properties.isHome = focusFeature;
        });
    } else {
        newAction.focus = true;
    }
    const collection = turf.featureCollection([...features]);
    newAction.collection = collection;
    if (features.length === 1 && features[0].geometry.type === 'Point') {
        newAction.center = features[0].geometry.coordinates;
        newAction.zoom = MapConstants.defaultPointFocusZoom;
        newAction.bearing = features[0].properties.bearing;
    } else if (!focusFeature && !noFocus && features.length) {
        newAction.bounds = getCollectionBounds(collection);
    }
    return newAction;
};
