import { AppEvent } from '../constants/events';
import { UrlContstants } from '../constants/constants';

export const setSortRule = (newRule) => {
    return {
        type: AppEvent.client.SET_COLLECTION_SORT_RULE,
        newRule,
    };
};

export const setListLayout = (newLayout) => {
    return {
        type: AppEvent.client.SET_TRAIL_LIST_LAYOUT,
        newLayout,
    };
};

export const focusFeatureOnMap = (feature) => {
    return {
        type: AppEvent.map.FOCUS_MAP_FEATURE,
        feature,
    };
};

export const showHomeGeoJsonOnMap = () => {
    return {
        type: AppEvent.client.PREVIEW_ACTIVE_HOME_COLLECTION,
    };
};

export const showTrailsGroupGeoJsonOnMap = (uuid) => {
    return {
        type: AppEvent.client.PREVIEW_ACTIVE_TRAILS_GROUP_COLLECTION,
        uuid,
    };
};

export const showTrailGeoJsonOnMap = (uuid) => {
    return {
        type: AppEvent.client.PREVIEW_ACTIVE_TRAIL_COLLECTION,
        uuid,
    };
};

export const setMapLoaded = () => {
    return {
        type: AppEvent.map.MAP_LOADED,
    };
};

export const setModal = (payload) => {
    return {
        type: AppEvent.client.SET_MODAL_STATE,
        payload,
    };
};

export const setMobileLayout = (layout) => {
    return {
        type: AppEvent.client.SET_CLIENT_MOBILE_LAYOUT,
        layout,
    };
};

export const getHomeTrails = (allTrails) => {
    return function (dispatch) {
        const endPoint = allTrails
            ? UrlContstants.getAllTrails
            : UrlContstants.getAllTrailsCollection;
        return fetch(endPoint)
            .then((response) => {
                return response.json();
            })
            .then((trails) => {
                if (trails && trails.length) {
                    dispatch({
                        type: AppEvent.client.HOME_GEOJSON_LOADED,
                        trails,
                    });
                }
            })
            .catch((error) => {
                console.error('Error:', error);
            });
    };
};

export const getGroupTrails = (uuid) => {
    return function (dispatch) {
        return fetch(`${UrlContstants.getTrailsGroupJson}${uuid}`)
            .then((response) => {
                return response.json();
            })
            .then((trails) => {
                if (trails && trails.length) {
                    dispatch({
                        type: AppEvent.client.GROUP_GEOJSON_LOADED,
                        trails,
                        uuid,
                    });
                }
            })
            .catch((error) => {
                console.error('Error:', error);
            });
    };
};

export const getTrailGeoJson = (uuid) => {
    return function (dispatch) {
        // console.log(payload);
        return fetch(`${UrlContstants.getJson}${uuid}`)
            .then((response) => {
                return response.json();
            })
            .then((data) => {
                dispatch({
                    type: AppEvent.client.TRAIL_GEOJSON_LOADED,
                    payload: {
                        collection: data,
                        uuid,
                    },
                });
            })
            .catch((error) => {
                console.error('Error:', error);
            });
    };
};

export const togglePlayPreview = () => {
    return {
        type: AppEvent.client.TOGGLE_PLAY_TRAIL_PREVIEW,
    };
};

export const setTemporaryTrailsSelection = (trails, locked = false) => {
    return {
        type: AppEvent.client.SET_TEMPORARY_TRAILS_SELECTION,
        payload: {
            trails,
            locked,
        }
    };
};
