import React, { useEffect } from 'react';
import {
    getTokenSetup,
    getNextMapAction,
    getNextMapAction4Draw,
} from '../../store/adminSelector';
import {
    fetchTokenSetup,
    fetchInitialSetup,
    initiateActiveCollectionPreview,
    updateActiveCollection,
} from '../../store/adminActions';
import { addCoordinate } from '../../store/drawActions';
import { connect } from 'react-redux';
import { initialiseAdminMap } from '../../helpers/mapHelper';
import { executeMapAction } from '../../helpers/mapActions';
import { AppAction } from '../../constants/actions';

let mapInstance;

function AdminMap(props) {
    const {
        canDraw,
        tokenSetup,
        mapAction,
        mapActionDraw,
        initSetup,
        updateActiveCollection,
        drawAddCoordinate,
    } = props;
    let mapContainer;
    // Token dependent
    useEffect(() => {
        if (tokenSetup) {
            initialiseAdminMap(
                mapContainer,
                mapInstance,
                tokenSetup,
                (coordinate) => {
                    if (canDraw) {
                        drawAddCoordinate(coordinate);
                    }
                },
            );
        } else {
            initSetup();
        }
    }, [tokenSetup, mapContainer, initSetup, drawAddCoordinate, canDraw]);
    // Action dependent
    useEffect(() => {
        if (mapAction) {
            executeMapAction(mapAction, (collection) => {
                updateActiveCollection(collection);
            });
        }
        if (mapActionDraw) {
            executeMapAction(mapActionDraw, (collection) => {
                updateActiveCollection(collection);
            });
        }
    }, [mapAction, mapActionDraw, updateActiveCollection]);
    return <div className="map-container" ref={(el) => (mapContainer = el)} />;
}

const mapStateToProps = (state) => {
    return {
        tokenSetup: getTokenSetup(state),
        mapAction: getNextMapAction(state),
        mapActionDraw: getNextMapAction4Draw(state),
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        initSetup: () => {
            dispatch(fetchTokenSetup());
            dispatch(fetchInitialSetup());
        },
        updateActiveCollection: (collection) => {
            dispatch(
                updateActiveCollection({
                    collection,
                    action: AppAction.DRAW_UPDATE,
                }),
            );
            dispatch(initiateActiveCollectionPreview());
        },
        drawAddCoordinate: (coordinate, manual) =>
            dispatch(addCoordinate(coordinate, manual)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(AdminMap);
