import React from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import { IntlProvider } from 'react-intl';

import messages, { locale } from './langs/languageConfig';
import BaseTheme from './styles/themes/BaseTheme';

import AdminLayout from './layouts/AdminLayout';
import ClientLayout from './layouts/ClientLayout';
import TrailPreviewLayout from './layouts/TrailPreviewLayout';

import SingleTrailDataLoader from './dataLoaders/SingleTrailDataLoader';
import AllTrailsDataLoader from './dataLoaders/AllTrailsDataLoader';
import EditAllTrailsDataLoader from './dataLoaders/EditAllTrailsDataLoader';
import TrailsGroupDataLoader from './dataLoaders/TrailsGroupDataLoader';

import { hot } from 'react-hot-loader';

function App() {
    return (
        <IntlProvider messages={messages} locale={locale}>
            <MuiThemeProvider theme={createMuiTheme(BaseTheme)}>
                <BrowserRouter>
                    <Switch>
                        <Route path="/admin/all/trails">
                            <EditAllTrailsDataLoader />
                        </Route>
                        <Route path="/admin/combine">
                            <AdminLayout combine={true} />
                        </Route>
                        <Route path="/admin/draw">
                            <AdminLayout canDraw={true} />
                        </Route>
                        <Route path="/admin/edit">
                            <AdminLayout edit={true} />
                        </Route>
                        <Route path="/admin/manual">
                            <AdminLayout manual={true} />
                        </Route>
                        <Route path="/admin/upload">
                            <AdminLayout upload={true} />
                        </Route>
                        <Route path="/admin/batch">
                            <AdminLayout batch={true} />
                        </Route>
                        <Route path="/admin/preview">
                            <ClientLayout setType="home">
                                <AllTrailsDataLoader includeSegments={true} />
                            </ClientLayout>
                        </Route>
                        <Route path="/admin">
                            <AdminLayout />
                        </Route>
                        <Route path={'/staza/:slug/3d'}>
                            <TrailPreviewLayout>
                                <SingleTrailDataLoader loadOnly={true}/>
                            </TrailPreviewLayout>
                        </Route>
                        <Route path={'/staza/:slug'}>
                            <ClientLayout setType="trail">
                                <SingleTrailDataLoader />
                            </ClientLayout>
                        </Route>
                        <Route path={'/trail/:slug'}>
                            <ClientLayout setType="trail">
                                <SingleTrailDataLoader />
                            </ClientLayout>
                        </Route>
                        <Route path={'/:slug'}>
                            <ClientLayout setType="group">
                                <TrailsGroupDataLoader />
                            </ClientLayout>
                        </Route>
                        <Route path="/">
                            <ClientLayout setType="home">
                                <AllTrailsDataLoader />
                            </ClientLayout>
                        </Route>
                    </Switch>
                </BrowserRouter>
            </MuiThemeProvider>
        </IntlProvider>
    );
}

export default hot(module)(App);
