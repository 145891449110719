import {
    getAllCoordinates,
    getLines,
    getPoints,
    preparePayload4Publish,
} from '../helpers/collectionHelper';
import { processingParameters } from '../constants/constants';

export const getMapData = (state) => {
    return state.adminState.data;
};

export const getPois = (state) => {
    return state.adminState.pois;
};

export const getTokenSetup = (state) => {
    return state.adminState.tokenSetup;
};

export const getInitialSetup = (state) => {
    return state.adminState.initialSetup;
};

export const getActiveCollection = (state) => {
    const allRevisions = getAllRevisions(state);
    const lastRevision = allRevisions[allRevisions.length - 1];
    if (!lastRevision) {
        return null;
    }
    return lastRevision.collection;
};

export const getActiveCollection4Publish = (state) => {
    const collection = JSON.parse(JSON.stringify(getActiveCollection(state)));
    return preparePayload4Publish(collection);
};

export const getNextMapAction = (state) => {
    return state.adminState.mapActionStack[
        state.adminState.mapActionStack.length - 1
    ];
};

export const getNextMapAction4Draw = (state) => {
    return state.drawState.mapActionStack[
        state.drawState.mapActionStack.length - 1
    ];
};

export const getAllRevisions = (state) => {
    return state.adminState.dataStack.map((revision) => {
        return { ...revision };
    });
};

export const getAllCollectionCoordinates = (state) => {
    const collection = getActiveCollection(state);
    return getAllCoordinates(collection);
};

export const getAllUnresolvedCoordinates = (state) => {
    return [...state.adminState.coordinates.unresolved];
};

export const getAllResolvedCoordinates = (state) => {
    return [...state.adminState.coordinates.resolved];
};

export const getUnresolvedCandidatesCoordinates = (state) => {
    const unresolvedCoordinates = getAllUnresolvedCoordinates(state);
    if (!unresolvedCoordinates.length) {
        return [];
    }
    const newCandidates = [];
    for (let i = 0; i < processingParameters.elevate.fetchLimit; i++) {
        const candidate = unresolvedCoordinates[i];
        if (candidate) {
            newCandidates.push([candidate.lat, candidate.lng]);
        }
    }
    return [...newCandidates];
};

export const isElevationFetchInProgress = (state) => {
    return !!state.adminState.actionFlags.elevationFetchInProgress;
};

export const getAllWaypointsForThumbnailGeneration = (state) => {
    const collection = getActiveCollection(state);
    const points = getPoints(collection);
    const lines = getLines(collection);
    const filteredFeatures = [...lines, ...points].filter((p) => {
        const { imageUrl, wpGeoJSON } = p.properties;
        return (!imageUrl || !imageUrl.length) && !!wpGeoJSON;
    });
    return [...filteredFeatures];
};

export const getNextFeatureForThumbnailGeneration = (state) => {
    const candidates = getAllWaypointsForThumbnailGeneration(state);
    if (candidates && candidates.length) {
        return {
            ...candidates[0],
        };
    } else {
        return null;
    }
};

export const actionFlags = (state) => {
    return {
        ...state.actionFlags,
    };
};

export const trails2combine = (state) => {
    return state.adminState.combineStack.map((collection) => {
        const lines = getLines(collection);
        const points = getPoints(collection);
        const firstLine = lines[0];
        return {
            ...firstLine.properties,
            lines: lines.length,
            points: points.length,
        };
    });
};

export const getNextTrailFetchCandidate = (state) => {
    const candidates = state.adminState.batchUpdateStack.fetchCandidates;
    if (candidates && candidates.length) {
        return {
            ...candidates[0],
        };
    } else {
        return null;
    }
};

export const getNextTrailUpdateCandidate = (state) => {
    const candidates = state.adminState.batchUpdateStack.updateCandidates;
    if (candidates && candidates.length) {
        return {
            ...candidates[0],
        };
    } else {
        return null;
    }
};
