import React from 'react';

function PlaceObjectsEditor(props) {
    const { value, onUpdate } = props;
    const removeSegment = (key) => {
        const newValue = [...value.filter((k) => k.uuid !== key)];
        onUpdate(newValue);
    };
    return (
        <div className="feature__surface-collection">
            {value.map((item) => {
                return (
                    <span
                        className="surface__item"
                        style={{
                            backgroundColor:
                                item.type === 'mountain' ? '#0a0' : '#ccc',
                        }}
                        key={item.uuid}
                    >
                        <span className="surface__value">{item.name}</span>
                        <button
                            onClick={() => {
                                removeSegment(item.uuid);
                            }}
                        >
                            <span className="material-icons">
                                remove_circle_outline
                            </span>
                        </button>
                    </span>
                );
            })}
        </div>
    );
}

export default PlaceObjectsEditor;
