/* global turf */
import { getLines, getPoints } from './collectionHelper';
import { MapConstants, surfaceTypes } from '../constants/constants';
import {
    wpIsPlace,
    wpIsAmenities,
    wpIsOrienteering,
    wpIsWarning,
} from '../helpers/waypointHelper';

export const getChartSetup4Line = (collection, lineId, onClick) => {
    const lines = getLines(collection);
    if (!lines.length) {
        return null;
    }
    const line = lines.find((l) => l.properties.id === lineId);
    if (!line) {
        return null;
    }
    const {
        // trailName,
        trailDesc,
        elevMin,
        elevMax,
        surfaceCollection,
        distance,
    } = line.properties;
    const waypoints = getPoints(collection).filter(
        (point) =>
            point.properties.elevationProfile === true &&
            point.properties.parent &&
            point.properties.parent.id === lineId,
    );
    const places = waypoints
        .filter((p) => wpIsPlace(p))
        .map((p) => getAnnotationPoint(p, distance));
    const orienteerings = waypoints
        .filter((p) => wpIsOrienteering(p))
        .map((p) => getAnnotationPoint(p, distance));
    const amenities = waypoints
        .filter((p) => wpIsAmenities(p))
        .map((p) => getAnnotationPoint(p, distance));
    const warnings = waypoints
        .filter((p) => wpIsWarning(p))
        .map((p) => getAnnotationPoint(p, distance));
    return {
        chart: {
            type: 'area',
            height: 300,
        },
        accessibility: {
            description: trailDesc,
        },
        title: {
            // text: trailName,
            text: undefined,
        },
        subtitle: {
            // text: trailDesc,
            text: undefined,
        },
        xAxis: {
            title: {
                text: 'Predjeni put [km]',
            },
            allowDecimals: false,
            type: 'integer',
            labels: {
                formatter: function () {
                    return this.value; // clean, unformatted number for year
                },
            },
            accessibility: {
                rangeDescription: 'Predjeni put u kilometrima',
            },
        },
        yAxis: {
            title: {
                text: 'Nadmorska visina [m]',
            },
            labels: {
                formatter: function () {
                    return this.value;
                },
            },
            min: Math.floor(elevMin / 500) * 500,
            max: Math.ceil(elevMax / 500) * 500 + 500,
        },
        plotOptions: {
            series: {
                states: {
                    hover: {
                        enabled: false,
                    },
                    inactive: {
                        opacity: 1,
                    },
                },
                point: {
                    events: {
                        mouseOver: (event) => {
                            if (window.xxx) {
                                const odometer = Math.round(event.target.options.x * 100) / 100;
                                const coordinates =
                                    line.geometry.coordinates[
                                        event.target.index
                                    ];
                                window.xxx
                                    .getSource(
                                        MapConstants.id.source
                                            .currentPositionMarker,
                                    )
                                    .setData(
                                        turf.featureCollection([
                                            turf.point(coordinates, {
                                                odometer: `${odometer} km`,
                                                elevation: `${coordinates[2]} mnv`,
                                            }),
                                        ]),
                                    );
                            }
                        },
                        click: (event) => {
                            const odometer =
                                Math.round(event.point.x * 100) / 100;
                            onClick(odometer);
                        },
                    },
                },
            },
        },
        tooltip: {
            formatter: function () {
                return `${
                    this.series.userOptions.name
                }<br>Pređeno ${this.x.toFixed(2)} km.<br>Visina ${this.y}mnv`;
            },
        },
        series: getAllSeriesData(line, surfaceCollection),
        annotations: [
            {
                labelOptions: {
                    allowOverlap: true,
                    shape: 'connector',
                    // backgroundColor: 'rgba(0,0,150,0.1)',
                    y: -30,
                },
                labels: orienteerings,
            },
            {
                labelOptions: {
                    allowOverlap: true,
                    // shape: 'connector',
                    backgroundColor: 'rgba(0,0,100,0.5)',
                    // verticalAlign: 'top',
                    y: -10,
                },
                labels: amenities,
            },
            {
                labelOptions: {
                    allowOverlap: true,
                    shape: 'connector',
                    // backgroundColor: 'rgba(0,0,0,0.8)',
                    // verticalAlign: 'bottom',
                    y: -50,
                },
                labels: places,
            },
            {
                labelOptions: {
                    allowOverlap: true,
                    backgroundColor: 'rgba(255,0,0,0.5)',
                    verticalAlign: 'top',
                    y: 30,
                },
                labels: warnings,
            },
        ],
        legend: {
            enabled: false,
        },
        credits: {
            enabled: false,
        },
    };
};

const getAnnotationPoint = (p) => {
    return {
        point: {
            xAxis: 0,
            yAxis: 0,
            x: p.properties.odometer,
            y: p.geometry.coordinates[2],
        },
        text: p.properties.name,
        // x: getRelativeXDistance(p.properties.odometer, totalDistance),
    };
};

// const getRelativeXDistance = (odometer, totalDistance) => {
//     if (odometer < totalDistance / 4) {
//         return 30;
//     }
//     if (odometer > (2 * totalDistance) / 4) {
//         return -30;
//     }
//     return 0;
// };

const distinctSurface = (surfaceCollection, length) => {
    if (!surfaceCollection.length) {
        return [];
    }
    let distinctSurfaces = surfaceCollection.map((segment) => {
        return {
            id: segment.value,
            from: segment.key,
        };
    });
    distinctSurfaces.forEach((segment, idx) => {
        if (idx === distinctSurfaces.length - 1) {
            segment.to = length;
        } else {
            segment.to = distinctSurfaces[idx + 1].from;
        }
    });
    return distinctSurfaces;
};

const getAllSeriesData = (line, surfaceCollection) => {
    const totalLineLength = parseFloat(turf.length(line));
    let dataset = getRichLine(line).map((element) => [
        element.odometer,
        element.elevation,
    ]);
    const surfaceSegments = distinctSurface(surfaceCollection, totalLineLength);
    return surfaceTypes.map((surfaceType) => {
        const segmentDataset = dataset.map((point) => {
            const type = surfaceSegments.find(
                (segment) => segment.from <= point[0] && segment.to >= point[0],
            );
            if (type && type.id === surfaceType.id) {
                return point;
            } else {
                return [point[0], null];
            }
        });
        const indexes2Fix = [];
        segmentDataset.forEach((point, index) => {
            if (
                index > 0 &&
                point[1] === null &&
                segmentDataset[index - 1][1] !== null
            ) {
                indexes2Fix.push(index);
            }
        });
        indexes2Fix.forEach((index) => {
            segmentDataset[index][1] = dataset[index][1];
        });
        return {
            name: surfaceType.name,
            color: surfaceType.style.color,
            marker: {
                enabled: false,
            },
            data: segmentDataset,
        };
    });
};

export const getRichLine = (line) => {
    let dataset = [];
    const totalLineLength = turf.length(line);
    const lineCoordinates = [...line.geometry.coordinates];
    const tempLineCoordinates = [];
    let odometer = 0;
    let elevation = 0;
    let elevGain = 0;
    let elevLoss = 0;
    let currentElevation = parseInt(lineCoordinates[0][2], 10);
    lineCoordinates.forEach((coordinate, cIdx) => {
        tempLineCoordinates.push(coordinate);
        if (cIdx) {
            odometer = turf.length(turf.lineString(tempLineCoordinates));
        }
        elevation = parseFloat(coordinate[2]);
        if (elevation >= currentElevation) {
            elevGain += elevation - currentElevation;
        } else {
            elevLoss += currentElevation - elevation;
        }
        currentElevation = parseFloat(elevation);
        dataset.push({
            lng: parseFloat(coordinate[0]),
            lat: parseFloat(coordinate[1]),
            elevation,
            odometer,
            remain: totalLineLength - odometer,
            elevGain: parseInt(elevGain, 10),
            elevLoss: parseInt(elevLoss, 10),
        });
    });
    return dataset;
};

const getAllLineSegment = (inputLine, surfaceCollection) => {
    const line = JSON.parse(JSON.stringify(inputLine));
    const totalLineLength = parseFloat(turf.length(line));
    const surfaceSegments = distinctSurface(surfaceCollection, totalLineLength);
    const lineCoordinates = [...line.geometry.coordinates];
    const tempLineCoordinates = [];
    lineCoordinates.forEach((point, pointIndex) => {
        tempLineCoordinates.push(point);
        if (!pointIndex) {
            point[3] = 0;
        } else {
            const currentLength = turf.length(
                turf.lineString(tempLineCoordinates),
            );
            point[3] = currentLength;
        }
    });
    return surfaceSegments.map((surfaceSegment) => {
        const segmentCoordinates = [];
        let lastActiveIndex = 0;
        lineCoordinates.forEach((coordinate, coordinateIndex) => {
            if (
                coordinate[3] <= surfaceSegment.to &&
                coordinate[3] >= surfaceSegment.from
            ) {
                segmentCoordinates.push([coordinate[0], coordinate[1]]);
                lastActiveIndex = coordinateIndex;
            }
        });
        if (segmentCoordinates.length && lineCoordinates[lastActiveIndex + 1]) {
            segmentCoordinates.push([
                lineCoordinates[lastActiveIndex + 1][0],
                lineCoordinates[lastActiveIndex + 1][1],
            ]);
        }
        const newSegment =
            segmentCoordinates.length > 1
                ? turf.lineString(segmentCoordinates, {
                      surfaceType: surfaceSegment.id,
                  })
                : false;
        return newSegment;
    });
};

export const getTrailSegments4Map = (collection) => {
    const lines = getLines(collection);
    let newSurfaceSegments = [];
    lines.forEach((line) => {
        const { surfaceCollection } = line.properties;
        const newLineSegments = getAllLineSegment(line, surfaceCollection);
        if (newLineSegments) {
            newSurfaceSegments = [...newSurfaceSegments, ...newLineSegments];
        }
    });
    return {
        ...collection,
        features: [...collection.features, ...newSurfaceSegments],
    };
};
