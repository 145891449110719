import React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';

const DataLoaderSpinner = (props) => {
    const { text } = props;
    return (
        <div className="content flex justify-center items-center">
            <CircularProgress />
            {text && (
                <Typography variant="h5" component="h5">
                    {text}
                </Typography>
            )}
        </div>
    );
};

export default DataLoaderSpinner;
