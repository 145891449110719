import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { connect } from 'react-redux';
import {
    getGroupTrails,
    showTrailsGroupGeoJsonOnMap,
} from '../store/presentationActions';
import { getTrailGroupCollection } from '../store/presentationSelector';
import DataLoaderSpinner from '../components/base/DataLoaderSpinner';
import AllTrailsLayout from '../layouts/AllTrailsLayout';
import { UrlContstants } from '../constants/constants';

const TrailsGroupDataLoader = (props) => {
    const params = useParams();
    const { slug } = params;
    const { collection, initCollection, mapLoaded, previewCollection, loadOnly } = props;
    const [uuid, setUUID] = useState(null);
    // console.log(`### slug: ${slug}, uuid: ${uuid}`);
    // Decode slug into uuid
    useEffect(() => {
        fetch(`${UrlContstants.decodeSlug}${slug}`)
            .then((response) => response.json())
            .then((data) => {
                if (data.status) {
                    console.log(data);
                    if (data.result.type !== 'trail' && uuid !== data.result.uuid) {
                        setUUID(data.result.uuid);
                    }
                } else {
                    console.warn(data);
                }
            })
            .catch((error) => {
                console.error('Error:', error);
            });
    }, [uuid, slug]);
    // Load trails group
    useEffect(() => {
        if (uuid && !collection) {
            initCollection(uuid);
        }
    }, [collection, uuid, initCollection]);
    // Preview trail
    useEffect(() => {
        if (uuid && collection && mapLoaded) {
            previewCollection(uuid);
        }
    }, [collection, mapLoaded, uuid, previewCollection]);
    if (loadOnly) {
        return null;
    }
    if (collection && mapLoaded) {
        return <AllTrailsLayout collection={collection} />;
    }
    return <DataLoaderSpinner />;

};

const mapStateToProps = (state) => {
    return {
        collection: getTrailGroupCollection(state),
        mapLoaded: state.presentationState.mapLoaded,
        listLayout: state.presentationState.uiStates.trailListLayout,
        sortRule: state.presentationState.uiStates.trailListSortRule,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        initCollection: (uuid) => dispatch(getGroupTrails(uuid)),
        previewCollection: (uuid) =>
            dispatch(showTrailsGroupGeoJsonOnMap(uuid)),
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(TrailsGroupDataLoader);
