/* global turf */
import { MapConstants, surfaceTypes, AdditionalLayers, defaultGeoFeatureProperties } from '../constants/constants';
import { auth } from '../constants/configs';
import { getLines, getPoints } from '../helpers/collectionHelper';
import {getUUID} from "./commonHelper";

let timer;

export const initialiseAdminMapListeners = (
    mapInstance,
    sid,
    lid,
    token,
    draw,
    onClick,
) => {
    mapInstance.on('load', () => {
        // Sources
        addInitialSources(mapInstance, sid, token, true);
        addAdditionalSources(mapInstance);
        addDrawRouteSources(mapInstance);
        addFocusSource(mapInstance);
        // Layers
        addBaseLayers(mapInstance, lid);
        addFocusLayers(mapInstance);
        addDataLines(mapInstance);
        addDataLabels(mapInstance);
        addHomeLines(mapInstance);
        addPositionPointer(mapInstance);

        addDrawRoutesLayers(mapInstance);
        addAdditionalLayers(mapInstance);
    });
    mapInstance.once('render', () => {
        // Check` for position
        const currentBounds = window.localStorage.getItem('currentBounds');
        if (currentBounds) {
            var parsed = JSON.parse(currentBounds);
            if (parsed && parsed.length === 2) {
                mapInstance.fitBounds(parsed);
            }
        }
    });
    mapInstance.on('lineSlice', (p) => {
        const collection = draw.getAll();
        const lines = getLines(collection);
        const points = getPoints(collection);
        const slicePoint = turf.point([p.position.lng, p.position.lat]);
        const newLines = [];
        lines.forEach((line) => {
            const startPoint = turf.point(line.geometry.coordinates[0]);
            const endPoint = turf.point(line.geometry.coordinates[line.geometry.coordinates.length - 1]);
            // Before
            const slicedBefore = turf.lineSlice(startPoint, slicePoint, line);
            let tempUUID = getUUID();
            slicedBefore.properties = {
                ...defaultGeoFeatureProperties.LineString,
                id: tempUUID,
                trailUUID: tempUUID,
                trailName: 'Before',
            };
            newLines.push(slicedBefore);
            // After
            const slicedAfter = turf.lineSlice(slicePoint, endPoint, line);
            tempUUID = getUUID();
            slicedAfter.properties = {
                ...defaultGeoFeatureProperties.LineString,
                id: tempUUID,
                trailUUID: tempUUID,
                trailName: 'After',
            };
            newLines.push(slicedAfter);
        });
        const newCollection = turf.featureCollection([...newLines, ...points]);
        draw.set(newCollection);
    });
    mapInstance.on('click', (e) => {
        const { lng, lat } = e.lngLat;
        onClick([lng, lat]);
    });
    const onMoveEnd = () => {
        const b = mapInstance.getBounds();
        const bounds = [
            [b._sw.lng, b._sw.lat],
            [b._ne.lng, b._ne.lat],
        ];
        window.localStorage.setItem('currentBounds', JSON.stringify(bounds));
    };
    mapInstance.on('moveend', onMoveEnd);
    mapInstance.on('zoomend', onMoveEnd);
    mapInstance.on('boxzoomend', onMoveEnd);
};

export const initialiseClientListeners = (
    mapInstance,
    sid,
    lid,
    token,
    mapLoaded,
    onTrailClicked,
    onTrailHovered,
) => {
    // mapInstance.on('resize', () => {
    //     console.log('resize');
    // });
    mapInstance.on('click', (e) => {
        const features = mapInstance.queryRenderedFeatures(e.point, {
            layers: [`${MapConstants.id.layer.geojsonFocus.line}-4query`]
        });
        onTrailClicked(features);
    });
    mapInstance.on('mousemove', `${MapConstants.id.layer.geojsonFocus.line}-4query`, (e) => {
        clearTimeout(timer);
        const { features } = e;
        timer = setTimeout(() => {
            onTrailHovered(features);
        }, 100);
    });
    mapInstance.on('mouseleave', `${MapConstants.id.layer.geojsonFocus.line}-4query`, () => {
        clearTimeout(timer);
        timer = setTimeout(() => {
            onTrailHovered([]);
        }, 100);
    });
    mapInstance.on('load', () => {
        // Sources
        addInitialSources(mapInstance, sid, token, false);
        // addDrawRouteSources(mapInstance);
        addFocusSource(mapInstance);
        // Layers
        addBaseLayers(mapInstance, lid);
        addFocusLayers(mapInstance);
        addDataLines(mapInstance);
        addDataLabels(mapInstance);
        addHomeLines(mapInstance);
        addPositionPointer(mapInstance);
        mapLoaded();
    });
};

export const initialiseTerrainPreviewListeners = (
    mapInstance,
    sid,
    lid,
    token,
    mapLoaded,
) => {
    mapInstance.on('load', () => {
        // Sources
        addInitialSources(mapInstance, sid, token, false);
        // addDrawRouteSources(mapInstance);
        addFocusSource(mapInstance);
        addTerrainSource(mapInstance);
        // Layers
        addBaseLayers(mapInstance, lid, 'road-label');
        addFocusLayers(mapInstance, 'road-label');
        addDataLines(mapInstance, 'road-label');
        addTerrainLayers(mapInstance);
        add3dDataLabels(mapInstance);
        mapLoaded();
    });
};

const addInitialSources = (map, sid, token, admin = false) => {
    map.addSource(MapConstants.id.source.geojsonPreview, {
        type: 'geojson',
        data: {
            type: 'FeatureCollection',
            features: [],
        },
    });
    map.addSource(MapConstants.id.source.currentPositionMarker, {
        type: 'geojson',
        data: {
            type: 'FeatureCollection',
            features: [],
        },
    });
    map.addSource(MapConstants.id.source.satellite, {
        type: 'raster',
        url: 'mapbox://mapbox.satellite',
        tileSize: 256,
    });
    map.addSource(MapConstants.id.source.mines, {
        type: 'vector',
        url: `mapbox://${sid}`,
    });
    if (admin) {
        map.addSource(MapConstants.id.source.stravaRide, {
            type: 'raster',
            tiles: [
                `https://heatmap-external-a.strava.com/tiles-auth/ride/red/{z}/{x}/{y}.png?Key-Pair-Id=${auth.strava.keyPairId}&Policy=${auth.strava.policy}&Signature=${auth.strava.signature}`,
            ],
            tileSize: 256,
        });
        map.addSource(MapConstants.id.source.stravaRun, {
            type: 'raster',
            tiles: [
                `https://heatmap-external-a.strava.com/tiles-auth/run/blue/{z}/{x}/{y}.png?Key-Pair-Id=${auth.strava.keyPairId}&Policy=${auth.strava.policy}&Signature=${auth.strava.signature}`,
            ],
            tileSize: 256,
        });
    }
};

const addFocusSource = (map) => {
    map.addSource(MapConstants.id.source.geojsonFocus, {
        type: 'geojson',
        data: {
            type: 'FeatureCollection',
            features: [],
        },
    });
};

const addAdditionalSources = (map) => {
    AdditionalLayers.forEach((l) => {
        map.addSource(`additional_source_${l.id}`, {
            type: 'geojson',
            data: `//staze.net/jsons/${l.id}.json`,
        });
    });
};

const addTerrainSource = (map) => {
    map.addSource('mapbox-dem', {
        type: 'raster-dem',
        url: 'mapbox://mapbox.mapbox-terrain-dem-v1',
        tileSize: 512,
        maxzoom: 14,
    });
    map.setTerrain({
        source: 'mapbox-dem',
        exaggeration: 1.5,
    });
    map.setFog({
        range: [0.5, 5],
        color: 'white',
        'horizon-blend': 0.5,
    });
};

const addDrawRouteSources = (map) => {
    map.addSource(MapConstants.id.source.geojsonRoutePreview, {
        type: 'geojson',
        data: {
            type: 'FeatureCollection',
            features: [],
        },
    });
};

const addBaseLayers = (map, lid, placeHolderLayerId = MapConstants.placeHolderLayerId) => {
    // Add satellite layer
    if (placeHolderLayerId === MapConstants.placeHolderLayerId) {
        map.addLayer(
            {
                id: MapConstants.id.layer.satellite,
                type: 'raster',
                source: MapConstants.id.source.satellite,
                paint: {
                    'raster-saturation': 0.5,
                },
                layout: {
                    visibility: 'none',
                },
            },
            placeHolderLayerId,
        );
    }
    // Add mines layer
    map.addLayer(
        {
            id: MapConstants.id.layer.mines,
            type: 'fill',
            source: MapConstants.id.source.mines,
            'source-layer': lid,
            paint: {
                'fill-color': MapConstants.style.minesRed,
                'fill-opacity': 0.3,
            },
            layout: {
                visibility: 'visible',
            },
            minzoom: 10,
        },
        placeHolderLayerId,
    );
};

const addDataLines = (map, placeHolderLayerId = MapConstants.placeHolderLayerId) => {
    const isTopoMap = placeHolderLayerId === MapConstants.placeHolderLayerId;
    // Outline 1
    map.addLayer(
        {
            id: `${MapConstants.id.layer.geojsonPreview.line}-white-outline`,
            type: 'line',
            source: MapConstants.id.source.geojsonPreview,
            metadata: {
                layerSet: 'data',
            },
            paint: {
                'line-color': '#ffffff',
                'line-opacity': isTopoMap ? 0.4 : 0.1,
                'line-width': MapConstants.style.line.width.outlineWide,
            },
            layout: {
                visibility: 'visible',
            },
            filter: ['all', ['!has', 'isHome'], ['==', '$type', 'LineString']],
        },
        placeHolderLayerId,
    );
    //Outline 2
    map.addLayer(
        {
            id: `${MapConstants.id.layer.geojsonPreview.line}-black-outline`,
            type: 'line',
            source: MapConstants.id.source.geojsonPreview,
            metadata: {
                layerSet: 'data',
            },
            paint: {
                // 'line-color': '#ffffff',
                'line-color': '#000000',
                'line-opacity': 0.8,
                'line-width': MapConstants.style.line.width.outlineNormal,
            },
            layout: {
                visibility: 'visible',
            },
            filter: ['all', ['!has', 'isHome'], ['==', '$type', 'LineString']],
        },
        placeHolderLayerId,
    );
    // Under line
    map.addLayer(
        {
            id: `${MapConstants.id.layer.geojsonPreview.line}-white-underline`,
            type: 'line',
            source: MapConstants.id.source.geojsonPreview,
            metadata: {
                layerSet: 'data',
            },
            paint: {
                'line-color': '#ffffff',
                'line-opacity': 1,
                'line-width': MapConstants.style.line.width.dataLineString,
            },
            layout: {
                visibility: 'visible',
            },
            filter: ['all', ['!has', 'isHome'], ['==', '$type', 'LineString']],
        },
        placeHolderLayerId,
    );
    // Add geojson lines
    surfaceTypes.forEach((surfaceType) => {
        map.addLayer(
            {
                id: `${MapConstants.id.layer.geojsonPreview.line}-segment-${surfaceType.id}`,
                type: 'line',
                source: MapConstants.id.source.geojsonPreview,
                metadata: {
                    layerSet: 'data',
                },
                paint: {
                    'line-color': surfaceType.style.color,
                    'line-width': MapConstants.style.line.width.dataLineString,
                    'line-dasharray': surfaceType.style.dasharray,
                },
                layout: {
                    visibility: 'visible',
                },
                filter: [
                    'all',
                    ['==', '$type', 'LineString'],
                    ['has', 'surfaceType'],
                    ['!has', 'isHome'],
                    ['==', 'surfaceType', surfaceType.id],
                ],
            },
            placeHolderLayerId,
        );
    });
};

const addDataLabels = (map, placeHolderLayerId = MapConstants.placeHolderLayerId) => {
    const isTopoMap = placeHolderLayerId === MapConstants.placeHolderLayerId;
    map.addLayer(
        {
            id: `${MapConstants.id.layer.geojsonPreview.symbol}-label`,
            type: 'symbol',
            source: MapConstants.id.source.geojsonPreview,
            metadata: {
                layerSet: 'data',
            },
            paint: {
                'text-halo-color': isTopoMap ? MapConstants.style.labelHalo : MapConstants.style.labelDark,
                'text-color': isTopoMap ? MapConstants.style.labelDark : MapConstants.style.focusOutline,
                'text-halo-width': isTopoMap ? 1 : 2,
                'text-halo-blur': 1,
            },
            layout: {
                'icon-image': [
                    'coalesce',
                    ['image', ['get', 'iconMarker']],
                    ['image', 'circle-stroked-15']
                ],
                'text-anchor': 'top',
                'text-offset': [0, 1],
                'text-field': [
                    'format',
                    ['get', 'name'],
                    { 'font-scale': 1 },
                ],
                'text-size': 14,
                visibility: 'visible',
                // 'text-allow-overlap': true,
                // 'text-ignore-placement': true,
                'icon-allow-overlap': true,
                'icon-ignore-placement': true,
                'symbol-avoid-edges': true,
            },
            filter:  ['==', '$type', 'Point'],
        }
    );
};

const add3dDataLabels = (map) => {
    map.addLayer(
        {
            id: `${MapConstants.id.layer.geojsonPreview.symbol}-3d-label`,
            type: 'symbol',
            source: MapConstants.id.source.geojsonPreview,
            metadata: {
                layerSet: 'data',
            },
            paint: {
                'text-color': '#FF0',
                'text-halo-color': MapConstants.style.labelDark,
                'text-halo-width': 4,
                'text-halo-blur': 4,
            },
            layout: {
                'icon-image': ['image', '2x100'],
                'icon-anchor': 'bottom',
                'icon-allow-overlap': true,
                // 'icon-ignore-placement': true,
                'text-allow-overlap': true,
                // 'text-ignore-placement': true,
                'text-anchor': 'left',
                'text-justify': 'left',
                'text-offset': [0.5, -5.5],
                'text-size': 14,
                'text-field': [
                    'format',
                    ['get', 'name'],
                    { 'font-scale': 1.5 },
                    '\n',
                    {},
                    ['get', 'odometer'],
                    {'text-color': MapConstants.style.labelHalo},
                    ' km',
                    {'text-color': MapConstants.style.labelHalo},
                    '\n',
                    {},
                    ['get', 'elevGain'],
                    {'text-color': MapConstants.style.labelHalo},
                    ' m uspona ',
                    {'text-color': MapConstants.style.labelHalo},
                ],
                visibility: 'visible',
            },
            filter: ['all', ['==', 'elevationProfile', true], ['==', '$type', 'Point']],
        },
    );
};

const addHomeLines = (map) => {
    map.addLayer({
        id: `${MapConstants.id.layer.geojsonPreview.symbol}-home-lines`,
        type: 'line',
        source: MapConstants.id.source.geojsonPreview,
        paint: {
            'line-color': MapConstants.style.homeLinePreview,
            'line-width': 2,
        },
        layout: {
            visibility: 'visible',
        },
        filter: ['all', ['==', '$type', 'LineString'], ['has', 'isHome']],
    });
};

const addPositionPointer = (map) => {
    map.addLayer({
        id: `${MapConstants.id.layer.currentPositionMarker}`,
        type: 'symbol',
        source: MapConstants.id.source.currentPositionMarker,
        paint: {
            'text-color': MapConstants.style.labelDark,
            'text-halo-color': MapConstants.style.labelHalo,
            'text-halo-width': 2,
            'text-halo-blur': 1,
        },
        layout: {
            'icon-image': ['image', 'marker-bike-black'],
            'icon-anchor': 'bottom',
            'icon-allow-overlap': true,
            'icon-ignore-placement': true,
            'text-allow-overlap': true,
            'text-ignore-placement': true,
            'text-anchor': 'left',
            'text-justify': 'left',
            'text-offset': [1.5, -1.5],
            'text-field': [
                'format',
                ['get', 'odometer'],
                { 'font-scale': 1.3 },
                '\n',
                {},
                ['get', 'elevation'],
                { 'font-scale': 1 },
            ],
            'text-size': 11,
            visibility: 'visible',
        },
        filter: ['==', '$type', 'Point'],
    });
};

const addAdditionalLayers = (map) => {
    map.addLayer(
        {
            id: MapConstants.id.layer.stravaRun,
            type: 'raster',
            source: MapConstants.id.source.stravaRun,
            layout: {
                visibility: 'none',
            },
        },
        'road-primary',
    );
    map.addLayer(
        {
            id: MapConstants.id.layer.stravaRide,
            type: 'raster',
            source: MapConstants.id.source.stravaRide,
            layout: {
                visibility: 'none',
            },
        },
        'road-primary',
    );
    AdditionalLayers.forEach((l) => {
        if (l.symbol) {
            map.addLayer(
                {
                    id: `additional_layer_${l.id}_symbol`,
                    type: 'symbol',
                    source: `additional_source_${l.id}`,
                    metadata: {
                        layerSet: l.id,
                    },
                    paint: {
                        'text-color': l.color || MapConstants.style.labelDark,
                        'text-halo-color': MapConstants.style.labelHalo,
                        'text-halo-width': 2,
                        'text-halo-blur': 1,
                    },
                    layout: {
                        'icon-image': l.symbol,
                        'text-anchor': 'top',
                        'text-offset': [0, 1],
                        'text-field': [
                            'format',
                            ['get', 'name'],
                            { 'font-scale': 1 },
                        ],
                        'text-size': 11,
                        visibility: 'none',
                    },
                },
                l.top ? null : 'road-primary',
            );
        }
        if (l.line) {
            map.addLayer(
                {
                    id: `additional_layer_${l.id}_line`,
                    type: 'line',
                    source: `additional_source_${l.id}`,
                    metadata: {
                        layerSet: l.id,
                    },
                    paint: {
                        'line-color': ['get', 'stroke'],
                        'line-opacity': l.opacity || 0.3,
                        'line-width': 4,
                    },
                    layout: {
                        visibility: 'none',
                    }
                },
                'road-primary',
            );
            map.addLayer(
                {
                    id: `additional_layer_${l.id}_line_symbol`,
                    type: 'symbol',
                    source: `additional_source_${l.id}`,
                    metadata: {
                        layerSet: l.id,
                    },
                    paint: {
                        'text-color': l.color || MapConstants.style.labelDark,
                        'text-halo-color': MapConstants.style.labelHalo,
                        'text-halo-width': 2,
                        'text-halo-blur': 1,
                    },
                    layout: {
                        'symbol-placement': 'line',
                        'text-field': [
                            'format',
                            ['get', 'name'],
                            { 'font-scale': 1 },
                        ],
                        'text-size': 11,
                        visibility: 'none',
                    },
                },
                'road-primary',
            );
        }
    });
};

const addDrawRoutesLayers = (map, placeHolderLayerId = MapConstants.placeHolderLayerId) => {
    map.addLayer(
        {
            id: `${MapConstants.id.layer.geojsonRoutePreview.line}-blue-outline`,
            type: 'line',
            source: MapConstants.id.source.geojsonRoutePreview,
            metadata: {
                layerSet: 'draw',
            },
            paint: {
                'line-color': MapConstants.style.drawLinePreview,
                'line-opacity': 0.8,
                'line-width': 6,
            },
            layout: {
                visibility: 'visible',
            },
            filter: ['all', ['has', 'isDraw'], ['==', '$type', 'LineString']],
        },
        placeHolderLayerId,
    );
    map.addLayer(
        {
            id: `${MapConstants.id.layer.geojsonRoutePreview.line}-white-underline`,
            type: 'line',
            source: MapConstants.id.source.geojsonRoutePreview,
            metadata: {
                layerSet: 'draw',
            },
            paint: {
                'line-color': '#ffffff',
                'line-opacity': 1,
                'line-width': 2,
            },
            layout: {
                visibility: 'visible',
            },
            filter: ['all', ['has', 'isDraw'], ['==', '$type', 'LineString']],
        },
        placeHolderLayerId,
    );
    map.addLayer(
        {
            id: `${MapConstants.id.layer.geojsonRoutePreview.symbol}-circle`,
            type: 'circle',
            source: MapConstants.id.source.geojsonRoutePreview,
            metadata: {
                layerSet: 'draw',
            },
            paint: {
                'circle-radius': 10,
                'circle-color': MapConstants.style.drawLinePreview,
            },
            layout: {
                visibility: 'visible',
            },
            filter: ['all', ['has', 'isDraw'], ['==', '$type', 'Point']],
        },
        placeHolderLayerId,
    );
};

const addFocusLayers = (map, placeHolderLayerId = MapConstants.placeHolderLayerId) => {
    // Geojson Preview Outline - filtered by IDs
    map.addLayer(
        {
            id: `${MapConstants.id.layer.geojsonFocus.line}-outline`,
            type: 'line',
            source: MapConstants.id.source.geojsonPreview,
            metadata: {
                layerSet: 'data',
            },
            paint: {
                'line-color': MapConstants.style.focusOutline,
                'line-opacity': 0.5,
                'line-width': 16,
            },
            layout: {
                visibility: 'visible',
            },
            filter: ['all', ['==', '$type', 'LineString'], ['has', 'isHome'], ['in', 'trailUUID', '']],
        },
        placeHolderLayerId,
    );
    // Focus dot
    map.addLayer(
        {
            id: `${MapConstants.id.layer.geojsonFocus.symbol}-focus`,
            type: 'circle',
            source: MapConstants.id.source.geojsonFocus,
            metadata: {
                layerSet: 'data',
            },
            paint: {
                'circle-radius': MapConstants.style.circle.radius.regularFocus,
                'circle-opacity': 0.5,
                'circle-color': MapConstants.style.focusOutline,
            },
            layout: {
                visibility: 'visible',
            },
            filter: ['all', ['==', '$type', 'Point']],
        },
        placeHolderLayerId,
    );
    // Focus line
    map.addLayer(
        {
            id: `${MapConstants.id.layer.geojsonFocus.line}-focus`,
            type: 'line',
            source: MapConstants.id.source.geojsonFocus,
            metadata: {
                layerSet: 'data',
            },
            paint: {
                'line-color': MapConstants.style.homeLinePreview,
                'line-width': 2,
                'line-dasharray': [1, 1],
            },
            layout: {
                visibility: 'visible',
            },
            filter: ['all', ['==', '$type', 'LineString']],
        },
        placeHolderLayerId,
    );
    // Focus catcher
    map.addLayer(
        {
            id: `${MapConstants.id.layer.geojsonFocus.line}-4query`,
            type: 'line',
            source: MapConstants.id.source.geojsonPreview,
            paint: {
                'line-color': MapConstants.style.focusOutline,
                'line-opacity': 0.01,
                'line-width': 16,
            },
            layout: {
                visibility: 'visible',
            },
            filter: ['all', ['==', '$type', 'LineString'], ['has', 'isHome']],
        },
        placeHolderLayerId,
    );
};

const addTerrainLayers = (map) => {
    map.addLayer({
        'id': 'sky',
        'type': 'sky',
        'paint': {
            'sky-type': 'atmosphere',
            'sky-atmosphere-sun': [0.0, 90.0],
            'sky-atmosphere-sun-intensity': 15
        }
    });
};
