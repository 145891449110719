import React from 'react';
import {
    FacebookShareButton,
    FacebookIcon,
    FacebookShareCount,
    TwitterShareButton,
    TwitterIcon,
    LinkedinShareButton,
    LinkedinIcon,
    EmailShareButton,
    EmailIcon,
    ViberShareButton,
    ViberIcon,
    WhatsappShareButton,
    WhatsappIcon,
} from 'react-share';

const SocialNetworkShareItems = (props) => {
    const {
        hashtag,
        twhashtags,
        activeSharingTitle,
        activeSharingDescription,
        via,
        url,
    } = props.setup;
    return (
        <div className="modal__content-container">
            <div className="modal__row-item--narrower">
                <FacebookShareButton
                    url={url}
                    quote={activeSharingTitle}
                    hashtag={hashtag}
                >
                    <FacebookIcon size={32} round={true} />
                    <FacebookShareCount url={url} />
                </FacebookShareButton>
            </div>
            <div className="modal__row-item--narrower">
                <TwitterShareButton
                    url={url}
                    title={activeSharingTitle}
                    hashtags={twhashtags}
                    via={via}
                >
                    <TwitterIcon size={32} round={true} />
                </TwitterShareButton>
            </div>
            <div className="modal__row-item--narrower">
                <LinkedinShareButton url={url} description={activeSharingTitle}>
                    <LinkedinIcon size={32} round={true} />
                </LinkedinShareButton>
            </div>
            <div className="modal__row-item--narrower">
                <EmailShareButton
                    url={url}
                    subject={activeSharingTitle}
                    body={`Pogledajte ${activeSharingTitle} na ${window.location.href}\n\n${activeSharingDescription}`}
                >
                    <EmailIcon size={32} round={true} />
                </EmailShareButton>
            </div>
            <div className="modal__row-item--narrower">
                <ViberShareButton url={url} title={activeSharingTitle}>
                    <ViberIcon size={32} round={true} />
                </ViberShareButton>
            </div>
            <div className="modal__row-item--narrower">
                <WhatsappShareButton url={url} title={activeSharingTitle}>
                    <WhatsappIcon size={32} round={true} />
                </WhatsappShareButton>
            </div>
        </div>
    );
};

export default SocialNetworkShareItems;
