import React, { useEffect, useState } from 'react';
import { getAllTrailsList, getTrailGeoJson } from '../../store/adminActions';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import classNames from 'classnames';
import Fuse from 'fuse.js';

function GeoFileDownload(props) {
    const { initAllTrailsList, trailsList, initCollection } = props;
    const searchOptions = {
        keys: ['name', 'places'],
    };
    const [selectedTrailUUID, setSelectedTrailUUID] = useState(undefined);
    const [searchTerm, setSearchTerm] = useState('');
    const [typeFilter, setTypeFilter] = useState(0);
    const [selectedPlaceUUID, setSelectedPlaceUUID] = useState(0);
    // trals list load
    useEffect(() => {
        if (!trailsList.length) {
            initAllTrailsList();
        }
    }, [trailsList, initAllTrailsList]);
    if (!trailsList || !trailsList.length) {
        return <FormattedMessage id="admin.loadingTrails" />;
    }
    const onSelectTrail = (e) => {
        if (e.target.value) {
            setSelectedTrailUUID(e.target.value);
        }
    };
    const onSetTypeFilter = (e) => {
        if (e.target.value) {
            setTypeFilter(parseInt(e.target.value, 10));
        }
    };
    const onSetSelectedPlaceUUID = (e) => {
        if (e.target.value) {
            setSelectedPlaceUUID(e.target.value);
        }
    };
    const onDownloadInitiated = () => {
        initCollection(selectedTrailUUID);
    };
    const onRefreshList = () => {
        initAllTrailsList();
    };
    let allPlaces = [];
    trailsList.forEach((trail) => {
        trail.placeObjects.forEach((place) => {
            if (!allPlaces.some((p) => p.uuid === place.uuid)) {
                allPlaces.push(place);
            }
        });
    });
    allPlaces = allPlaces.sort((a, b) => a.name - b.name);
    const enrichedTrails = trailsList.map((item) => {
        return {
            ...item,
            places: item.placeObjects.map((item) => item.name).join(','),
        };
    });
    const fuse = new Fuse(enrichedTrails, searchOptions);
    let filteredTrails4edit =
        searchTerm.length > 2
            ? fuse.search(searchTerm).map((e) => e.item)
            : enrichedTrails;
    // Filter by type
    if (typeFilter) {
        filteredTrails4edit = filteredTrails4edit.filter(
            (trail) => trail.status === typeFilter,
        );
    }
    // Filter by place
    if (selectedPlaceUUID) {
        filteredTrails4edit = filteredTrails4edit.filter((trail) =>
            trail.placeObjects.some(
                (place) => place.uuid === selectedPlaceUUID,
            ),
        );
    }
    filteredTrails4edit = filteredTrails4edit.sort(
        (a, b) => b.trailID - a.trailID,
    );
    return (
        <div>
            <div>
                <input
                    type="text"
                    value={searchTerm}
                    onChange={(e) => {
                        setSearchTerm(e.target.value);
                    }}
                />
                <select value={typeFilter} onChange={onSetTypeFilter}>
                    <option key={0} value={0}>
                        All statuses
                    </option>
                    <option key={1} value={1}>
                        Trails
                    </option>
                    <option key={2} value={2}>
                        Segments
                    </option>
                </select>
                <select
                    value={selectedPlaceUUID}
                    onChange={onSetSelectedPlaceUUID}
                >
                    <option key={0} value={0}>
                        All places
                    </option>
                    {allPlaces.map((place) => (
                        <option key={place.uuid} value={place.uuid}>
                            {place.name}
                        </option>
                    ))}
                </select>
                <button
                    onClick={onDownloadInitiated}
                    className={classNames({ inactive: !selectedTrailUUID })}
                >
                    <span>Open</span>
                </button>
                <button
                    onClick={onRefreshList}
                >
                    <span>Refresh</span>
                </button>
            </div>
            <div>
                <select onChange={onSelectTrail} value={selectedTrailUUID}>
                    <option key={'dummy'} value={null}>
                        Pick a trail to edit it
                    </option>
                    {filteredTrails4edit.map((trail) => {
                        const { trailUUID, trailID, name, places } = trail;
                        return (
                            <option key={trailUUID} value={trailUUID}>
                                {`${trailID} - ${name} # (${places})`}
                            </option>
                        );
                    })}
                </select>
            </div>
        </div>
    );
}

const mapStateToProps = (state) => {
    return {
        trailsList: state.adminState.trailsList,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        initCollection: (uuid) => dispatch(getTrailGeoJson(uuid)),
        initAllTrailsList: () => dispatch(getAllTrailsList()),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(GeoFileDownload);
