// eslint-disable-next-line import/no-webpack-loader-syntax
import mapboxgl from '!mapbox-gl';
import MapboxDraw from '@mapbox/mapbox-gl-draw';
import { MapConstants } from '../constants/constants';
import {
    initialiseAdminMapListeners,
    initialiseClientListeners,
    initialiseTerrainPreviewListeners,
} from './mapListeners';
import MapTypeControl from '../components/map/MapTypeControl';
import CollectionControl from '../components/map/CollectionControl';
import { initMapActions } from '../helpers/mapActions';

export const initialiseAdminMap = (
    mapContainer,
    mapInstance,
    { token, sid, lid },
    onClick,
) => {
    mapboxgl.accessToken = token;
    mapInstance = new mapboxgl.Map({
        container: mapContainer,
        style: MapConstants.baseMapStyle,
        center: MapConstants.defaultCenter,
        maxBounds: MapConstants.defaultMaxBBox,
        zoom: MapConstants.defaultZoom,
    });
    const Draw = new MapboxDraw({
        displayControlsDefault: false,
        controls: {
            line_string: true,
            point: true,
            trash: true,
        },
        clickBuffer: 4,
    });
    window.xxx = mapInstance;
    mapInstance.fitBounds(MapConstants.defaultBBox);
    initialiseAdminMapListeners(mapInstance, sid, lid, token, Draw, onClick);
    addInitialControls(mapInstance, Draw, true);
    preloadMapIcons(mapInstance);
    initMapActions(mapInstance, Draw);
};

export const initialiseClientMap = (
    mapContainer,
    mapInstance,
    { token, sid, lid },
    defaultMapPosition,
    mapLoaded,
    onTrailClicked,
    onTrailHovered,
) => {
    mapboxgl.accessToken = token;
    mapInstance = new mapboxgl.Map({
        container: mapContainer,
        style: MapConstants.baseMapStyle,
        center: defaultMapPosition.center,
        maxBounds: MapConstants.defaultMaxBBox,
        zoom: MapConstants.defaultZoom,
    });
    window.xxx = mapInstance;
    mapInstance.fitBounds(defaultMapPosition.bbox);
    initialiseClientListeners(
        mapInstance,
        sid,
        lid,
        token,
        mapLoaded,
        onTrailClicked,
        onTrailHovered,
    );
    addInitialControls(mapInstance, false, false);
    preloadMapIcons(mapInstance);
    initMapActions(mapInstance, false);
};

export const initialiseTerrainPreviewMap = (
    mapContainer,
    mapInstance,
    { token, sid, lid },
    mapLoaded,
    defaultMapPosition,
) => {
    mapboxgl.accessToken = token;
    mapInstance = new mapboxgl.Map({
        container: mapContainer,
        style: MapConstants.satelliteMapStyle,
        center: defaultMapPosition.center,
        maxBounds: MapConstants.defaultMaxBBox,
        zoom: MapConstants.defaultZoom,
    });
    window.xxx = mapInstance;
    mapInstance.fitBounds(defaultMapPosition.bbox);
    preloadMapIcons(mapInstance);
    initialiseTerrainPreviewListeners(
        mapInstance,
        sid,
        lid,
        token,
        mapLoaded,
    );
    addMapNavigationControls(mapInstance);
    initMapActions(mapInstance, false);
};

const addInitialControls = (map, draw, admin = false) => {
    addMapNavigationControls(map);
    // Map type
    map.addControl(new MapTypeControl(map, admin), 'top-left');
    if (draw) {
        map.addControl(new CollectionControl(map), 'top-right');
        // Add Draw
        map.addControl(draw, 'top-right');
    }
};

const addMapNavigationControls = (map) => {
    // Navigation
    map.addControl(
        new mapboxgl.NavigationControl(),
        'top-left',
    );
    // Full screen control
    map.addControl(
        new mapboxgl.FullscreenControl({
            container: document.querySelector('body'),
        }),
        'top-left',
    );
    // Geo locateF
    map.addControl(
        new mapboxgl.GeolocateControl({
            positionOptions: {
                enableHighAccuracy: true
            },
            trackUserLocation: true
        }),
        'top-left',
    );
    // Scale legend
    // map.addControl(
    //     new mapboxgl.ScaleControl({
    //         maxWidth: 150,
    //         unit: 'metric',
    //     }),
    //     'bottom-right',
    // );
};

const preloadMapIcons = (map) => {
    let icons = [
        'marker-bike-amber',
        'marker-bike-cyan',
        'marker-bike-black',
        'bicycle1',
        'bicycle2',
        'city',
        'crossroad',
        'crossroad1',
        'danger',
        'drinking-water',
        'hiking',
        'hiking-black',
        'lodging',
        'pass',
        'photo',
        'photo-inverted',
        'place',
        'restaurant',
        'restaurant-inverted',
        'road',
        'roadblock',
        'summit',
        'swimming',
        '2x100',
    ];
    // icons = [
    //     'bicycle',
    //     'bicycle-share',
    //     'building',
    //     'caution',
    //     'circle',
    //     'circle-stroked',
    //     'city',
    //     'cross',
    //     'danger',
    //     'diamond',
    //     'drinking-water',
    //     'embassy',
    //     'heart',
    //     'highway-rest-area',
    //     'home',
    //     'hospital',
    //     'information',
    //     'lodging',
    //     'marker',
    //     'marker-stroked',
    //     'mountain',
    //     'picnic-site',
    //     'pitch',
    //     'racetrack',
    //     'racetrack-cycling',
    //     'ranger-station',
    //     'restaurant',
    //     'roadblock',
    //     'square',
    //     'square-stroked',
    //     'star',
    //     'star-stroked',
    //     'swimming',
    //     'triangle',
    //     'triangle-stroked',
    //     'village',
    //     'water',
    // ];
    loadSingleIcon(map, icons, 0);
};

const loadSingleIcon = (map, icons, index) => {
    if (icons[index]) {
        // console.log(icons[index]);
        map.loadImage(`/icons/${icons[index]}.png`, (error, image) => {
            if (error) {
                console.warn('Error loading icon ' + icons[index]);
                throw error;
            } else {
                map.addImage(icons[index], image);
                loadSingleIcon(map, icons, index + 1);
            }
        });
    } else {
        console.warn('All ' + icons.length + ' icons loaded');
    }
};
