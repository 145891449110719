import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';

const styles = (theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(2),
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
});

const DialogTitle = withStyles(styles)((props) => {
    const { children, classes, onClose, ...other } = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root} {...other}>
            <Typography variant="h6">{children}</Typography>
            {onClose ? (
                <IconButton
                    aria-label="close"
                    className={classes.closeButton}
                    onClick={onClose}
                >
                    <CloseIcon />
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});

const Modal = (props) => {
    const {
        buttonLabel,
        buttonIcon,
        modalTitle,
        children,
        buttonVariant,
        maxWidth,
    } = props;
    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const button = buttonVariant && buttonVariant === 'icon' ? <IconButton
        color="inherit"
        onClick={handleClickOpen}
    >
        {buttonIcon}
    </IconButton> : <Button
        variant={buttonVariant || 'contained'}
        color="secondary"
        onClick={handleClickOpen}
        startIcon={buttonIcon}
    >
        {buttonLabel}
    </Button>;

    return (
        <>
            {button}
            <Dialog
                onClose={handleClose}
                aria-labelledby="simple-dialog-title"
                open={open}
                fullWidth={true}
                maxWidth={maxWidth || 'sm'}
            >
                <DialogTitle id="simple-dialog-title" onClose={handleClose}>
                    {modalTitle}
                </DialogTitle>
                <DialogContent>{children}</DialogContent>
            </Dialog>
        </>
    );
};

export default Modal;
