import React, { useState } from 'react';
import { connect } from 'react-redux';
import AllTrailsListGrid from '../components/client/AllTrailsListGrid';
import AllTrailsListList from '../components/client/AllTrailsListList';
import AllTrailsListControls from '../components/client/AllTrailsListControls';
import { filterAndSortCollection } from '../helpers/collectionHelper';
import { uiConstants } from '../constants/constants';
import {setListLayout, setSortRule, setTemporaryTrailsSelection} from '../store/presentationActions';
import { getTempTrailSelectionUUIDs } from '../store/presentationSelector';

const AllTrailsLayout = (props) => {
    const {
        collection,
        listLayout,
        setListLayout,
        setSortRule,
        sortRule,
        tempTrailSelectionUUIDs,
        setTemporaryTrailsSelection,
    } = props;
    const [searchTerm, setSearchTerm] = useState(null);
    const onSortAction = (action) => {
        console.log(action);
        setSortRule(action);
    };
    const onLayoutSwitch = (newLayout) => {
        console.log(newLayout);
        setListLayout(newLayout);
    };
    const onSearchAction = (e) => {
        console.log(e.target.value);
        setSearchTerm(e.target.value);
    };
    const onResetTempSelection = () => {
        setTemporaryTrailsSelection([], true);
    };
    let collection4preview = { ...collection };
    collection4preview = filterAndSortCollection(collection4preview, sortRule, tempTrailSelectionUUIDs);
    // TODO: This needs to go via reducer to cover the map as well
    if (collection4preview && searchTerm && searchTerm.length > 2) {
        collection4preview = {
            ...collection4preview,
            features: collection4preview.features.filter((e) =>
                uiConstants.searchTrailsBy.some(
                    (propName) =>
                        e.properties[propName]
                            .toLowerCase()
                            .indexOf(searchTerm.toLowerCase()) > -1,
                ),
            ),
        };
    }
    let list;
    switch (listLayout) {
        case 'grid':
            list = <AllTrailsListGrid collection={collection4preview} />;
            break;
        case 'list':
            list = <AllTrailsListList collection={collection4preview} />;
            break;
        default:
            return null;
    }
    return (
        <div className="content">
            <AllTrailsListControls
                onSortAction={onSortAction}
                onLayoutSwitch={onLayoutSwitch}
                onSearchAction={onSearchAction}
                onResetTempSelection={onResetTempSelection}
                hasTempSelection={tempTrailSelectionUUIDs.length > 0}
                listLayout={listLayout}
            />
            <div className="margin-vertical--half" />
            <div className="content__list">{list}</div>
        </div>
    );
};

const mapStateToProps = (state) => {
    return {
        listLayout: state.presentationState.uiStates.trailListLayout,
        sortRule: state.presentationState.uiStates.trailListSortRule,
        tempTrailSelectionUUIDs: getTempTrailSelectionUUIDs(state),
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        setListLayout: (newLayout) => dispatch(setListLayout(newLayout)),
        setSortRule: (newRule) => dispatch(setSortRule(newRule)),
        setTemporaryTrailsSelection: (trails, mode) => dispatch(setTemporaryTrailsSelection(trails, mode)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(AllTrailsLayout);
