import React from 'react';
import { connect } from 'react-redux';
import Fab from '@material-ui/core/Fab';
import PlayArrowIcon from '@material-ui/icons/PlayArrow';
import PauseIcon from '@material-ui/icons/Pause';
import { togglePlayPreview } from '../../store/presentationActions';

function TrailPreviewLayout(props) {
    const { togglePlayPreview, isPlaying } = props;
    return (
        <div className="app__layout-action-buttons">
            {!isPlaying && <Fab
                color="secondary"
                size="medium"
                className="action-button"
                onClick={togglePlayPreview}
            >
                <PlayArrowIcon />
            </Fab>}
            {isPlaying && <Fab
                color="secondary"
                size="medium"
                className="action-button"
                onClick={togglePlayPreview}
            >
                <PauseIcon />
            </Fab>}
        </div>
    );
}

const mapStateToProps = (state) => {
    return {
        isPlaying: state.presentationState.preview.playing,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        togglePlayPreview: () => dispatch(togglePlayPreview()),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(TrailPreviewLayout);
