export const config = {
    ownerID: '33d7d4b6-0f1b-11e8-ba89-0ed5f89f718b',
    defaultCredits: 'staze.net & mapbox',
    alternateCredits: 'Emir Vučijak',
    photoWaypointsUUID: 'photo-waypoints',
    crossroadsUUID: 'crossroads',
    defaultWpName: 'Raskrsnica',
};

export const UrlContstants = {
    // GET
    // Setup
    getToken: '//staze.net/api/db/gettoken.php',
    getInitialSetup: '//staze.net/api/db/getInitialSetup.php',
    getJson: '//staze.net/api/db/getJson.php?uuid=',
    getTrailStatus: '//staze.net/api/db/getTrailStatus.php?uuid=',
    decodeSlug: '//staze.net/api/db/decodeSlug.php?s=',
    //
    getAllTrails: '//staze.net/api/db/getAllTrails.php',
    getTrailsByStatus: '//staze.net/api/db/getAllTrails.php?status=',
    getAllTrailsCollection: '//staze.net/api/db/getHomeTrails.php',
    getTrailsGroupJson: '//staze.net/api/db/getHomeTrails.php?uuid=',
    getNearestTrails: '//staze.net/api/db/getNearestTrails.php',
    //
    updateTrailProperty: '//staze.net/api/db/updateTrailProperty.php',
    uploadCollection: '//staze.net/api/db/setTrailV2.php',
    publishCollection: '//staze.net/api/file/publishTrail.php',
    purgeTrail: '//staze.net/api/file/purgeTrail.php?uuid=',
    //
    elevation: 'https://api.opentopodata.org/v1/eudem25m?locations=',
    imageUpload: '//staze.net/api/file/upload.php',
    imageRepository: '//staze.net/images/',
    waypointGeneration: '//staze.net/api/file/getWPThumbnail.php',
    trailPreviewGeneration: '//staze.net/api/file/getTrailThumbnail.php',
    maptoolkit: {
        nearestPoint: '//routing.maptoolkit.net/nearest',
        route: 'https://routing.maptoolkit.net/route?vehicle=bike&language=hr',
        apiKey: 'outdoorish111',
    },
    path: {
        trail: 'staza',
        place: 'mjesto',
    },
};

export const MapConstants = {
    // mapStyle: 'mapbox://styles/mapbox/streets-v11',
    baseMapStyle: 'mapbox://styles/mapbox/outdoors-v10',
    satelliteMapStyle: 'mapbox://styles/mapbox/satellite-streets-v11',
    // baseMapStyle: 'mapbox://styles/mapbox-map-design/ckhqrf2tz0dt119ny6azh975y',
    defaultCenter: [17.8, 44.2], // BiH
    defaultZoom: 7,
    defaultPointFocusZoom: 16,
    // defaultBBox: [
    //     [15.9, 42.6],
    //     [19.5, 45.1],
    // ],
    // Trebevic
    defaultBBox: [
        [18.413719405086823, 43.814114635323676],
        [18.49198259478169, 43.839820799686464],
    ],
    defaultMaxBBox: [
        [12, 40],
        [23, 47],
    ],
    bboxPadding: 50,
    placeHolderLayerId: 'housenum-label',
    id: {
        source: {
            satellite: 'satelliteTiles',
            mines: 'mineTiles',
            geojsonPreview: 'geojsonPreview',
            geojsonFocus: 'geojsonFocus',
            geojsonRoutePreview: 'geojsonRoutePreview',
            currentPositionMarker: 'currentPositionMarker',
            rgbTerrain: 'rgbTerrain',
            stravaRun: 'strava-run',
            stravaRide: 'strava-ride',
        },
        layer: {
            satellite: 'satellite',
            rgbTerrain: 'rgbTerrain',
            mines: 'mines-bh',
            currentPositionMarker: 'currentPositionMarker',
            geojsonPreview: {
                line: 'preview-geojson-line',
                fill: 'preview-geojson-fill',
                symbol: 'preview-geojson-symbol',
            },
            geojsonRoutePreview: {
                line: 'preview-geojson-route-line',
                symbol: 'preview-geojson-route-symbol',
            },
            geojsonFocus: {
                line: 'focus-geojson-route-line',
                symbol: 'focus-geojson-route-symbol',
            },
            stravaRun: 'strava-run-raster',
            stravaRide: 'strava-ride-raster',
        },
    },
    style: {
        minesRed: '#ff0000',
        linePreview: '#ff6600',
        homeLinePreview: '#CC0000',
        drawLinePreview: '#0000FF',
        labelDark: '#111111',
        labelHalo: '#ffffff',
        focusOutline: '#ffff99',
        static: {
            primary: {
                stroke: '#FF0',
                'stroke-width': 8,
                'stroke-opacity': 0.6,
            },
            secondary: {
                stroke: '#F00',
                'stroke-width': 8,
                'stroke-opacity': 0.8,
            },
            marker: {
                'marker-size': 'large',
                'marker-symbol': 'cross',
                'marker-color': '#F00',
            },
            arrow: {
                fill: '#F00',
                stroke: '#F00',
                'fill-opacity': 0.8,
            },
        },
        line: {
            width: {
                outlineWide: [
                    'interpolate',
                    ['linear'],
                    ['zoom'],
                    12,
                    16,
                    18,
                    36,
                ],
                outlineNormal: [
                    'interpolate',
                    ['linear'],
                    ['zoom'],
                    12,
                    4,
                    18,
                    14,
                ],
                dataLineString: [
                    'interpolate',
                    ['linear'],
                    ['zoom'],
                    12,
                    2,
                    18,
                    12,
                ],
            },
        },
        circle: {
            radius: {
                regularFocus: [
                    'interpolate',
                    ['linear'],
                    ['zoom'],
                    12,
                    20,
                    18,
                    60,
                ],
            },
        },
    },
};

export const AdditionalLayers = [
    {
        id: 'pois',
        name: 'POIs',
        color: '#2d821e',
        symbol: 'triangle-stroked-15',
    },
    {
        id: 'igman_simplified',
        name: 'Igman',
        color: '#ff00ff',
        symbol: 'circle-stroked-15',
        line: true,
    },
    {
        id: 'bjelasnica_simplified',
        name: 'Bjelasnica',
        color: '#0ab493',
        symbol: 'circle-stroked-15',
        line: true,
    },
    {
        id: 'trener_20210812_simplified',
        name: 'Trener priprema',
        symbol: 'circle-stroked-15',
        opacity: 0.5,
        line: true,
    },
    {
        id: config.crossroadsUUID,
        name: 'Raskrsnice',
        color: '#F00',
        symbol: 'circle-15',
        top: true,
    },
    {
        id: config.photoWaypointsUUID,
        name: 'Fotosi',
        color: '#4d2f89',
        symbol: 'attraction-15',
        line: true,
    },
    {
        id: 'trener_more_2021',
        name: 'Trener priprema za more 2021',
        symbol: 'circle-stroked-15',
        opacity: 0.3,
        line: true,
    },
];

export const layerSets = [
    ...AdditionalLayers,
    {
        id: 'data',
        name: 'Staza'
    },
    {
        id: 'draw',
        name: 'Draw route'
    },
];

export const processingParameters = {
    overrideThumbnails: false,
    absoluteElevationThreshold: 50,
    relativeElevationThreshold: 10,
    elevationReduction: 0.12 / 55,
    slopeTreshlod: 20,
    stickyPointsTreshold: 0.05, // 50m
    snapWPsToPath: false,
    simplify: {
        tolerance: 0.00005,
        highQuality: true,
        mutate: true,
    },
    simplifyLQ: {
        tolerance: 0.0001,
        highQuality: false,
    },
    bezier: {
        resolution: 100000,
        sharpness: 0.85,
    },
    elevate: {
        fetchLimit: 100,
    },
    nearestPointTreshold: 50, // meters
    offRoadPointTreshold: 200, // meters
    placeObjectsGeocodeTreshold: 5, // kilometers km
};

export const editableGeoFeatureProperies = {
    line: [
        { featureKey: 'trailName', label: 'Name', size: 400 },
        { featureKey: 'typeID', label: 'Type', select: 'trailTypes' },
        {
            featureKey: 'trailDesc',
            label: 'Description',
            multiline: true,
            size: 500,
        },
        {
            featureKey: 'trailDescEn',
            label: 'Description',
            multiline: true,
            size: 500,
        },
        { featureKey: 'distance', label: 'Distance[km]', size: 50 },
        { featureKey: 'elevMin', label: 'Min.Elev.', size: 50 },
        { featureKey: 'elevMax', label: 'max.Elev.', size: 50 },
        { featureKey: 'elevGain', label: 'Elev.Gain', size: 50 },
        { featureKey: 'elevLoss', label: 'Elev.Loss', size: 50 },
        { featureKey: 'reviewLandscape', label: 'Landscape', size: 40 },
        { featureKey: 'reviewFun', label: 'Fun', size: 40 },
        {
            featureKey: 'requiredFitness',
            label: 'Fitness',
            select: 'fitnessLevels',
        },
        {
            featureKey: 'requiredTechnique',
            label: 'Technique',
            select: 'techniqueLevels',
        },
        { featureKey: 'externalLink', label: 'External Link', size: 250 },
        { featureKey: 'imageUrl', label: 'Image Url', size: 450 },
        { featureKey: 'imageUrl', label: 'Image Url', imageUploader: true },
        { featureKey: 'imageCredits', label: 'Image Credits', size: 150 },
        {
            featureKey: 'surfaceCollection',
            label: 'Surface',
            size: 250,
            surfaceControl: true,
        },
        {
            featureKey: 'placeObjects',
            label: 'Place Objects',
            size: 250,
            placesControl: true,
        },
    ],
    point: [
        { featureKey: 'name', label: 'Name', size: 200 },
        {
            featureKey: 'name',
            label: 'Name suggestion',
            selectFromData: 'suggestedObjects',
            size: 120,
        },
        { featureKey: 'odometer', label: 'Odometer[km]', size: 50, hideOnPhoto: true },
        {
            featureKey: 'desc',
            label: 'Description BS',
            multiline: true,
            size: 400,
            hideOnPhoto: true,
        },
        {
            featureKey: 'descEn',
            label: 'Description EN',
            multiline: true,
            size: 400,
            hideOnPhoto: true,
        },
        { featureKey: 'symbol', label: 'Symbol', select: 'pointTypes', hideOnPhoto: true },
        { featureKey: 'elevationProfile', label: 'Profile', boolean: true, hideOnPhoto: true },
        { featureKey: 'pictogram', label: 'Pictogram', size: 200, hideOnPhoto: true },
        { featureKey: 'imageCredits', label: 'Image Credits', size: 200 },
        { featureKey: 'imageUrl', label: 'Image Url', imageUploader: true },
    ],
    crossroad: [
        { featureKey: 'name', label: 'Name', size: 200 },
        {
            featureKey: 'name',
            label: 'Name suggestion',
            selectFromData: 'suggestedObjects',
            size: 120,
        },
    ],
};

export const defaultGeoFeatureProperties = {
    LineString: {
        ownerID: config.ownerID,
        trailUUID: null,
        publishStatus: 0,
        trailSlug: '',
        trailName: ' ', // 'Mostar - Brasina - Gnojnice - Guberaca'
        trailDesc: '', // 'CrossCountry staza'
        trailNameEn: '',
        trailDescEn: '',
        typeID: 2,
        distance: 0,
        elevMin: 9999,
        elevMax: 0,
        elevGain: 0,
        elevLoss: 0,
        surfaceCollection: [{ key: 0, value: 'A' }],
        reviewLandscape: 1,
        reviewFun: 1,
        requiredFitness: 1,
        requiredTechnique: 1,
        center: [],
        bounds: [],
        fileName: '', // 'Mostar - Brasina - Gnojnice - Guberaca.kml'
        externalLink: '//www.staze.net/', // 'http://www.mtb.ba/velez/'
        imageUrl: '', // 'http://www.staze.net/images/trails/89e873ab-3d14-4202-9041-b8f6371b5681/78e0c28b-f86b-439a-83fc-6ac87f54a79e.jpg',
        imageCredits: config.defaultCredits,
        placeObjects: [],
        start: 'POINT(0 0)',
        end: 'POINT(0 0)',
    },
    Point: {
        id: 0,
        name: '', // 'Polazna tačka',
        nameEn: '', // 'Polazna tačka',
        desc: '', // 'Generisani opis',
        descEn: '',
        elevGain: 0,
        elevLoss: 0,
        nextElevGain: 0,
        nextElevLoss: 0,
        odometer: 0,
        nextStepDist: 0,
        bearing: 0,
        time: 0,
        symbol: 'CROSSROAD', // 'START',
        iconMarker: 'circle-11', // 'bicycle2',
        pictogram: '90', // '90',
        imageUrl: '', // 'url...',
        imageCredits: config.defaultCredits,
        elevationProfile: true, // true,
    },
    Collection: {
        type: 'FeatureCollection',
        features: [],
    },
};

export const surfaceTypes = [
    {
        id: 'A',
        name: 'Asfalt, nabijeni makadam',
        style: {
            color: '#FFFF00',
            dasharray: [1, 0],
        },
        trail: {
            width: 'Šire od 1.8m',
            surface: 'Asfaltna ili nabijena ravna podloga',
            avgGrade: 'Manje od 5%',
            maxGrade: 'Najviše 10%',
            obstacles: ['Bez prepreka'],
        },
    },
    {
        id: 'M',
        name: 'Makadam, kolski put',
        style: {
            color: '#ff8800',
            dasharray: [1, 0],
        },
        trail: {
            width: 'Šire od 1.2m',
            surface: 'Tvrda i stabilna podloga',
            avgGrade: '5% ili manje',
            maxGrade: 'Najviše 15%',
            obstacles: [
                'Neizbježne poprečne prepreke visine do 5cm',
                'Moguće dodatne prepreke',
                'Mogući mostovi i prelazi širi od 90cm',
            ],
        },
    },
    {
        id: 'S',
        name: 'Uglavnom utabana staza',
        style: {
            color: '#FF0000',
            dasharray: [1, 0],
        },
        trail: {
            width: 'Šire od 60cm',
            surface: 'Uglavnom tvrda i stabilna',
            avgGrade: '10% ili manje',
            maxGrade: '15% ili više',
            obstacles: [
                'Neizbježne poprečne prepreke visine do 20cm',
                'Moguće dodatne prepreke',
                'Mogući mostovi i prelazi širi od 60cm',
                'Tehničke elementi visine do 60cm i širine preko 30cm (premostivi)',
            ],
        },
    },
    {
        id: 'P',
        name: 'Tehnički zahtjevna (planinarska) staza',
        style: {
            color: '#FF0000',
            dasharray: [2, 2],
        },
        trail: {
            width: 'Šire od 30cm',
            surface: 'Uglavnom rastresita',
            avgGrade: '15% ili manje',
            maxGrade: '20% ili više',
            obstacles: [
                'Neizbježne poprečne prepreke visine do 40cm',
                'Moguće dodatne prepreke i rastresito kamenje',
                'Mogući mostovi i prelazi širi od 60cm',
                'Tehničke elementi visine do 1m i širine manje od 50cm - teško premostivi',
                'Kratke sekcije mogu biti i tehnički zahtjevnije',
            ],
        },
    },
    {
        id: 'N',
        name: '(Ne)prohodna staza',
        style: {
            color: '#660066',
            dasharray: [2, 2],
        },
        trail: {
            width: 'Šire od 15cm',
            surface: 'Uglavnom rastresita',
            avgGrade: '20% ili više',
            maxGrade: '15% ili više',
            obstacles: [
                'Neizbježne poprečne prepreke visine do 40cm',
                'Moguće dodatne prepreke i rastresito kamenje',
                'Mogući mostovi i prelazi uži od 60cm',
                'Tehničke elementi visine do 1m i širine manje od 50cm - teško premostivi',
                'Mnoge sekcije mogu biti i tehnički zahtjevnije',
            ],
        },
    },
];

export const uiConstants = {
    sidebarContentID: 'sidebar-content',
    trailListLayout: {
        grid: 'grid',
        list: 'list',
    },
    sortRules: [
        {
            label: 'Naziv staze A->Z',
            featureKey: 'name',
            ascending: true,
        },
        {
            label: 'Naziv staze Z->A',
            featureKey: 'name',
            ascending: false,
        },
        { separator: true },
        {
            label: 'Kraća->duža staza',
            featureKey: 'distance',
            ascending: true,
        },
        {
            label: 'Duža->kraća staza',
            featureKey: 'distance',
            ascending: false,
        },
        { separator: true },
        {
            label: 'Manji->veći uspon',
            featureKey: 'elevGain',
            ascending: true,
        },
        {
            label: 'Veći->manji uspon',
            featureKey: 'elevGain',
            ascending: false,
        },
    ],
    searchTrailsBy: ['name'],
};
