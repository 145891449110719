// import blue from '@material-ui/core/colors/blue';
// import indigo from '@material-ui/core/colors/indigo';
//
// export default {
//     palette: {
//         primary: blue,
//         secondary: indigo,
//     },
// };

export default {
    palette: {
        primary: {
            main: '#33F',
        },
        secondary: {
            main: '#006',
        },
    },
};
