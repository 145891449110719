import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { getTokenSetup } from '../../store/adminSelector';
import {
    getHomeCollection,
    getNextMapAction,
    getTrailCollection,
    getTrailGroupCollection
} from '../../store/presentationSelector';
import { fetchTokenSetup, fetchInitialSetup } from '../../store/adminActions';
import { setMapLoaded, setTemporaryTrailsSelection } from '../../store/presentationActions';
import { initialiseClientMap } from '../../helpers/mapHelper';
import { executeMapAction } from '../../helpers/mapActions';
// import { getTrailLink } from '../../helpers/commonHelper';
import { getCollectionCenter, getCollectionBounds } from '../../helpers/mapToolsHelper';

let mapInstance;

function ClientMap(props) {
    const history = useHistory();
    const { tokenSetup, mapAction, initSetup, setMapLoaded, mapLoaded, collectionSet, setType, setTemporaryTrailsSelection } = props;
    const collection = collectionSet[setType];
    const defaultMapPosition = collection ? {
        bbox: getCollectionBounds(collection),
        center: getCollectionCenter(collection).geometry.coordinates,
    } : null;
    let mapContainer = undefined;
    // Init token
    useEffect(() => {
        if (!tokenSetup) {
            initSetup();
        }
    }, [tokenSetup, initSetup]);
    // Init map
    useEffect(() => {
        if (tokenSetup && defaultMapPosition && !mapLoaded) {
            initialiseClientMap(
                mapContainer,
                mapInstance,
                tokenSetup,
                defaultMapPosition,
                () => {
                    setMapLoaded();
                },
                (trails) => {
                    // history.push(getTrailLink(trail));
                    setTemporaryTrailsSelection(trails, true);
                },
                (trails) => {
                    // history.push(getTrailLink(trail));
                    setTemporaryTrailsSelection(trails);
                },
            );
        }
    }, [tokenSetup, mapContainer, setMapLoaded, mapLoaded, history, defaultMapPosition, setTemporaryTrailsSelection]);
    // Action dependent
    useEffect(() => {
        if (mapLoaded && mapAction) {
            executeMapAction(mapAction);
        }
    }, [mapAction, mapLoaded]);
    return (
        <div
            className="map-container"
            id="client-map"
            ref={(el) => (mapContainer = el)}
        />
    );
}

const mapStateToProps = (state) => {
    return {
        mapLoaded: state.presentationState.mapLoaded,
        tokenSetup: getTokenSetup(state),
        mapAction: getNextMapAction(state),
        collectionSet: {
            trail: getTrailCollection(state),
            group: getTrailGroupCollection(state),
            home: getHomeCollection(state),
        },
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        initSetup: () => {
            dispatch(fetchTokenSetup());
            dispatch(fetchInitialSetup());
        },
        setMapLoaded: () => dispatch(setMapLoaded()),
        setTemporaryTrailsSelection: (trails, mode) => dispatch(setTemporaryTrailsSelection(trails, mode)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ClientMap);
