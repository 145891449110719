import React from 'react';
import { connect } from 'react-redux';
import classNames from 'classnames';
import Map from '../components/map/TerrainPreviewMap';
import SplashScreen from '../components/client/SplashScreen';
import HeadToolbar from '../components/client/HeadToolbar';
import TrailPreviewControls from '../components/client/TrailPreviewControls';

function TrailPreviewLayout(props) {
    const { mobileLayout } = props;
    return (
        <>
            <div className="app">
                <div className="app__header">
                    <HeadToolbar />
                </div>
                <div className="app__content">
                    <div className={classNames('app__map', { hideOnMobile: !mobileLayout.map })}>
                        <div className="map-grid">
                            <Map />
                            {props.children}
                        </div>
                    </div>
                </div>
                <TrailPreviewControls />
            </div>
            <SplashScreen />
        </>
    );
}

const mapStateToProps = (state) => {
    return {
        mobileLayout: state.presentationState.mobileLayout,
    };
};

const mapDispatchToProps = () => {
    return {
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(TrailPreviewLayout);
