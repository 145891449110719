import React from 'react';
import classNames from 'classnames';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import Map from '../components/map/ClientMap';
import SplashScreen from '../components/client/SplashScreen';
import HeadToolbar from '../components/client/HeadToolbar';
import Fab from '@material-ui/core/Fab';
import HomeIcon from '@material-ui/icons/Home';
import MapIcon from '@material-ui/icons/Map';
import PlaceIcon from '@material-ui/icons/Place';
import {
    getHomeTrails,
    setMobileLayout,
    showHomeGeoJsonOnMap,
} from '../store/presentationActions';
import { connect } from 'react-redux';
import Legend from '../components/map/Legend';
import { uiConstants, UrlContstants} from '../constants/constants';

const useStyles = makeStyles((theme) => ({
    homeIcon: {
        zIndex: 1,
    },
}));

function ClientLayout(props) {
    const classes = useStyles();
    const history = useHistory();
    const params = useParams();
    const location = useLocation();
    const { initCollection, previewCollection, setMobileLayout, mobileLayout, setType } = props;
    const shouldRenderHomeButton = !!params.uuid || !!params.slug;
    const shouldRenderLegend =
        location.pathname.indexOf(`/${UrlContstants.path.trail}`) === 0;
    return (
        <>
            <div className="app">
                <div className="app__header mobile-only">
                    <HeadToolbar />
                </div>
                <div className="app__content">
                    <div className={classNames('app__map', { hideOnMobile: !mobileLayout.map })}>
                        <div className="map-grid">
                            <Map setType={setType}/>
                            <div className="legend">
                                {shouldRenderLegend && <Legend />}
                            </div>
                            <div className="map-grid__top-right">
                                {shouldRenderHomeButton && (
                                    <Fab
                                        color="secondary"
                                        size="medium"
                                        className={classes.homeIcon}
                                        onClick={() => {
                                            history.push('/');
                                            initCollection();
                                            previewCollection();
                                        }}
                                    >
                                        <HomeIcon />
                                    </Fab>
                                )}
                            </div>
                        </div>
                    </div>
                    <div className={classNames('app__sidebar', { hideOnMobile: !mobileLayout.details })}>
                        <div className="app__sidebar-header desktop-only">
                            <HeadToolbar />
                        </div>
                        <div
                            id={uiConstants.sidebarContentID}
                            className="app__sidebar-content"
                        >
                            {props.children}
                        </div>
                    </div>
                </div>
                <div className="app__layout-action-buttons">
                    <Fab
                        color="secondary"
                        size="medium"
                        className={classNames('action-button', { hideOnMobile: !mobileLayout.details })}
                        onClick={() => {
                            setMobileLayout({
                                map: true,
                                details: false,
                                chart: false,
                            });
                        }}
                    >
                        <MapIcon />
                    </Fab>
                    <Fab
                        color="secondary"
                        size="medium"
                        className={classNames('action-button', { hideOnMobile: !mobileLayout.map })}
                        onClick={() => {
                            setMobileLayout({
                                map: false,
                                details: true,
                                chart: false,
                            });
                        }}
                    >
                        <PlaceIcon />
                    </Fab>
                </div>
            </div>
            <SplashScreen />
        </>
    );
}

const mapStateToProps = (state) => {
    return {
        mobileLayout: state.presentationState.mobileLayout,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        setMobileLayout: (layout) => dispatch(setMobileLayout(layout)),
        initCollection: () => dispatch(getHomeTrails()),
        previewCollection: () => dispatch(showHomeGeoJsonOnMap()),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ClientLayout);
