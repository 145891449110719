/* globals Highcharts */
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { getActiveCollection } from '../../store/adminSelector';
import { pickCurrentPositionOdometer } from '../../store/adminActions';
import { getChartSetup4Line } from '../../helpers/elevationChartHelper';

function AdminChartContainer(props) {
    const { id, activeCollection, pickCurrentOdometer } = props;
    const onChartClick = (odometer) => {
        pickCurrentOdometer(odometer);
    };
    useEffect(() => {
        const newSetup = getChartSetup4Line(activeCollection, id, onChartClick);
        new Highcharts.Chart(id, newSetup);
    });
    return React.createElement('div', { id });
}

const mapStateToProps = (state) => {
    return {
        activeCollection: getActiveCollection(state),
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        pickCurrentOdometer: (odometer) =>
            dispatch(pickCurrentPositionOdometer(odometer)),
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(AdminChartContainer);
