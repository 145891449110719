import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { surfaceTypes } from '../../constants/constants';

function SurfaceCollectionEditor(props) {
    const { value, onUpdate, odometer } = props;
    let keyValue, valueValue;
    const onAddItem = () => {
        if (!!keyValue.value.length && !!valueValue.value.length) {
            let newValue = [
                ...value.filter((k) => k.key !== parseFloat(keyValue.value)),
            ];
            newValue.push({
                key: parseFloat(keyValue.value),
                value: valueValue.value,
            });
            newValue = newValue.sort((a, b) => a.key - b.key);
            keyValue.value = null;
            valueValue.value = null;
            onUpdate(newValue);
        }
    };
    const removeSegment = (key) => {
        const newValue = [...value.filter((k) => k.key !== key)];
        keyValue.value = null;
        valueValue.value = null;
        onUpdate(newValue);
    };
    useEffect(() => {
        keyValue.value = odometer;
    }, [odometer, keyValue]);
    return (
        <div className="feature__surface-collection">
            {value.map((item) => {
                const color = surfaceTypes.find((s) => s.id === item.value)
                    .style.color;
                return (
                    <span
                        className="surface__item"
                        style={{ backgroundColor: color }}
                        key={item.key}
                    >
                        <span className="surface__key">{item.key}</span>
                        <span className="surface__value">{item.value}</span>
                        <button
                            onClick={() => {
                                removeSegment(item.key);
                            }}
                        >
                            <span className="material-icons">
                                remove_circle_outline
                            </span>
                        </button>
                    </span>
                );
            })}
            <input
                name="key"
                type="number"
                style={{ width: 40 }}
                ref={(el) => (keyValue = el)}
            />
            <select
                name="value"
                style={{ width: 70 }}
                ref={(el) => (valueValue = el)}
            >
                {surfaceTypes.map((type) => (
                    <option key={type.id} value={type.id}>
                        {type.name}
                    </option>
                ))}
            </select>
            <button onClick={onAddItem}>
                <span className="material-icons">add_circle_outline</span>
            </button>
        </div>
    );
}

const mapStateToProps = (state) => {
    return {
        odometer: state.adminState.current.odometer,
    };
};

const mapDispatchToProps = () => {
    return {};
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(SurfaceCollectionEditor);
