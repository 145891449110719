import React, { useState } from 'react';
import { getLines, getPoints, isPhotoCollection, isCrossroadCollection } from '../../helpers/collectionHelper';
import {
    getActiveCollection,
    getInitialSetup,
} from '../../store/adminSelector';
import { connect } from 'react-redux';
import EditProperty from '../base/EditProperty';
import { editableGeoFeatureProperies, config } from '../../constants/constants';
import {
    initiateActiveCollectionPreview,
    updateActiveCollection,
    focusFeatureOnMap,
    removeFeatureOnMap,
} from '../../store/adminActions';
import { AppAction } from '../../constants/actions';
import ChartContainer from '../base/AdminChartContainer';

function CollectionFeaturesPreview(props) {
    const {
        collection,
        initialSetup,
        updateActiveCollection,
        focusFeatureOnMap,
        removeFeatureOnMap,
    } = props;
    const [crossroadsOnly, setCrossroadsOnly] = useState(false);
    if (!collection) {
        return null;
    }
    const lines = getLines(collection);
    const points = getPoints(collection).sort(
        (a, b) => a.properties.odometer - b.properties.odometer,
    );
    const filteredPoints = crossroadsOnly ? points.filter((p) => p.properties.name === config.defaultWpName) : points;
    const onFeatureUpdate = (featureId, featurekey, featureValue) => {
        const existingFeatures = [...collection.features];
        const existingFeature = existingFeatures.find(
            (f) => f.properties.id === featureId,
        );
        existingFeature.properties[featurekey] = featureValue;
        const newCollection = {
            ...collection,
            features: existingFeatures,
        };
        updateActiveCollection(newCollection);
    };
    const renderFeature = (feature, featureProperties, onFeatureUpdate) => {
        const { properties } = feature;
        return (
            <div className="feature__container">
                {featureProperties.map((f, i) => {
                    if (isPhotoCollection(collection) && f.hideOnPhoto) return null;
                    return <EditProperty
                        {...f}
                        key={i}
                        storeValue={properties[f.featureKey]}
                        properties={properties}
                        initialSetup={initialSetup}
                        onUpdate={onFeatureUpdate}
                        isPhotoCollection={isPhotoCollection(collection)}
                    />;
                })}
            </div>
        );
    };
    return (
        <div className="sidebar-feature__container">
            <div className="sidebar-feature__row">
                <h3>Lines {lines.length}</h3>
            </div>
            {lines.map((l) => (
                <div className="sidebar-feature__column" key={l.properties.id}>
                    {renderFeature(
                        l,
                        editableGeoFeatureProperies.line,
                        onFeatureUpdate,
                    )}
                    <div className="feature__container">
                        <ChartContainer id={l.properties.id} />
                    </div>
                    <button
                        onClick={() => {
                            removeFeatureOnMap(l);
                        }}
                    >
                        Remove line
                    </button>
                </div>
            ))}
            <div className="sidebar-feature__row">
                <h3>Points {filteredPoints.length}</h3>
                <input
                    name="crossroadsOnly"
                    type="checkbox"
                    value={crossroadsOnly}
                    checked={crossroadsOnly}
                    onChange={() => setCrossroadsOnly(!crossroadsOnly)}
                />
                <label htmlFor="crossroadsOnly">Crossroads only</label>
            </div>
            {filteredPoints.map((p) => (
                <div className="sidebar-feature__row" key={p.properties.id}>
                    {renderFeature(
                        p,
                        isCrossroadCollection(collection) ?  editableGeoFeatureProperies.crossroad : editableGeoFeatureProperies.point,
                        onFeatureUpdate,
                    )}
                    <div
                        className="feature__pictogram"
                    >
                        {!isCrossroadCollection(collection) && <img
                            src={isPhotoCollection(collection) ? p.properties.imageUrl : `//www.staze.net/images/svg/getsvg.php?opis=${p.properties.pictogram}`}
                            width="100"
                            alt={p.properties.pictogram}
                        />}
                        <button
                            onClick={() => {
                                focusFeatureOnMap(p);
                            }}
                        >
                            Foc
                        </button>
                        <button
                            onClick={() => {
                                removeFeatureOnMap(p);
                            }}
                        >
                            Del
                        </button>
                    </div>
                </div>
            ))}
        </div>
    );
}

const mapStateToProps = (state) => {
    return {
        collection: getActiveCollection(state),
        initialSetup: getInitialSetup(state),
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        removeFeatureOnMap: (feature) => {
            dispatch(removeFeatureOnMap(feature));
            dispatch(initiateActiveCollectionPreview());
        },
        focusFeatureOnMap: (feature) => dispatch(focusFeatureOnMap(feature)),
        updateActiveCollection: (collection) => {
            dispatch(
                updateActiveCollection({
                    collection,
                    action: AppAction.DATA_UPDATE,
                }),
            );
            dispatch(initiateActiveCollectionPreview());
        },
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(CollectionFeaturesPreview);
