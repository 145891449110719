/* global turf */
/* global polyline */
import { getPoints, getLines } from './collectionHelper';
import { processingParameters, MapConstants } from '../constants/constants';

export const getRegionsAndMountains = (collection, state) => {
    if (!collection || !collection.features.length || !state.pois) {
        return [];
    }
    const points = getPoints(collection);
    const lines = getLines(collection);
    lines.forEach((line) => {
        const firstPoint = turf.point(line.geometry.coordinates[0]);
        points.push(firstPoint);
        if (line.geometry.coordinates.length > 1) {
            const lastPoint = turf.point(
                line.geometry.coordinates[line.geometry.coordinates.length - 1],
            );
            points.push(lastPoint);
        }
    });
    const cities = state.pois.gradovi;
    const mountains = state.pois.planine;
    const ids = [];
    const placeObjects = [];
    points.forEach((wpoint) => {
        // mountains
        mountains.features.forEach((region) => {
            if (turf.booleanPointInPolygon(wpoint, region)) {
                if (ids.indexOf(region.properties.uuid) < 0) {
                    ids.push(region.properties.uuid);
                    placeObjects.push(region.properties);
                }
            }
        });
        // places
        cities.features.forEach((point) => {
            if (
                turf.distance(wpoint, point) <
                processingParameters.placeObjectsGeocodeTreshold
            ) {
                if (ids.indexOf(point.properties.uuid) < 0) {
                    ids.push(point.properties.uuid);
                    placeObjects.push(point.properties);
                }
            }
        });
    });
    return placeObjects;
};

export const geoCodePoints = (collection, state) => {
    const bbox = turf.bbox(collection);
    const extendedBBox = extendBBox(bbox, 0.3);
    const bboxPolygon = turf.bboxPolygon(extendedBBox);
    const points = getPoints(collection);
    const { crossroads, pois } = state.pois;
    const toponimes = turf.featureCollection([...pois.features, ...crossroads.features, ...state.pois['photo-waypoints'].features]);
    const reducedToponimes = turf.pointsWithinPolygon(toponimes, bboxPolygon);
    points.forEach((wpoint) => {
        if (
            !wpoint.properties.suggestedObjects ||
            wpoint.properties.suggestedObjects.length > -1
        ) {
            const suggestedNames = [];
            const suggestedObjects = [
                {
                    key: `${wpoint.properties.name}-0`,
                    id: wpoint.properties.name,
                    name: wpoint.properties.name,
                    distance: 0,
                },
            ];
            reducedToponimes.features.forEach((poi) => {
                const distance = turf.distance(wpoint, poi);
                if (distance < 0.5) {
                    if (suggestedNames.indexOf(poi.properties.name) < 0) {
                        suggestedNames.push(poi.properties.name);
                        suggestedObjects.push({
                            key: `${poi.properties.name}-${distance}`,
                            id: poi.properties.name,
                            name: `${poi.properties.name} - ${distance.toFixed(
                                2,
                            )} km`,
                            distance,
                        });
                    }
                }
            });
            wpoint.properties.suggestedObjects = suggestedObjects.sort(
                (a, b) => a.distance - b.distance,
            );
        }
    });
};

export const assignGeoPoints2Line = (collection, state) => {
    const bbox = turf.bbox(collection);
    const extendedBBox = extendBBox(bbox, 0.3);
    const bboxPolygon = turf.bboxPolygon(extendedBBox);
    const lines = getLines(collection);
    // const points = getPoints(collection);
    const newPoints = [];
    const { crossroads } = state.pois;
    const toponimes = turf.featureCollection([...crossroads.features, ...state.pois['photo-waypoints'].features]);
    const reducedToponimes = turf.pointsWithinPolygon(toponimes, bboxPolygon);
    lines.forEach((line) => {
        const { coordinates } = line.geometry;
        const firstPoint = turf.point(coordinates[0]);
        const lastPoint = turf.point(coordinates[coordinates.length - 1]);
        newPoints.push(firstPoint);
        newPoints.push(lastPoint);
        reducedToponimes.features.forEach((poi) => {
            const distance = turf.pointToLineDistance(poi, line);
            if (distance < 0.05) {
                const newPoint = {
                    ...poi,
                };
                newPoints.push(newPoint);
            }
        });
    });
    return newPoints;
};

const extendBBox = (bbox, multiplier = 1) => {
    const [minX, minY, maxX, maxY] = bbox;
    const deltaX = (maxX - minX) * multiplier;
    const deltaY = (maxY - minY) * multiplier;
    return [minX - deltaX, minY - deltaY, maxX + deltaX, maxY + deltaY];
};

export const minimizeCoordinates = (coordinates) => {
    const newCoordinates = [...coordinates];
    return newCoordinates.map((coordinate) => minimizeCoordinate(coordinate));
};

export const minimizeCoordinate = (coordinate) => {
    return coordinate.map((dimension) =>
        typeof dimension === 'number'
            ? parseFloat(dimension.toFixed(5))
            : minimizeCoordinate(dimension),
    );
};

export const getSimplifiedCollection = (collection) => {
    const points = getPoints(collection);
    const lines = getLines(collection).map((line) =>
        turf.simplify(line, processingParameters.simplify),
    );
    return {
        ...collection,
        features: [...lines, ...points],
    };
};

export const stickPoints2Lines = (collection) => {
    let points = [...getPoints(collection)];
    const lines = [...getLines(collection)];
    lines.forEach((line) => {
        const { id } = line.properties;
        points.forEach((point) => {
            const pointOnLine = turf.nearestPointOnLine(line, point, {
                units: 'kilometers',
            });
            const { dist, index, location } = pointOnLine.properties;
            if (dist < processingParameters.stickyPointsTreshold) {
                point.properties.parent = {
                    id,
                    index,
                    dist,
                    location,
                };
                point.geometry = {
                    ...point.geometry,
                };
                point.properties.odometer = location;
                // point.properties.elevationProfile = true;
            } else {
                point.properties.elevationProfile = false;
            }
        });
    });
    points = points.sort(
        (a, b) => a.properties.odometer <= b.properties.odometer,
    );
    return {
        ...collection,
        features: [...lines, ...points],
    };
};

export const nivelateBearing = (b) => {
    if (b < -180) {
        return b + 180;
    }
    if (b > 180) {
        return b - 180;
    }
    return b;
};

const getRotadedPoly = (currentIndex, lineCoordinates) => {
    if (!lineCoordinates[currentIndex - 1] || !lineCoordinates[currentIndex]) {
        return null;
    }
    const pointA = turf.point(lineCoordinates[currentIndex - 1]);
    const pointB = turf.point(lineCoordinates[currentIndex]);
    let bearingAB = nivelateBearing(turf.bearing(pointA, pointB) + 90);
    bearingAB = turf.bearing(pointA, pointB) - 90;
    const arrowPoly = getArrow(lineCoordinates[currentIndex]);
    const rotatedPoly = turf.transformRotate(arrowPoly, bearingAB, {
        pivot: pointB.geometry.coordinates,
    });
    return rotatedPoly;
};

const getArrow = (c) => {
    const style = MapConstants.style.static;
    const x = 0.0005;
    const y = 0.0002;
    const p1 = [...c];
    const p2 = [c[0] - x, c[1] + y];
    const p3 = [c[0] - x, c[1] - y];
    const p4 = [...c];
    return turf.polygon([[p1, p2, p3, p4]], style.arrow);
};

export const generateWPointGeoJSON = (collection) => {
    let points = [...getPoints(collection)];
    let lines = [...getLines(collection)];
    const style = MapConstants.style.static;
    points.forEach((point) => {
        const { parent } = point.properties;
        if (parent && parent.id) {
            const line = lines.find((l) => l.properties.id === parent.id);
            let { index } = parent;
            // Steps from actual cross road
            index += 0;
            const offset = 15;
            const features = [];
            const pointFromIndex = index - offset < 0 ? 0 : index - offset;
            const pointToIndex =
                index + offset > line.geometry.coordinates.length - 1
                    ? line.geometry.coordinates.length - 1
                    : index + offset;

            // // IN
            // const inPathCoordinates = [];
            // for (let i = pointFromIndex; i <= index; i++) {
            //     inPathCoordinates.push(line.geometry.coordinates[i]);
            // }
            // if (inPathCoordinates.length > 1) {
            //     features.push(
            //         turf.lineString(inPathCoordinates, style.primary),
            //     );
            // }

            // // OUT
            // const outPathCoordinates = [];
            // for (let j = index; j <= pointToIndex; j++) {
            //     outPathCoordinates.push(line.geometry.coordinates[j]);
            // }
            // if (outPathCoordinates.length > 1) {
            //     features.push(
            //         turf.lineString(outPathCoordinates, style.secondary),
            //     );
            // }

            // ALL
            const allPathCoordinates = [];
            for (let z = pointFromIndex; z <= pointToIndex; z++) {
                allPathCoordinates.push(line.geometry.coordinates[z]);
            }
            if (allPathCoordinates.length > 1) {
                features.push(
                    turf.lineString(allPathCoordinates, style.primary),
                );
            }

            // TURN
            const turnPathCoordinates = [];
            for (let q = Math.max(index - 2, 0); q <= index + 3; q++) {
                if (line.geometry.coordinates[q]) {
                    turnPathCoordinates.push(line.geometry.coordinates[q]);
                }
            }
            if (turnPathCoordinates.length > 1) {
                features.push(
                    turf.lineString(turnPathCoordinates, style.secondary),
                );
            }

            // ARROW
            const rotatedPoly = getRotadedPoly(
                index + 3,
                line.geometry.coordinates,
            );
            if (rotatedPoly) {
                rotatedPoly.geometry.coordinates = minimizeCoordinates(
                    rotatedPoly.geometry.coordinates,
                );
                features.push(rotatedPoly);
            }

            const marker = turf.point(
                minimizeCoordinate(point.geometry.coordinates),
                style.marker,
            );
            features.push(marker);
            const wpGeoJSON = turf.featureCollection(features);
            point.properties.wpGeoJSON = wpGeoJSON;
        }
    });
    lines.forEach((line) => {
        line.properties.wpGeoJSON = encodeLine2wpGeoJSON(line);
    });
    return {
        ...collection,
        features: [...lines, ...points],
    };
};

export const calculateZoomLevel = (feature) => {
    const collection = turf.featureCollection([feature]);
    const bbox = turf.bbox(collection);
    const squared = turf.bboxPolygon(turf.square(bbox));
    const area = turf.area(squared) / 1000000; // in square kilometers
    const radius = Math.sqrt(area);
    if (radius > 110) {
        return 7;
    } else if (radius > 75) {
        return 8;
    } else if (radius > 50) {
        return 9;
    } else if (radius > 30) {
        return 10;
    } else if (radius > 15) {
        return 11;
    } else if (radius > 10) {
        return 12;
    } else if (radius > 5) {
        return 13;
    }
    return 14;
};

const elevateSingleCoordinate = (pointCoordinate, coordinates) => {
    const originalElevation = parseInt(pointCoordinate[2], 10) || 0;
    const parsedElevation =
        parseInt(
            coordinates.find(
                (c) =>
                    c.lng === pointCoordinate[0] &&
                    c.lat === pointCoordinate[1],
            ).elevation,
            10,
        ) || 0;
    if (
        Math.abs(originalElevation - parsedElevation) >
        processingParameters.absoluteElevationThreshold
    ) {
        return parsedElevation;
    } else {
        return parseInt((parsedElevation + originalElevation) / 2, 10);
    }
};

export const getElevatedCollection = (collection, coordinates) => {
    const points = getPoints(collection).map((point) => {
        return {
            ...point,
            geometry: {
                ...point.geometry,
                coordinates: [
                    point.geometry.coordinates[0],
                    point.geometry.coordinates[1],
                    elevateSingleCoordinate(
                        point.geometry.coordinates,
                        coordinates,
                    ),
                ],
            },
        };
    });
    const lines = getLines(collection).map((line) => {
        return {
            ...line,
            geometry: {
                ...line.geometry,
                coordinates: line.geometry.coordinates.map((coordinate) => {
                    return [
                        coordinate[0],
                        coordinate[1],
                        elevateSingleCoordinate(coordinate, coordinates),
                    ];
                }),
            },
        };
    });
    lines.forEach((line) => {
        // Slope fix
        line.geometry.coordinates.forEach((coordinate, cIdx) => {
            if (cIdx) {
                const prevCoordinate = line.geometry.coordinates[cIdx - 1];
                const distance = turf.length(
                    turf.lineString([prevCoordinate, coordinate]),
                );
                const prevElevation = parseInt(prevCoordinate[2], 10);
                const currElevation = parseInt(coordinate[2], 10);
                const elevationDelta = currElevation - prevElevation;
                const slope = (elevationDelta / (distance * 1000)) * 100;
                const recommendedSlope = processingParameters.slopeTreshlod;
                if (slope > recommendedSlope) {
                    coordinate[2] = parseInt(
                        recommendedSlope * distance * 10 + prevElevation,
                        10,
                    );
                    // console.log(
                    //     `Slope fix elevation ${currElevation} -> ${coordinate[2]}`,
                    // );
                } else if (slope < processingParameters.slopeTreshlod * -1) {
                    coordinate[2] = parseInt(
                        prevElevation - recommendedSlope * distance * 10,
                        10,
                    );
                    // console.log(
                    //     `Slope fix elevation ${currElevation} -> ${coordinate[2]}`,
                    // );
                }
            }
        });
        // Oscilation fix
        let numberOfOscilations = 0;
        line.geometry.coordinates.forEach((coordinate, cIdx) => {
            const first = coordinate;
            const second = line.geometry.coordinates[cIdx + 1];
            const third = line.geometry.coordinates[cIdx + 2];
            const forth = line.geometry.coordinates[cIdx + 3];
            const fifth = line.geometry.coordinates[cIdx + 4];
            if (first && second && third) {
                if (second[2] > first[2] && second[2] > third[2]) {
                    second[2] = parseInt((first[2] + third[2]) / 2, 10);
                    numberOfOscilations += 1;
                } else if (second[2] < first[2] && second[2] < third[2]) {
                    second[2] = parseInt((first[2] + third[2]) / 2, 10);
                    numberOfOscilations += 1;
                }
            }
            if (first && second && third && forth && fifth) {
                if (
                    third[2] > first[2] &&
                    third[2] > second[2] &&
                    third[2] > forth[2] &&
                    third[2] > fifth[2]
                ) {
                    third[2] = parseInt(
                        (first[2] + second[2] + forth[2] + fifth[2]) / 4,
                        10,
                    );
                } else if (
                    third[2] < first[2] &&
                    third[2] < second[2] &&
                    third[2] < forth[2] &&
                    third[2] < fifth[2]
                ) {
                    third[2] = parseInt(
                        (first[2] + second[2] + forth[2] + fifth[2]) / 4,
                        10,
                    );
                }
            }
        });
        line.properties.numberOfOscilations = numberOfOscilations;
        console.log(numberOfOscilations);
    });
    return {
        ...collection,
        features: [...lines, ...points],
    };
};

export const getSmoothCollection = (collection) => {
    const points = getPoints(collection);
    const lines = getLines(collection).map((line) =>
        turf.bezierSpline(line, processingParameters.bezier),
    );
    return {
        ...collection,
        features: [...lines, ...points],
    };
};

export const encodeLine2wpGeoJSON = (line) => {
    const simplified = turf.simplify(line, processingParameters.simplifyLQ);
    const { coordinates } = simplified.geometry;
    const reducedCoordinates = coordinates.map((c) => [c[0], c[1]]);
    const coordinatesPolilined = polyline.encode(reducedCoordinates);
    const replacedCoordinatesPolilined = coordinatesPolilined.replace(
        /\\/g,
        '###',
    );
    return encodeURIComponent(replacedCoordinatesPolilined);
};

export const decodeWpGeoJSON2Line = (wpGeoJSON, properties) => {
    const encoded = wpGeoJSON;
    const replaced = encoded.replace(/###/g, '\\');
    const coordinates = polyline.decode(replaced);
    const newLine = turf.lineString(coordinates, {
        ...properties,
        isHome: true,
    });
    return newLine;
};

export const calculateBearing = (point, lines) => {
    let bearing = 0;
    lines.forEach((line) => {
        const distance = turf.pointToLineDistance(point, line);
        if (distance < processingParameters.stickyPointsTreshold) {
            const start = turf.point(line.geometry.coordinates[0]);
            let stop = turf.nearestPointOnLine(line, point);
            const lineSlice = turf.lineSlice(start, stop, line);
            if (lineSlice.geometry.coordinates.length > 2) {
                const simplified = turf.simplify(
                    lineSlice,
                    processingParameters.simplify,
                );
                const coordinates = simplified.geometry.coordinates;
                const pointA = turf.point(coordinates[coordinates.length - 2]);
                const pointB = turf.point(coordinates[coordinates.length - 1]);
                // For unknown reason, lineS
                bearing = turf.bearing(pointA, pointB);
            } else {
                const pointA = turf.point(line.geometry.coordinates[0]);
                const pointB = turf.point(line.geometry.coordinates[1]);
                // For unknown reason, lineS
                bearing = turf.bearing(pointA, pointB);
            }
        }
    });
    return bearing;
};
